import createApiInstance from "./api";

const API_URL = "/athleteMeasurements";

const AthleteMeasurementsService = {
    getAllByUserId: async (token, userId) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(`${API_URL}/user/${encodeURIComponent(userId)}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching athlete measurements for user id ${userId}:`, error);
            throw error;
        }
    },

    get: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(`${API_URL}/${encodeURIComponent(id)}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching athlete measurement with id ${id}:`, error);
            throw error;
        }
    },

    create: async (token, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post(API_URL, data);
            return response.data;
        } catch (error) {
            console.error("Error creating athlete measurement:", error);
            throw error;
        }
    },

    update: async (token, id, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.put(`${API_URL}/${encodeURIComponent(id)}`, data);
            return response.data;
        } catch (error) {
            console.error(`Error updating athlete measurement with id ${id}:`, error);
            throw error;
        }
    },

    delete: async (token, id) => {
        try {
            const api = createApiInstance(token);
            await api.delete(`${API_URL}/${encodeURIComponent(id)}`);
        } catch (error) {
            console.error(`Error deleting athlete measurement with id ${id}:`, error);
            throw error;
        }
    },
};

export default AthleteMeasurementsService;
