import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const ProgramCard = ({assignment, handleViewProgram, handleSelectProgram}) => {
    let isProgramComplete = assignment.extraData.completeDate;
    //console.log(assignment);
    return (
        <Container fluid>
            <Row>
                <Col>
                    <Card key={assignment.id} className="text-center">
                        <Card.Body>
                            <Card.Title style={{ color: isProgramComplete ? "red" : "green" }}>
                                {`${assignment.program.programName} - ${isProgramComplete ? "COMPLETED" : "ACTIVE"}`}
                            </Card.Title>
                            <Button onClick={() => handleViewProgram(assignment)}>View</Button>
                            {!isProgramComplete &&
                <Button style={{marginLeft: "10px"}} onClick={() => handleSelectProgram(assignment)}>Select</Button>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

ProgramCard.propTypes = {
    assignment: PropTypes.shape({
        id: PropTypes.string.isRequired,
        extraData: PropTypes.shape({
            completeDate: PropTypes.string
        }),
        program: PropTypes.shape({
            programName: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    handleViewProgram: PropTypes.func.isRequired,
    handleSelectProgram: PropTypes.func.isRequired
};

export default ProgramCard;
