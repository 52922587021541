import createApiInstance from "./api";

const UserProfileService = {
    getUserProfile: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.get(`/user-profiles/${encodedId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching user profile with id ${id}:`, error);
            throw error;
        }
    },

    hasUserProfile: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.get(`/user-profiles/${encodedId}`);
            return response.status === 200;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                return false;
            } else {
                console.error(`Error fetching user profile with id ${id}:`, error);
                throw error;
            }
        }
    },
  
    addUserProfile: async (token, userProfile) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post("/user-profiles", userProfile);
            return response.data;
        } catch (error) {
            console.error("Error adding user profile:", error);
            throw error;
        }
    },

    updateUserProfile: async (token, id, userProfile) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.put(`/user-profiles/${encodedId}`, userProfile);
            return response.data;
        } catch (error) {
            console.error(`Error updating user profile with id ${id}:`, error);
            throw error;
        }
    },

    getUserName: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.get(`/user-profiles/${encodedId}/name`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching user name with id ${id}:`, error);
            throw error;
        }
    },

    getUserProfiles: async (token) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get("/user-profiles");
            return response.data;
        } catch (error) {
            console.error("Error fetching user profiles:", error);
            throw error;
        }
    },
};



export default UserProfileService;