import createApiInstance from "./api";

const ExerciseService = {
    getExercises: async (token) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get("/exercises");
            return response.data;
        } catch (error) {
            console.error("Error fetching exercises:", error);
            throw error;
        }
    },

    addExercise: async (token, exercise) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post("/exercises", exercise);
            return response.data;
        } catch (error) {
            console.error("Error adding exercise:", error);
            throw error;
        }
    },

    updateExercise: async (token, id, exercise) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.put(`/exercises/${encodedId}`, exercise);
            return response.data;
        } catch (error) {
            console.error(`Error updating exercise with id ${id}:`, error);
            throw error;
        }
    },

    deleteExercise: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            await api.delete(`/exercises/${encodedId}`);
        } catch (error) {
            console.error(`Error deleting exercise with id ${id}:`, error);
            throw error;
        }
    },
};

export default ExerciseService;
