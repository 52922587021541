import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Accordion, Container, Row, Col, Badge, Button, Modal } from "react-bootstrap";
import ProgramAssignmentForm from "./ProgramAssignmentForm";
import ProgramForm from "../program/ProgramForm";
import { v4 as uuidv4 } from "uuid";
import UserContext from "../../../context/UserContext";

const ProgramAssignmentAccordion = ({ athletePrograms, handleProgramAssignmentChange, mode, activeKey, onAccordionChange }) => {
    const [showModal, setShowModal] = useState(false);
    const [programIdToEdit, setProgramIdToEdit] = useState(null);
    const [programToEdit, setProgramToEdit] = useState(null);
    const user = useContext(UserContext);
    const userRoles = user["https://enlistedfitness.com/roles"] || [];
    const isTrainer = userRoles.includes("ROLE_TRAINER");

    const handleEditProgram = (program) => {
        // Set the program ID to be edited
        setProgramIdToEdit(program.id);
    
        // Clone the program.program object to avoid modifying the original object
        const editableProgram = { ...program.program };
    
        // Iterate through workoutAssignments
        program.workoutAssignments.forEach((workoutAssignment) => {
            // Check if workoutassignment.date is not blank or null
            if (workoutAssignment.date) {
                // Iterate through program.program.workouts
                editableProgram.workouts.forEach((workout) => {
                    // Check if the IDs match
                    if (workoutAssignment.workout.id === workout.id) {
                        // Add the canEdit property and set to false
                        workout.canEdit = false;
                    }
                });
            }
        });
    
        setProgramToEdit(editableProgram);
        setShowModal(true);
    };
    
    const handleSaveProgram = (editedProgram) => {
        console.log(editedProgram);
        // Locate the original athleteProgram in athletePrograms using the programIdToEdit
        const athleteProgramIndex = athletePrograms.findIndex(
            (program) => program.id === programIdToEdit
        );
        if (athleteProgramIndex === -1) {
            console.log("Program not found");
            setShowModal(false);
            return;
        }
      
        // Clone the athleteProgram to avoid modifying the original object
        const updatedAthleteProgram = { ...athletePrograms[athleteProgramIndex] };
        console.log(editedProgram);
        updatedAthleteProgram.program = editedProgram;
        // Iterate through the editedProgram.workouts
        editedProgram.workouts.forEach((workout) => {

            // Check if the workout object does not have a property of canEdit and has a property of edited equals true
            if (workout.canEdit === undefined) {
            // Find the corresponding workoutassignment object
                const workoutAssignmentIndex = updatedAthleteProgram.workoutAssignments.findIndex(
                    (assignment) => assignment.workout.id === workout.id
                );
                if (workoutAssignmentIndex !== -1) {
                    // Update the workout assignment to a new assignment
                    const exerciseAssignments = workout.exercises.map((exercise) => {
                        return {
                            id: uuidv4(),
                            exercise: exercise,
                            completedSets: null,
                            totalWeight: null,
                            videoLink: null,
                            status: "ready",
                            extraData: {},
                        };
                    });
                    updatedAthleteProgram.workoutAssignments[workoutAssignmentIndex] = {
                        id: uuidv4(),
                        workout: workout,
                        date: null,
                        startTime: null,
                        endTime: null,
                        exerciseAssignments,
                        extraData: {},
                    };
                }
            }
        });
      
        // Log the new athleteProgram object
        console.log(updatedAthleteProgram);
        handleProgramAssignmentChange(programIdToEdit, "update", updatedAthleteProgram);
        setShowModal(false);
    };

    //console.log(athletePrograms);
    const currentDate = new Date();

    const handleDisableProgram = (programId) => {
        console.log(`Disable Program: ${programId}`);
        handleProgramAssignmentChange(programId, "disable");
    };

    const handleEnableProgram = (programId) => {
        console.log(`Enable Program: ${programId}`);
        handleProgramAssignmentChange(programId, "enable");
    };

    return (
        athletePrograms && athletePrograms.length > 0 ? (
            <Accordion style={{ marginTop: "10px" }} activeKey={activeKey} onSelect={onAccordionChange}>
                {athletePrograms.map((program, index) => {
                    const endDate = new Date(program.endDate);
                    return (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>
                                <Container fluid>
                                    <Row>
                                        <Col md={4} className="text-left">
                                            {program.program.programName}
                                        </Col>
                                    </Row>
                                    {isTrainer && <Row className="mt-3" xs={1} sm={2} md={4} lg={4}>
                                        <Col className="text-left mb-2 mb-md-0">
                                            {endDate < currentDate && (
                                                <Badge bg="danger">EXPIRED</Badge>
                                            )}
                                        </Col>
                                        <Col className="text-left mb-2 mb-md-0">
                                            <Badge bg={program.enabled ? "success" : "warning"}>
                                                {program.enabled ? "ENABLED" : "DISABLED"}
                                            </Badge>
                                        </Col>
                                    </Row>}
                                </Container>
                            </Accordion.Header>
                            <Accordion.Body>
                                {isTrainer && 
                <Row xs={1} sm={2} md={4} lg={4}>
                    <Col className="text-right mb-2 mb-md-0">
                        {program.enabled ? (
                            <Button onClick={() => handleDisableProgram(program.id)}>Disable</Button>
                        ) : (
                            <Button onClick={() => handleEnableProgram(program.id)}>Enable</Button>
                        )}
                    </Col>
                    <Col className="text-right">
                        <Button className="ml-2 mb-2" onClick={() => handleEditProgram(program)}>Edit</Button>
                    </Col>
                </Row>
                                }
                                <ProgramAssignmentForm
                                    mode={mode}
                                    programAssignment={program}
                                    handleChange={handleProgramAssignmentChange}
                                    onChange={handleProgramAssignmentChange}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    );
                })}
                <Modal show={showModal} onHide={() => setShowModal(false)}   className="custom-modal"
                    aria-labelledby= {programToEdit ? "Edit Program" : "Add Program"}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {programToEdit ? "Edit Program" : "Add Program"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProgramForm
                            initialProgram={programToEdit}
                            closeModal={handleSaveProgram}
                        />
                    </Modal.Body>
                </Modal>
            </Accordion>
        ) : (
            <p>No Programs Assigned</p>
        )
    );
};

ProgramAssignmentAccordion.propTypes = {
    athletePrograms: PropTypes.array.isRequired,
    handleProgramAssignmentChange: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]).isRequired,
    activeKey: PropTypes.string, // Optional
    onAccordionChange: PropTypes.func, // Optional
};

export default ProgramAssignmentAccordion;
