import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Container, Row, Col, Table, FormControl } from "react-bootstrap";
import ExerciseForm from "./ExerciseForm";
import ExerciseService from "../../../services/exercise.service";
import { useAuth0 } from "@auth0/auth0-react";
import { datadogLogs } from "@datadog/browser-logs";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";
import { AiOutlineSortAscending, AiOutlineSortDescending, AiFillCopy } from "react-icons/ai";


const ExerciseList = () => {
    const [exercises, setExercises] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [exerciseToEdit, setExerciseToEdit] = useState(null);
    const [searchQuery, setSearchQuery] = useState(""); // State for the search query
    const [sortField, setSortField] = useState("exerciseName"); // Field to sort by
    const [sortOrder, setSortOrder] = useState("asc");  // Sort order: 'asc' or 'desc'
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();


    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                //console.log("token: " + token);
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
                datadogLogs.logger.error("Error retrieving access token:", { error });
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        const fetchExercises = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getToken();
                    if (token) {
                        const response = await ExerciseService.getExercises(token);
                        if (!response || response.length === 0) {
                            setExercises([]);
                        } else {
                            // Filter exercises where exercise.userId is not "template"
                            const filteredExercises = response.filter(exercise => exercise.userId.toLowerCase() !== "template");
                            setExercises(filteredExercises);
                        }
                    }
                } catch (error) {
                    console.error("Error fetching exercises:", error);
                    datadogLogs.logger.error("Error fetching exercises:", { error });
                }
            }
        };
        fetchExercises();
    }, [isAuthenticated, getToken]);


    const handleAddExercise = () => {
        setShowModal(true);
        setExerciseToEdit(null);
    };

    const handleEditExercise = (exercise) => {
        setShowModal(true);
        setExerciseToEdit(exercise);
    };

    const handleCopyExercise = (exercise) => {
        const copiedExercise = { ...exercise, id: 0 };
        setShowModal(true);
        setExerciseToEdit(copiedExercise);
    };

    const handleDeleteExercise = async (exercise) => {
        try {
            const token = await getToken();
            if (token) {
                await ExerciseService.deleteExercise(token, exercise.id);
                setExercises((prevExercises) =>
                    prevExercises.filter((e) => e.id !== exercise.id)
                );
            }
        } catch (error) {
            console.error(`Error deleting exercise with id ${exercise.id}:`, error);
            datadogLogs.logger.error(`Error deleting exercise with id ${exercise.id}:`, { error });
        }
    };

    const handleSaveExercise = async (exercise) => {
        try {
            console.log(JSON.stringify(exercise));
            const token = await getToken();
            if (token) {
                if (exercise.id !== 0) {
                    console.log("Trying to Update Exercise");
                    console.log(exercise.id);
                    await ExerciseService.updateExercise(token, exercise.id, exercise);
                    setExercises((prevExercises) =>
                        prevExercises.map((e) => (e.id === exercise.id ? exercise : e))
                    );
                } else {
                    console.log("Adding a new Exercise");
                    const response = await ExerciseService.addExercise(token, exercise);
                    setExercises((prevExercises) =>
                        prevExercises.concat(response)
                    );
                }
                setShowModal(false);
            }
        } catch (error) {
            console.error("Error saving exercise:", error);
            datadogLogs.logger.error("Error saving exercise:", { error });
        }
    };


    if (isLoading) {
        return <div>Loading...</div>; // You can replace this with a loading spinner or any other loading indicator
    }

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };

    const sortedExercises = [...exercises].filter(exercise =>
        exercise.exerciseName.toLowerCase().includes(searchQuery.toLowerCase())
    ).sort((a, b) => {
        if (sortOrder === "asc") {
            return a[sortField].localeCompare(b[sortField]);
        }
        return b[sortField].localeCompare(a[sortField]);
    });


    return (
        <div>
            <Container fluid>
                <Row className="mt-4">
                    <h1>My Exercise Templates</h1>
                </Row>
                <Row className="justify-content-start mb-4">
                    <Col>
                        <FormControl
                            type="text"
                            placeholder="Search by exercise name"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-start">
                    <Button onClick={handleAddExercise} className="mb-4">Add Exercise</Button>
                </Row>
                <hr style={{ borderTop: "1px solid black" }} />
                <Row className="mt-4">
                    {sortedExercises.length ? (
                        <Table bordered striped className="table-striped">
                            <thead>
                                <tr>
                                    <th className="col-12 col-md-6 text-md-left align-middle" style={{ fontSize: "1.25rem" }} onClick={() => handleSort("exerciseName")}>
                                        Sort By Exercise Name
                                        {sortField === "exerciseName" && sortOrder === "asc" && <AiOutlineSortAscending />}
                                        {sortField === "exerciseName" && sortOrder === "desc" && <AiOutlineSortDescending />}
                                    </th>
                                    <th colSpan={3} className="col-12 col-md-2 text-center align-middle"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedExercises && sortedExercises.map((exercise) => (
                                    <tr key={exercise.id}>
                                        <td className="col-12 col-md-12 text-md-left align-middle" style={{ fontSize: "1.25rem" }}>
                                            <div style={{ textAlign: "center" }}>{exercise.exerciseName}</div>
                                            <div className="d-flex align-items-center mt-2 mt-md-0 justify-content-between">
                                                <Button
                                                    variant="warning"
                                                    onClick={() => handleCopyExercise(exercise)}
                                                >
                                                    <AiFillCopy />
                                                </Button>
                                                {exercise.userId !== "all" &&
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => handleDeleteExercise(exercise)}
                                                    >
                                                        <BsFillTrash3Fill />
                                                    </Button>
                                                }
                                                <Button
                                                    variant="primary"
                                                    onClick={() => handleEditExercise(exercise)}
                                                >
                                                    <BsFillPencilFill />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    ) : (
                        <p>No exercises found.</p>
                    )}
                </Row>
                <Modal show={showModal} onHide={() => setShowModal(false)} className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {exerciseToEdit ? "Edit Exercise" : "Add Exercise"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ExerciseForm
                            exercise={exerciseToEdit}
                            closeModal={handleSaveExercise}
                        />
                    </Modal.Body>
                </Modal>
            </Container>
        </div>
    );
};

export default ExerciseList;