import { useState, useEffect, useCallback } from "react";
import ProgramService from "../../../../services/program.service";
import { datadogLogs } from "@datadog/browser-logs";

const usePrograms = (token, isAuthenticated) => {
    const [programs, setPrograms] = useState([]);
    const [fetchProgramsError, setFetchProgramsError] = useState(null);

    const fetchPrograms = useCallback(async () => {
        datadogLogs.logger.info("Fetching programs");
        if (isAuthenticated && token) {
            try {
                const programsData = await ProgramService.getAll(token);
                setPrograms(programsData);
                datadogLogs.logger.info("Programs fetched successfully", { programs: programsData });
            } catch (error) {
                datadogLogs.logger.error("Error fetching programs", { error: error.message });
                setFetchProgramsError(error.message || "An error occurred while fetching programs.");
            }
        } else {
            datadogLogs.logger.warn("Failed to retrieve token");
        }
    }, [token, isAuthenticated]);

    useEffect(() => {
        if (token && isAuthenticated) {
            fetchPrograms();
        }
    }, [token, isAuthenticated, fetchPrograms]);

    return [programs, fetchProgramsError, fetchPrograms];
};

export default usePrograms;
