import createApiInstance from "./api";

const TrainerProfileService = {
    getTrainerProfile: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.get(`/trainer-profiles/${encodedId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching trainer profile with id ${id}:`, error);
            throw error;
        }
    },

    hasTrainerProfile: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.get(`/trainer-profiles/${encodedId}`);
            return response.status === 200;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                return false;
            } else {
                console.error(`Error fetching trainer profile with id ${id}:`, error);
                throw error;
            }
        }
    },

    addTrainerProfile: async (token, trainerProfile) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post("/trainer-profiles", trainerProfile);
            return response.data;
        } catch (error) {
            console.error("Error adding trainer profile:", error);
            throw error;
        }
    },

    updateTrainerProfile: async (token, id, trainerProfile) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.put(`/trainer-profiles/${encodedId}`, trainerProfile);
            return response.data;
        } catch (error) {
            console.error(`Error updating trainer profile with id ${id}:`, error);
            throw error;
        }
    },

    getTrainerProfilesWithUserData: async (token) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get("/trainer-profiles/with-user-profiles");
            return response.data;
        } catch (error) {
            console.error("Error fetching trainer profiles with user data:", error);
            throw error;
        }
    },


    assignAthleteToTrainer: async (token, trainerId, userId) => {
        try {
            const api = createApiInstance(token);
            const encodedTrainerId = encodeURIComponent(trainerId);
            const response = await api.post(`/trainer-profiles/${encodedTrainerId}/assign`, { userId });
            return response.data;
        } catch (error) {
            console.error(`Error assigning user ${userId} to trainer ${trainerId}:`, error);
            throw error;
        }
    },
    getAssignedAthletes: async (token, trainerId) => {
        try {
            const api = createApiInstance(token);
            const encodedTrainerId = encodeURIComponent(trainerId);
            const response = await api.get(`/trainer-profiles/${encodedTrainerId}/athletes`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching assigned athletes for trainer ${trainerId}:`, error);
            throw error;
        }
    }
};

export default TrainerProfileService;
