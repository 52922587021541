import createApiInstance from "./api";

const API_URL = "/s3";

const FileService = {
    uploadFile: async (token, formData, setUploadProgress) => {
        const apiInstance = createApiInstance(token);
        try {
            const response = await apiInstance.post(`${API_URL}/upload`,
                formData,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: progressEvent => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    },
                }
            );
            return response.data; // Assuming the API returns the URL of the uploaded file
        } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
        }
    },

    getPresignedUrl: async (token, fileName) => {
        const apiInstance = createApiInstance(token);
        try {
            const { data } = await apiInstance.get(`${API_URL}/getPresignedUrl`, {
                params: { fileName },
            });
            return data;
        } catch (error) {
            console.error("Error getting pre-signed URL:", error);
            throw error;
        }
    },
};

export default FileService;
