import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Form, Container, Row, Col, Table } from "react-bootstrap";
import WorkoutService from "../../../services/workout.service";
import { useAuth0 } from "@auth0/auth0-react";
import WorkoutForm from "../workout/WorkoutForm";
import PropTypes from "prop-types";
import { datadogLogs } from "@datadog/browser-logs";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";
import { AiFillSave, AiFillCaretDown, AiFillCaretUp, AiOutlineSortDescending, AiOutlineSortAscending } from "react-icons/ai";

const ProgramForm = ({ initialProgram = null, closeModal }) => {
    //console.log(initialProgram);
    const [workouts, setWorkouts] = useState([]);
    const [editWorkout, setEditWorkout] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [showWorkouts, setShowWorkouts] = useState(false);
    const [sortOrder, setSortOrder] = useState(null); // Possible values: 'asc', 'desc', null
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [workoutTemplate, setWorkoutTemplate] = useState([]);
    //console.log("Current User: " + user.sub);
    const [program, setProgram] = useState({
        programName: initialProgram ? initialProgram.programName : "",
        workouts: initialProgram ? initialProgram.workouts : [],
        userId: initialProgram ? initialProgram.userId : user.sub,
    });

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
                datadogLogs.logger.error("Error retrieving access token:", { error });
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);



    useEffect(() => {
        const fetchWorkouts = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getToken();
                    if (token) {
                        const response = await WorkoutService.getAll(token);
                        if (!response || Object.keys(response).length === 0) {
                            setWorkouts([]);
                            setWorkoutTemplate(null); // Reset workoutTemplate when no workouts available
                        } else {
                            // Filter workouts where userId is "template"
                            const templateWorkouts = response.filter(workout => workout.userId.toLowerCase() === "template");
                            const filteredWorkouts = response.filter(workout => workout.userId.toLowerCase() !== "template");
                            setWorkouts(filteredWorkouts);
                            if (templateWorkouts.length === 1) {
                                setWorkoutTemplate(templateWorkouts[0]);
                            } else {
                                setWorkoutTemplate(null); // Reset workoutTemplate if not exactly one template workout found
                            }
                        }
                    }
                } catch (error) {
                    console.error("Error fetching workouts:", error);
                    datadogLogs.logger.error("Error fetching workouts:", { error });
                }
            }
        };
        fetchWorkouts();
        if (initialProgram) {
            setProgram(initialProgram);
        }
    }, [initialProgram, isAuthenticated, getToken]);


    const addWorkout = (workout) => {
        const newWorkout = {
            ...workout,
            id: (Math.floor(Math.random() * 1000000) + 1),
        };
        setProgram((prevProgram) => ({
            ...prevProgram,
            workouts: [...prevProgram.workouts, newWorkout]
        }));
    };

    const removeWorkout = (workout) => {
        setProgram((prevProgram) => ({
            ...prevProgram,
            workouts: prevProgram.workouts.filter((w) => w.id !== workout.id)
        }));
    };

    const moveWorkoutUp = (workout) => {
        const index = program.workouts.findIndex((w) => w.id === workout.id);
        if (index > 0) {
            const workouts = [...program.workouts];
            const tmp = workouts[index - 1];
            workouts[index - 1] = workout;
            workouts[index] = tmp;
            setProgram((prevProgram) => ({
                ...prevProgram,
                workouts
            }));
        }
    };

    const moveWorkoutDown = (workout) => {
        const index = program.workouts.findIndex((w) => w.id === workout.id);
        if (index < program.workouts.length - 1) {
            const workouts = [...program.workouts];
            const tmp = workouts[index + 1];
            workouts[index + 1] = workout;
            workouts[index] = tmp;
            setProgram((prevProgram) => ({
                ...prevProgram,
                workouts
            }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        //console.log(program);
        datadogLogs.logger.info("Submitting program form", { program });
        closeModal && closeModal(program);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProgram({ ...program, [name]: value });
    };

    const handleUpdateWorkout = (updatedWorkout) => {
        //console.log(updatedWorkout);
        const index = program.workouts.findIndex((w) => w.id === updatedWorkout.id);
        if (index !== -1) {
            setProgram((prevProgram) => ({
                ...prevProgram,
                workouts: [
                    ...prevProgram.workouts.slice(0, index),
                    updatedWorkout, ...prevProgram.workouts.slice(index + 1),
                ],
            }));
        }
        //console.log(program);
        setEditWorkout(null);
        setShowModal(false);
        datadogLogs.logger.info("Update workout", { updatedWorkout });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSort = () => {
        setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    };

    const handleHideWorkouts = () => {
        setShowWorkouts(showWorkouts === true ? false : true);
    };

    const filteredWorkouts = workouts.filter((workout) =>
        workout.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedWorkouts = sortOrder
        ? filteredWorkouts.sort((a, b) =>
            sortOrder === "asc"
                ? a.name.localeCompare(b.name)
                : b.name.localeCompare(a.name)
        )
        : filteredWorkouts;


    if (!user) {
        return <div>Loading...</div>;
    }

    //console.log(workoutTemplate);

    return (
        <div>
            <Container fluid>
                <Row>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-2">
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="programName">
                                    <h3>Program Name</h3>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    id="programName"
                                    name="programName"
                                    value={program.programName}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="justify-content-between mb-3">
                            <Col xs={12} sm={6} md={4} className="text-right">
                                <Button variant="primary" onClick={() => addWorkout(workoutTemplate)}>
                      Add New Workout
                                </Button>
                            </Col>
                            <Col xs={12} sm={6} md={4} className="text-right" style={{ marginTop: "10px" }}>
                                <Button variant="primary" onClick={() => handleHideWorkouts()}>
                                    {showWorkouts === true ? "Hide Workout Templates" : "Show Workout Templates"}
                                </Button>
                            </Col>
                        </Row>
                        {showWorkouts && (
                            <Form.Group>
                                <Row className="justify-content-between mb-3">
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            placeholder="Search workouts"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="justify-content-end w-100">
                                    <Table bordered striped className="table-striped">
                                        <thead>
                                            <tr>
                                                <th className="col text-left align-middle" style={{ flexGrow: 1, fontSize: "1.25rem" }} onClick={() => handleSort()}>
                                                    <h3>Sort By Workout Template Name
                                                        {sortOrder === "asc" && <AiOutlineSortAscending />}
                                                        {sortOrder === "desc" && <AiOutlineSortDescending />}
                                                    </h3>
                                                </th>
                                                <th colSpan={2}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedWorkouts && sortedWorkouts.map((workout) => (
                                                <tr key={workout.id}>
                                                    <td className="col text-left align-middle" style={{ flexGrow: 1, fontSize: "1.25rem" }}>
                                                        <Row>
                                                            <Col>{workout.name}</Col>
                                                            <Col className="d-flex flex-column align-items-end">
                                                                <Button
                                                                    variant="danger"
                                                                    type="button"
                                                                    onClick={() => removeWorkout(workout)}
                                                                    disabled={workout.canEdit !== undefined} // Disable the button if workout.canEdit exists
                                                                >
                                                                    <BsFillTrash3Fill />
                                                                </Button>
                                                                <Button
                                                                    variant="primary"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setEditWorkout(workout);
                                                                        setShowModal(true);
                                                                    }}
                                                                    disabled={workout.canEdit !== undefined} // Disable the button if workout.canEdit exists
                                                                    className="mt-2"
                                                                >
                                                                    <BsFillPencilFill />
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td className="col-auto text-end">
                                                        <Button
                                                            variant="secondary"
                                                            type="button"
                                                            onClick={() => addWorkout(workout)}
                                                            className="ml-auto"
                                                        >
                                  Add to Program
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Row>
                            </Form.Group>
                        )}
                        <hr style={{ borderTop: "1px solid black" }} />
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="selectedWorkouts">
                                    <h3>Selected Workouts</h3>
                                </Form.Label>
                                <Table bordered striped className="table-striped">
                                    <tbody>
                                        {program.workouts.map((workout, index) => (
                                            <tr key={workout.id}>
                                                <td className="col-12 col-md-6 text-md-left align-middle" style={{ fontSize: "1.25rem" }}>
                                                    <div style={{ marginBottom: "10px" }}>{workout.name}</div>
                                                    <div className="d-flex align-items-center mt-2 mt-md-0">
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            style={{ marginRight: "20px" }} 
                                                            onClick={() => {
                                                                setEditWorkout(workout);
                                                                setShowModal(true);
                                                            }}
                                                            disabled={workout.canEdit !== undefined}
                                                            className="mb-2 mb-md-0 ml-2 ml-md-2"
                                                        >
                                                            <BsFillPencilFill />
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            type="button"
                                                            onClick={() => removeWorkout(workout)}
                                                            disabled={workout.canEdit !== undefined}
                                                            className="mb-2 mb-md-0 ml-2 ml-md-2"
                                                        >
                                                            <BsFillTrash3Fill />
                                                        </Button>
                                                    </div>
                                                </td>

                                                <td className="col-12 col-md-6 text-md-end">
                                                    <div className="d-flex flex-column align-items-md-end">
                                                        <Button
                                                            variant="secondary"
                                                            type="button"
                                                            disabled={index === 0 || workout.canEdit === false}
                                                            className="mb-2"
                                                            onClick={() => moveWorkoutUp(workout)}
                                                        >
                                                            <AiFillCaretUp />
                                                        </Button>
                                                        <Button
                                                            variant="secondary"
                                                            type="button"
                                                            disabled={index === program.workouts.length - 1 || workout.canEdit === false}
                                                            onClick={() => moveWorkoutDown(workout)}
                                                        >
                                                            <AiFillCaretDown />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>


                            </Form.Group>
                        </Row>
                        <Button variant="primary" type="submit">
                            <AiFillSave />
                        </Button>
                    </Form>
                </Row>
            </Container>
            <Modal show={showModal} onHide={() => setShowModal(false)} className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editWorkout ? "Edit Workout" : "Add Workout"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WorkoutForm
                        initialWorkout={editWorkout}
                        closeModal={handleUpdateWorkout}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};
    
ProgramForm.propTypes = {
    initialProgram: PropTypes.shape({
        programName: PropTypes.string.isRequired,
        workouts: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                canEdit: PropTypes.bool // Optional, so not marked as required
            // Add other workout properties here if necessary
            })
        ).isRequired,
    }),
    closeModal: PropTypes.func.isRequired,
};
    
export default ProgramForm;