import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const My404Page = () => {
    return (
        <Container fluid>
            <Row className="justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
                <Col md={6} className="text-center">
                    <h1>404</h1>
                    <p>Oops! The page you&apos;re looking for doesn&apos;t exist.</p>
                    <Link to="/">
                        <Button variant="primary">Go to Home</Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};

export default My404Page;
