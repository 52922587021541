import createApiInstance from "./api";

const API_URL = "/teams";

const TeamService = {
    getAllByUser: async (token, userId) => {
        try {
            const api = createApiInstance(token);
            const encodedUserId = encodeURIComponent(userId);
            const response = await api.get(`${API_URL}/${encodedUserId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching teams for user id ${userId}:`, error);
            throw error;
        }
    },

    get: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.get(`${API_URL}/${encodedId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching team with id ${id}:`, error);
            throw error;
        }
    },

    create: async (token, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post(API_URL, data);
            return response.data;
        } catch (error) {
            console.error("Error creating team:", error);
            throw error;
        }
    },

    update: async (token, id, data) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.put(`${API_URL}/${encodedId}`, data);
            return response.data;
        } catch (error) {
            console.error(`Error updating team with id ${id}:`, error);
            throw error;
        }
    },

    delete: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            await api.delete(`${API_URL}/${encodedId}`);
        } catch (error) {
            console.error(`Error deleting team with id ${id}:`, error);
            throw error;
        }
    },
};

export default TeamService;
