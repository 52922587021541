import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ProgramAssignments from "./ProgramAssignments";
import ProgramAssignmentService from "../../services/program.assignment.service";
import ProgramAssignmentAccordion from "../trainer/programform/ProgramAssignmentAccordion";
import ProgramCard from "./ProgramCard";
import { datadogLogs } from "@datadog/browser-logs";

const UserPrograms = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [programAssignments, setProgramAssignments] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [showProgramView, setShowProgramView] = useState(false);

    // handler stubs
    const handleProgramAssignmentChange = (program) => {
        console.log("handleProgramAssignmentChange", program);
    };

    const handleProgramFormClose = () => {
        //console.log("handleProgramFormClose");
        setShowProgramView(false);
    };

    const getToken = useCallback(async () => {
        if (user) {
            try {
                return await getAccessTokenSilently();
            } catch (error) {
                //console.error("Error retrieving access token:", error);
                datadogLogs.logger.error("Error retrieving access token:", { error });
            }
        }
        return null;
    }, [getAccessTokenSilently, user]);

    useEffect(() => {
        const fetchProgramAssignments = async () => {
            if (user && isAuthenticated) {
                const token = await getToken();
                if (token) {
                    ProgramAssignmentService.findByAthleteId(token, user.sub)
                        .then(assignments => {
                            if (assignments) {
                                setProgramAssignments(assignments);
                                //console.log("Program assignments updated:", assignments);
                            } else {
                                //console.log("No assignments found for this user.");
                            }
                        })
                        .catch(err => {
                            //console.error("Error fetching program assignments:", err);
                            datadogLogs.logger.error("Error fetching program assignments:", { err });
                        });
                }
            }
        };

        fetchProgramAssignments();
    }, [user, isAuthenticated, getToken]);

    const handleSelectProgram = async (assignment) => {
        //console.log(assignment);

        // Fetch latest data
        if (user && isAuthenticated) {
            const token = await getToken();
            if (token) {
                ProgramAssignmentService.findByAthleteId(token, user.sub)
                    .then(assignments => {
                        if (assignments) {
                            setProgramAssignments(assignments);
                            //console.log("Program assignments updated:", assignments);

                            // Find the selected assignment in the updated assignments
                            const updatedAssignment = assignments.find(a => a.id === assignment.id);
                            setSelectedProgram(updatedAssignment || assignment);
                        } else {
                            console.log("No assignments found for this user.");
                        }
                    })
                    .catch(err => {
                        //console.error("Error fetching program assignments:", err);
                        datadogLogs.logger.error("Error fetching program assignments:", { err });
                    });
            }
        } else {
            setSelectedProgram(assignment);
        }
    };
    const handleBackClick = () => {
        setSelectedProgram(null);
        setShowProgramView(false);
    };


    


    const handleViewProgram = (program) => {
        setSelectedProgram([program]); // Setting it as array of one program
        setShowProgramView(true);
    };
    //console.log(programAssignments);

    return (
        <>
            <Container fluid>
                <Row className="mt-4">
                    <h1>My Training Programs</h1>
                </Row>
                <Row>
                    <Col>
                        {!selectedProgram && !showProgramView && programAssignments.length > 0 && programAssignments.map((assignment, index) => (
                            <ProgramCard 
                                key={index} 
                                assignment={assignment} 
                                handleViewProgram={handleViewProgram} 
                                handleSelectProgram={handleSelectProgram} 
                            />
                        ))}
    
                        {!selectedProgram && !showProgramView && programAssignments.length === 0 && (
                            <h2 style={{ textAlign: "center" }}>You currently have no assigned programs</h2>
                        )}
                        {(selectedProgram || showProgramView) && (
                            <Button variant="secondary" onClick={handleBackClick} className="mb-2">Back to Programs</Button>

                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {selectedProgram && !showProgramView && (
                            <ProgramAssignments assignmentData={selectedProgram ? [selectedProgram] : []} />
                        )}
                        {showProgramView && (
                            <ProgramAssignmentAccordion
                                athletePrograms={selectedProgram}
                                handleProgramAssignmentChange={handleProgramAssignmentChange}
                                handleProgramFormClose={handleProgramFormClose}
                                mode="view" />
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
    
};

export default UserPrograms;