import React, { useState, useEffect } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import FileService from "../../services/file.service";
import MediaRenderer from "./ImageRenderer";

const FileUploadComponent = ({ token, handleUpload, fileType = "both" }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [mediaType, setMediaType] = useState("");

    let acceptedFileTypes = "*";
    if (fileType === "photo") {
        acceptedFileTypes = "image/*";
    } else if (fileType === "video") {
        acceptedFileTypes = "video/*";
    } else if (fileType === "both") {
        acceptedFileTypes = "image/*, video/*";
    }

    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const onUpload = async () => {
        try {
            setIsComplete(false);
            setIsProcessing(false);
            
            // Create a FormData object
            const formData = new FormData();
    
            // Append the file and metadata to the FormData
            formData.append("file", selectedFile);
            formData.append("metadata", JSON.stringify({
                name: selectedFile.name,
                type: selectedFile.type,
                size: selectedFile.size,
                // Add more metadata as needed
            }));
    
            // Pass FormData as the payload
            const data = await FileService.uploadFile(token, formData, setUploadProgress);
            
            setImageUrl(data);
            setIsProcessing(false);
    
            if (handleUpload) {
                const presignedUrl = await FileService.getPresignedUrl(token, selectedFile.name);
                handleUpload(presignedUrl);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const renderProgressBar = () => {
        if (isComplete  && !isProcessing) {
            return <ProgressBar now={100} variant="success" label="Complete" />;
        } else if (isProcessing) {
            return (
                <>
                    <ProgressBar striped variant="info" now={100} label="Upload complete, image processing..." />
                </>
            );
        } else {
            return <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />;
        }
    };

    useEffect(() => {
        if (uploadProgress === 100) {
            setIsProcessing(true);
            setIsComplete(true);
        }
    }, [uploadProgress]);

    useEffect(() => {
        if (selectedFile && selectedFile.type.startsWith("image")) {
            setMediaType("image");
        } else if (selectedFile && selectedFile.type.startsWith("video")) {
            setMediaType("video");
        }
    }, [selectedFile]);

    return (
        <div>
            <input type="file" onChange={onFileChange} accept={acceptedFileTypes} />
            <Button variant="primary" onClick={onUpload}>Upload</Button>
            {renderProgressBar()}
            <MediaRenderer url={imageUrl} mediaType={mediaType} />
        </div>
    );
};

FileUploadComponent.propTypes = {
    token: PropTypes.string.isRequired,
    handleUpload: PropTypes.func,
    fileType: PropTypes.oneOf(["photo", "video", "both"]),
};

export default FileUploadComponent;
