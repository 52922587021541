import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LoginButton from "./buttons/loginButton";
import LogoutButton from "./buttons/logoutButton";
import SignupButton from "./buttons/signupButton";
import PropTypes from "prop-types";
import { BsHouse } from "react-icons/bs";




import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";

const NavBar = (props) => {
    // eslint-disable-next-line no-undef
    const logo = process.env.PUBLIC_URL + "/favicon.svg";
    const { showTrainerBoard, showAdminBoard, currentUser } = props;
    const [localShowTrainerBoard, setLocalShowTrainerBoard] = useState(showTrainerBoard);
    const [localShowAdminBoard, setLocalShowAdminBoard] = useState(showAdminBoard);
    const [localCurrentUser, setLocalCurrentUser] = useState(currentUser);
    const [expanded, setExpanded] = useState(false);

    const logOut = () => {
        console.log("Dispatching LogOut");
        EventBus.dispatch("logout");
    };

    useEffect(() => {
        setLocalShowTrainerBoard(showTrainerBoard);
        setLocalShowAdminBoard(showAdminBoard);
        setLocalCurrentUser(currentUser);
    }, [showTrainerBoard, showAdminBoard, currentUser]);

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="NavBar px-lg-3" expanded={expanded}>
            <Navbar.Brand as={NavLink} to="/home" onClick={() => setExpanded(false)}>
                <div style={{ backgroundColor: "white", display: "inline-block", padding: "5px" }}>
                    <img
                        alt=""
                        src={logo}
                        width="45"
                        height="45"
                        className="d-inline-block align-top"
                    />
                </div>
            </Navbar.Brand>
            <Nav.Link
                as={NavLink}
                to="/home"
                onClick={() => setExpanded(false)}
                style={{ fontSize: "45px" }} // Adjust the size as needed
            >
                <BsHouse />
            </Nav.Link>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(prevExpanded => !prevExpanded)} />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">

                    {localShowTrainerBoard && (
                        <Nav.Link as={NavLink} to="/myathletes" onClick={() => setExpanded(false)}>My Athletes</Nav.Link>
                    )}
                    {localShowAdminBoard && (
                        <Nav.Link as={NavLink} to="/admin" onClick={() => setExpanded(false)}>Admin Board</Nav.Link>
                    )}
                    <Nav.Link as={NavLink} to="/install" onClick={() => setExpanded(false)}>Install App</Nav.Link>
                    {localCurrentUser && (
                        <NavDropdown title="My Fitness" id="collasible-nav-dropdown">
                            <NavDropdown.Item as={NavLink} to="/user" onClick={() => setExpanded(false)}>My Program</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={NavLink} to="/profile" onClick={() => setExpanded(false)}>Profile</NavDropdown.Item>
                        </NavDropdown>
                    )}
                </Nav>
                <Nav>
                    {localCurrentUser && (
                        <>
                            <Nav.Link className="me-lg-3" href="/profile">
                                {localCurrentUser.username}
                            </Nav.Link>
                            <LogoutButton logOut={logOut} />
                        </>
                    )}
                    {!localCurrentUser && <LoginButton />}
                    {!localCurrentUser && <SignupButton />}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

NavBar.propTypes = {
    showTrainerBoard: PropTypes.bool,
    showAdminBoard: PropTypes.bool,
    currentUser: PropTypes.object
};

export default NavBar;
