import { useState, useEffect, useCallback } from "react";
import EventsService from "../../../../services/event.service";

const useFetchEvents = (userId, getToken) => {
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(null);

    const fetchEvents = useCallback(async () => { // Wrap this function in useCallback
        const token = await getToken();
        try {
            const localEvents = await EventsService.getAllByUserId(token, userId);
            //console.log(localEvents);
            setEvents(localEvents || []);  // Set to empty array if localEvents is falsy
        } catch (err) {
            setError(err);
            setEvents([]);  // Set to empty array on error
        }
    }, [userId, getToken]); // Include dependencies

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]); // Trigger when fetchEvents changes

    return [events, error, fetchEvents]; // Return fetchEvents to allow external invocation
};

export default useFetchEvents;
