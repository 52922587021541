import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Container, Row, Col, Table, Form } from "react-bootstrap"; // Import Form
import ProgramForm from "./ProgramForm";
import ProgramService from "../../../services/program.service";
import { useAuth0 } from "@auth0/auth0-react";
import { datadogLogs } from "@datadog/browser-logs";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";
import { AiOutlineSortDescending, AiOutlineSortAscending, AiFillCopy } from "react-icons/ai";

const ProgramList = () => {
    const [programs, setPrograms] = useState([]);
    const [searchedPrograms, setSearchedPrograms] = useState([]); // State to keep track of searched programs
    const [searchTerm, setSearchTerm] = useState(""); // State to keep track of search term
    const [sortField, setSortField] = useState("programName"); // Field to sort by
    const [sortOrder, setSortOrder] = useState("asc");  // Sort order: 'asc' or 'desc'
    const [showModal, setShowModal] = useState(false);
    const [programToEdit, setProgramToEdit] = useState(null);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
                datadogLogs.logger.error("Error retrieving access token:", { error });
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        const fetchPrograms = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getToken();
                    if (token) {
                        const response = await ProgramService.getAll(token);
                        if (!response || Object.keys(response).length === 0) {
                            setPrograms([]); // Set an empty array when no programs are available
                        } else {
                            // Filter out programs where userId is "template"
                            const filteredPrograms = response.filter(program => program.userId.toLowerCase() !== "template");
                            setPrograms(filteredPrograms);
                        }
                    }
                } catch (error) {
                    console.error("Error fetching programs:", error);
                    datadogLogs.logger.error("Error fetching programs:", { error });
                }
            }
        };
        fetchPrograms();
    }, [isAuthenticated, getToken]);

    useEffect(() => {
        setSearchedPrograms(programs);
    }, [programs]);

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchTerm(query);
        const filteredPrograms = programs.filter((program) =>
            program.programName.toLowerCase().includes(query.toLowerCase())
        );
        setSearchedPrograms(filteredPrograms);
    };

    const handleAddProgram = () => {
        setShowModal(true);
        setProgramToEdit(null);
        datadogLogs.logger.info("Adding a new Program");
    };

    const handleEditProgram = (program) => {
        setShowModal(true);
        setProgramToEdit(program);
        datadogLogs.logger.info("Editing Program", { program });
    };

    const handleCopyProgram = (program) => {
        const copiedProgram = { ...program, id: 0 };
        setShowModal(true);
        setProgramToEdit(copiedProgram);
    };

    const handleDeleteProgram = async (program) => {
        try {
            const token = await getToken();
            if (token) {
                await ProgramService.delete(token, program.id);
                setPrograms((prevPrograms) =>
                    prevPrograms.filter((p) => p.id !== program.id)
                );
            }
        } catch (error) {
            console.error(`Error deleting program with id ${program.id}:`, error);
            datadogLogs.logger.error("Error deleting program:", { programId: program.id, error });
        }
    };

    const handleSaveProgram = async (program) => {
        try {
            const token = await getToken();
            if (token) {
                console.log(JSON.stringify(program));
                datadogLogs.logger.info("Saving Program", { program });
                if (program.id && program.id !== 0) {
                    console.log("Trying to Update Program");
                    datadogLogs.logger.info("Updating Program");
                    await ProgramService.update(token, program.id, program);
                    setPrograms((prevPrograms) =>
                        prevPrograms.map((p) => (p.id === program.id ? program : p))
                    );
                } else {
                    console.log("Adding a new Program");
                    datadogLogs.logger.info("Adding a new Program");
                    const response = await ProgramService.create(token, program);
                    if (response) {
                        setPrograms((prevPrograms) =>
                            prevPrograms.concat(response)
                        );
                    }
                }
                setShowModal(false);
            }
        } catch (error) {
            console.error("Error saving program:", error);
            datadogLogs.logger.error("Error saving program:", { error });
        }
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };

    const sortedProgams = [...searchedPrograms].sort((a, b) => {
        if (sortOrder === "asc") {
            return a[sortField].localeCompare(b[sortField]);
        }
        return b[sortField].localeCompare(a[sortField]);
    });

    return (
        <div>
            <Container fluid>
                <Row className="mt-4">
                    <h1>My Program Templates</h1>
                </Row>
                <Row className="justify-content-start mb-4">
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="Search by program name"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-end w-100">
                    <Button onClick={handleAddProgram}>Add Program</Button>
                </Row>

                <hr style={{ borderTop: "1px solid black" }} />
                <Row className="mt-4">
                    <Table bordered striped className="table-striped">
                        <thead>
                            <tr>
                                <th className="col-12 col-md-6 text-md-left align-middle" style={{ fontSize: "1.25rem" }} onClick={() => handleSort("programName")}>
                                    Sort By Program Name
                                    {sortField === "programName" && sortOrder === "asc" && <AiOutlineSortAscending />}
                                    {sortField === "programName" && sortOrder === "desc" && <AiOutlineSortDescending />}
                                </th>
                                <th colSpan={3} className="col-12 col-md-2 text-center align-middle"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedProgams && sortedProgams.map((program) => program && (
                                <tr key={program.id}>
                                    <td className="col-12 col-md-12 text-md-left align-middle" style={{ fontSize: "1.25rem" }}>
                                        <div style={{ textAlign: "center" }}>{program.programName}</div>
                                        <div className="d-flex align-items-center mt-2 mt-md-0 justify-content-between">
                                            <Button
                                                variant="info"
                                                onClick={() => handleCopyProgram(program)}
                                            >
                                                <AiFillCopy />
                                            </Button>
                                            <Button
                                                variant="danger"
                                                onClick={() => handleDeleteProgram(program)}
                                            >
                                                <BsFillTrash3Fill />
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={() => handleEditProgram(program)}
                                            >
                                                <BsFillPencilFill />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
                <Modal show={showModal} onHide={() => setShowModal(false)} className="custom-modal"
                    aria-labelledby={programToEdit ? "Edit Program" : "Add Program"}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {programToEdit ? "Edit Program" : "Add Program"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProgramForm
                            initialProgram={programToEdit}
                            closeModal={handleSaveProgram}
                        />
                    </Modal.Body>
                </Modal>
            </Container>
        </div>
    );
};

export default ProgramList;
