import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import EventModal from "./EventModal";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const ScheduleView = ({ events, handleEventDrop, handleEventResize, handleDeleteEvent, teams }) => {
    //console.log(events);
    const [modalShow, setModalShow] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [currentView, setCurrentView] = useState("month");  // to track the current view
    const [isDragInProgress, setDragInProgress] = useState(false);
    const [dragEvent, setDragEvent] = useState(null);
    

    const handleViewChange = (newView) => {  // to handle view changes
        setCurrentView(newView);
    };

    const handleDoubleClickEvent = (event) => {
        // Clone the event object to avoid mutations
        const clonedEvent = { ...event };
    
        // Convert start and end to Date objects if they're strings
        if (typeof clonedEvent.start === "string") {
            clonedEvent.start = new Date(clonedEvent.start);
        }
        
        if (typeof clonedEvent.end === "string") {
            clonedEvent.end = new Date(clonedEvent.end);
        }
    
        setSelectedEvent(clonedEvent);
        setModalShow(true);
    };

    const handleCloseModal = () => {
        setModalShow(false);
    };

    const handleSaveEvent = (updatedEvent) => {
        handleEventDrop({ event: updatedEvent, start: updatedEvent.start, end: updatedEvent.end });
    };

    const handleSelecting = (range) => {
        if (currentView !== "month") {
            setDragInProgress(true);
            setDragEvent(range);
        }
    };
    
    let clickTimeout;

    const handleSelectSlot = (slotInfo) => {
        if (currentView === "month") {
            if (clickTimeout) {
                clearTimeout(clickTimeout);
                clickTimeout = null;
                handleDoubleClickSlot(slotInfo);
            } else {
                clickTimeout = setTimeout(() => {
                    clickTimeout = null;
                }, 250);
            }
        } else {
            if (clickTimeout) {
                clearTimeout(clickTimeout);
                clickTimeout = null;
                handleDoubleClickSlot(slotInfo);
            } else if (isDragInProgress) {
                setDragInProgress(false);
                setSelectedEvent({ start: dragEvent.start, end: slotInfo.end });
                setModalShow(true);
            } else {
                clickTimeout = setTimeout(() => {
                    clickTimeout = null;
                }, 250);
            }
        }
    };

    const handleDoubleClickSlot = (slotInfo) => {
        //console.log("Double clicked:", slotInfo);
        setSelectedEvent({ start: slotInfo.start, end: slotInfo.end });
        setModalShow(true);
    };

    const customEventPropGetter = (event) => {
        if (event.eventType === "team") {
            return {
                style: {
                    backgroundColor: "#f00", // Set your desired color for 'team' events
                },
            };
        } else {
            return {};
        }
    };

    return (
        <Container fluid className="justify-content-md-center">
            <Row>
                <Col>
                    <DragAndDropCalendar
                        localizer={localizer}
                        events={events}
                        onEventDrop={handleEventDrop}
                        onEventResize={handleEventResize}
                        onView={handleViewChange}  // to listen for view changes
                        view={currentView}  //  to set the current view
                        onSelecting={handleSelecting}
                        onSelectSlot={handleSelectSlot}
                        eventPropGetter={customEventPropGetter}
                        resizable
                        selectable="true"  
                        startAccessor={(event) => { return new Date(event.start); }}
                        endAccessor={(event) => { return new Date(event.end); }}
                        style={{ height: 500 }}
                        draggableAccessor={event => event.draggable}
                        onDoubleClickEvent={handleDoubleClickEvent}
                    />
                    <EventModal 
                        show={modalShow} 
                        handleClose={handleCloseModal} 
                        handleSave={handleSaveEvent}
                        event={selectedEvent}
                        handleDeleteEvent={handleDeleteEvent}
                        teams={teams}
                    />
                </Col>
            </Row>
        </Container>
    );
};

ScheduleView.propTypes = {
    events: PropTypes.array.isRequired,
    handleEventDrop: PropTypes.func.isRequired,
    handleEventResize: PropTypes.func.isRequired,
    handleDeleteEvent: PropTypes.func,
    teams: PropTypes.array
};

export default ScheduleView;
