import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import ScheduleView from "./ScheduleView";
import { v4 as uuidv4 } from "uuid";
import { useAuth0 } from "@auth0/auth0-react";


const ScheduleController = ({ initialEvents, onEventChange, handleDeleteEvent, teams }) => {
    //console.log(initialEvents);
    const [events, setEvents] = useState(initialEvents || []);
    const { user } = useAuth0();

    const handleEventDrop = ({ event, start, end }) => {
        try {
            if (!event || !start || !end) {
                throw new Error("Missing parameters in handleEventDrop");
            }
    
            let updatedEvents;
            let eventToPassBack;

            start = new Date(start); // Convert to Date object
            end = new Date(end); // Convert to Date object
    
            if (event.id) {
                updatedEvents = events.map((e) => {
                    if (e.id === event.id) {
                        eventToPassBack = { ...e, ...event, start, end };
                        return eventToPassBack;
                    }
                    return e;
                });
            } else {
                // If the event does not have an id, add a new UUID
                const newEvent = {
                    ...event,
                    id: uuidv4(),
                    start,
                    end,
                    userId: event.userId || event.userId === null ? event.userId : user.sub,
                };
                updatedEvents = [...events, newEvent];
                eventToPassBack = newEvent;
            }
    
            setEvents(updatedEvents);
    
            if (onEventChange) {
                onEventChange(eventToPassBack);
            }
        } catch (error) {
            console.error(`Error in handleEventDrop: ${error}`);
        }
    };
    

    const handleEventResize = ({ event, start, end }) => {
        console.log(event);
        try {
            if (!event || !start || !end) {
                throw new Error("Missing parameters in handleEventResize");
            }

            if (event.resizable !== false) {
                const updatedEvents = events.map((e) => {
                    if (e.id === event.id) {
                        return { ...e, start, end };
                    }
                    return e;
                });
                setEvents(updatedEvents);
                if (onEventChange) {
                    onEventChange(event);
                }
            }
        } catch (error) {
            console.error(`Error in handleEventResize: ${error}`);
        }
    };
    //console.log(events);

    useEffect(() => {
        setEvents(initialEvents || []);
    }, [initialEvents]);

    return (
        <ScheduleView
            events={events}
            handleEventDrop={handleEventDrop}
            handleEventResize={handleEventResize}
            handleDeleteEvent={handleDeleteEvent}
            teams={teams}
        />
    );
};

ScheduleController.propTypes = {
    initialEvents: PropTypes.arrayOf(PropTypes.object),
    onEventChange: PropTypes.func,
    handleDeleteEvent: PropTypes.func,
    teams: PropTypes.array
};

export default ScheduleController;
