import React from "react";

import ExerciseList from "./ExerciseList";

const MyExercises = () => {
    return (
        <div className="container">
            <header className="jumbotron">
                <ExerciseList/>
            </header>
        </div>
    );
};

export default MyExercises;
