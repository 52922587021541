import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Program from "./Program";
import WorkoutAssignment from "./WorkoutAssignment";
import ExerciseAssignment from "./ExerciseAssignment";
import { v4 as uuidv4 } from "uuid";
import { useAuth0 } from "@auth0/auth0-react";
import ProgramAssignmentService from "../../services/program.assignment.service";
import { datadogLogs } from "@datadog/browser-logs";
import { Container, Row, Col } from "react-bootstrap";

const ProgramAssignments = ({ assignmentData }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [programAssignment, setProgramAssignment] = useState({
        program: null,
        workoutAssignments: null,
        completedExerciseAssignments: [],
        extraData: {}
    });

    const handleSelectProgram = (program) => {
        console.log(program);
        if (!program.extraData.startDate) {
            program.extraData.startDate = new Date().toISOString().split("T")[0];
        }
        setProgramAssignment(prevState => {
            let workoutAssignments;
            if (!program.workoutAssignments) {
                //console.log("no existing workoutassignments");
                workoutAssignments = program.program.workouts.map(workout => {
                    const exerciseAssignments = workout.exercises.map(exercise => {
                        return {
                            id: uuidv4(),
                            exercise: exercise,
                            completedSets: null,
                            totalWeight: null,
                            videoLink: null,
                            status: "ready",
                            extraData: {}
                        };
                    });
                    return {
                        id: uuidv4(),
                        workout: workout,
                        date: null,
                        startTime: null,
                        endTime: null,
                        exerciseAssignments,
                        extraData: {}
                    };
                });
            } else {
                //console.log("existing workoutassignments");
                workoutAssignments = program.workoutAssignments;
            }
    
            // Returning new state
            return {
                ...prevState,
                program: {
                    ...prevState.program,
                    ...program,
                    workoutAssignments,
                },
                workoutAssignments
            };
        });
    };
       

    const handleSelectWorkoutAssignment = (selectedWorkoutAssignment) => {
        console.log(selectedWorkoutAssignment);
        if (!selectedWorkoutAssignment.date) {
            let currentDate = new Date();
            selectedWorkoutAssignment.date = currentDate.toISOString().split("T")[0];
            selectedWorkoutAssignment.startTime = currentDate.toISOString().split("T")[1].split(".")[0];
        }
    
        let exerciseAssignments;
        if (!selectedWorkoutAssignment.exerciseAssignments || selectedWorkoutAssignment.exerciseAssignments.length === 0) {
            //console.log("no existing exercises");
    
            exerciseAssignments = selectedWorkoutAssignment.workout.exercises.map(exercise => {
                return {
                    id: uuidv4(),
                    exercise: exercise,
                    completedSets: null,
                    totalWeight: null,
                    videoLink: null,
                    status: "ready",
                    extraData: {}
                };
            });
            //console.log("exercise assignments: ", exerciseAssignments);
    
            //selectedWorkoutAssignment.exerciseAssignments = exerciseAssignments;
        } else {
            exerciseAssignments = selectedWorkoutAssignment.exerciseAssignments;
        }
    
        setProgramAssignment(prevState => {
            let workoutAssignments;
            if (prevState && prevState.workoutAssignments) {
                workoutAssignments = [...prevState.workoutAssignments];
                let index = workoutAssignments.findIndex(workoutAssignment => workoutAssignment.workout.id === selectedWorkoutAssignment.workout.id);
                if (index !== -1) {
                    workoutAssignments[index] = { ...selectedWorkoutAssignment, exerciseAssignments };
                } else {
                    workoutAssignments.push({ ...selectedWorkoutAssignment, exerciseAssignments });
                }
            } else {
                workoutAssignments = [{ ...selectedWorkoutAssignment, exerciseAssignments }];
            }
    
            // Returning new state
            return {
                ...prevState,
                workoutAssignments,
                program: {
                    ...prevState.program,
    
                    workoutAssignments,
    
                },
                workoutAssignment: selectedWorkoutAssignment
            };
        });
    };
    




    const handleCompleteExerciseAssignment = (exerciseAssignment, exerciseData) => {
        try {
            let currentDate = new Date();
            exerciseAssignment.extraData = {
                "result": {
                    "completedReps": exerciseData.completedReps,
                    "measure": exerciseData.measure,
                },
                "endTime": currentDate.toISOString().split("T")[1].split(".")[0],
                "notes": exerciseData.notes
            };
            exerciseAssignment.status = "Complete";
    
            setProgramAssignment(prevState => {
                const updatedState = {...prevState, completedExerciseAssignments: [
                    ...prevState.completedExerciseAssignments,
                    exerciseAssignment
                ]};
    
                if (!updatedState.workoutAssignment || !Array.isArray(updatedState.workoutAssignment.exerciseAssignments)) {
                    throw new Error("Invalid workout assignment data");
                }
    
                const allExercisesCompleted = updatedState.workoutAssignment.exerciseAssignments.every(exercise => {
                    if (!exercise.status) {
                        throw new Error("Invalid exercise assignment data");
                    }
                    return exercise.status === "Complete";
                });
    
                if (allExercisesCompleted) {
                    updatedState.workoutAssignment.endTime = currentDate.toISOString().split("T")[1].split(".")[0];
                    
                    const workoutToUpdate = updatedState.workoutAssignments.find(workout => workout.id === updatedState.workoutAssignment.id);
                    if (workoutToUpdate) {
                        workoutToUpdate.endTime = currentDate.toISOString().split("T")[1].split(".")[0];
                    }
                    updatedState.workoutAssignment = null;
                }
    
                if (!Array.isArray(updatedState.workoutAssignments)) {
                    throw new Error("Invalid workout assignments data");
                }
    
                const completedWorkouts = updatedState.workoutAssignments.filter(workout => workout.endTime !== null);
    
                if (completedWorkouts.length === updatedState.workoutAssignments.length) {
                    updatedState.program.extraData.completeDate = currentDate.toISOString().split("T")[0];
                }
    
                return updatedState;
            });
        } catch (err) {
            datadogLogs.logger.error(err);
        }
    };
        
    
    const saveProgramAssignment = async (programAssignmentToSave) => {
        if (!programAssignment.program) {
            //console.error("No program selected");
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            // Create a shallow copy of the programAssignment
            let programAssignmentCopy = { ...programAssignment.program };
            // Delete the workoutAssignment property from the copy
            delete programAssignmentCopy.workoutAssignment;
            console.log(programAssignmentCopy);
            await ProgramAssignmentService.updateAthleteProgram(token, programAssignment.program.id, programAssignmentCopy);
            //console.log("Program assignment saved successfully");
        } catch (err) {
            console.error("Error saving program assignment:", err);
        }
    };


    useEffect(() => {
        //console.log("Program assignment has been updated: ", programAssignment);
        saveProgramAssignment(programAssignment);
    }, [programAssignment]);

    useEffect(() => {
        if (programAssignment.workoutAssignments) {
            // Find a workout assignment that has a date but no exercise assignments
            const workoutAssignment = programAssignment.workoutAssignments.find(
                (workoutAssignment) => workoutAssignment.date && (!workoutAssignment.exerciseAssignments || workoutAssignment.exerciseAssignments.length === 0)
            );
  
            if (workoutAssignment) {
                // If such a workout assignment exists, call handleSelectWorkoutAssignment for it
                handleSelectWorkoutAssignment(workoutAssignment);
            }
        }
    }, [programAssignment.workoutAssignments]);
  


    //console.log(programAssignment);

    return (
        <Container fluid>
            <Row>
                <Col>
                    {!programAssignment.program ? (
                        assignmentData.map((assignment) =>
                            <Program key={assignment.id} assignment={assignment} onSelectProgram={handleSelectProgram} />)
                    ) : (
                        !programAssignment.workoutAssignment ? (
                            programAssignment.workoutAssignments?.map((workoutAssignment) =>
                                <WorkoutAssignment key={workoutAssignment.id} workoutAssignment={workoutAssignment} onSelectWorkoutAssignment={handleSelectWorkoutAssignment} />)
                        ) : (
                            programAssignment.workoutAssignment?.exerciseAssignments?.map((exerciseAssignment) =>
                                (
                                    <ExerciseAssignment
                                        key={exerciseAssignment.id}
                                        exerciseAssignment={exerciseAssignment}
                                        onCompleteExerciseAssignment={handleCompleteExerciseAssignment}
                                    />
                                )
                            )
                        )
                    )}
                </Col>
            </Row>
        </Container>
    );
};

ProgramAssignments.propTypes = {
    assignmentData: PropTypes.array.isRequired
};

export default ProgramAssignments;


