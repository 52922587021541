import React from "react";
import PropTypes from "prop-types";
import { Card, Button, Container, Row, Col } from "react-bootstrap";

const WorkoutAssignment = ({ workoutAssignment, onSelectWorkoutAssignment }) => {
    const { workout, date, startTime, endTime } = workoutAssignment;
    console.log(workoutAssignment);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title>{workout.name}</Card.Title>
                            {date && <Card.Text>Date: {new Date(date).toLocaleDateString()}</Card.Text>}
                            {startTime && <Card.Text>Start Time: {startTime}</Card.Text>}
                            {endTime && <Card.Text>End Time: {endTime}</Card.Text>}
                            {!endTime && <Button variant="primary" onClick={() => onSelectWorkoutAssignment(workoutAssignment)}>Select Workout Assignment</Button>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

WorkoutAssignment.propTypes = {
    workoutAssignment: PropTypes.shape({
        id: PropTypes.string.isRequired,
        workout: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            exercises: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired
                }).isRequired
            ).isRequired
        }).isRequired,
        date: PropTypes.string,
        startTime: PropTypes.string,
        endTime: PropTypes.string,
        extraData: PropTypes.object
    }).isRequired,
    onSelectWorkoutAssignment: PropTypes.func.isRequired
};

export default WorkoutAssignment;
