import React, { useEffect, useState } from "react";
import AthleteAssignment from "./admin/AthleteAssignment";
import { Container, Row, Col } from "react-bootstrap";
import FileUploadComponent from "./imagemanagement/FileUploadComponent";
import { useGetToken } from "../services/authUtils";

const BoardAdmin = () => {
    const { getToken, isLoading } = useGetToken();
    const [token, setToken] = useState(null);
  
    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        };
  
        if (!isLoading) {
            fetchToken();
        }
    }, [getToken, isLoading]);
  
    if (isLoading || !token) {
        return <div>Loading...</div>;
    }
    return (
        
        <Container fluid className="mt-4">
            <Row>
                <Col>
                    <header>
                        <h3>Admin Dashboard</h3>
                    </header>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AthleteAssignment />
                    <FileUploadComponent token={token} />
                </Col>
            </Row>
        </Container>
    );
};

export default BoardAdmin;
