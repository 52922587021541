import React from "react";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const NavigationButtons = () => (
    <Row>
        <Col className="mt-4">
            <Link to="/myathletes">
                <Button variant="primary">My Athletes</Button>
            </Link>
        </Col>
        <Col className="mt-4">
            <Dropdown>
                <Dropdown.Toggle variant="primary" id="my-templates-dropdown">
                    My Templates
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/myexercises">
                        Exercise Templates
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/myworkouts">
                        Workout Templates
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/myprograms">
                        Program Templates
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Col>
        <Col className="mt-4">
            <Link to="/myteams">
                <Button variant="primary">My Teams</Button>
            </Link>
        </Col>
    </Row>
);

export default NavigationButtons;
