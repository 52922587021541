import createApiInstance from "./api";

const API_URL = "/events";  

const EventsService = {
    // Retrieve event by ID
    get: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(`${API_URL}/${encodeURIComponent(id)}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching event with id ${id}:`, error);
            throw error;
        }
    },

    // Retrieve all events for a specific user ID
    getAllByUserId: async (token, userId) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(`${API_URL}/user/${encodeURIComponent(userId)}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching events for user id ${userId}:`, error);
            throw error;
        }
    },

    // Create a new event
    create: async (token, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post(API_URL, data);
            return response.data;
        } catch (error) {
            console.error("Error creating event:", error);
            throw error;
        }
    },

    // Update an existing event by ID
    update: async (token, id, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.put(`${API_URL}/${encodeURIComponent(id)}`, data);
            return response.data;
        } catch (error) {
            console.error(`Error updating event with id ${id}:`, error);
            throw error;
        }
    },

    // Delete an event by ID
    delete: async (token, id) => {
        try {
            const api = createApiInstance(token);
            await api.delete(`${API_URL}/${encodeURIComponent(id)}`);
        } catch (error) {
            console.error(`Error deleting event with id ${id}:`, error);
            throw error;
        }
    },

    // Retrieve all events for a specific team ID
    getAllByTeamId: async (token, teamId) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(`${API_URL}/team/${encodeURIComponent(teamId)}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching events for team id ${teamId}:`, error);
            throw error;
        }
    },

    // Retrieve all events for a specific trainer ID
    getAllByTrainerId: async (token, trainerId) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(`${API_URL}/trainer/${encodeURIComponent(trainerId)}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching events for trainer id ${trainerId}:`, error);
            throw error;
        }
    },

    // Retrieve all events for a specific event type
    getAllByEventType: async (token, eventType) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(`${API_URL}/type/${encodeURIComponent(eventType)}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching events of type ${eventType}:`, error);
            throw error;
        }
    }
};

export default EventsService;
