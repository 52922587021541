import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const Program = ({assignment, onSelectProgram}) => {
    //console.log(assignment);
    return (
        <Container fluid>
            <Row>
                <Col>
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title>{assignment.program.programName}</Card.Title>
                            <Card.Text>
            Start Date: {new Date(assignment.startDate).toLocaleDateString()}
                            </Card.Text>
                            <Card.Text>
            End Date: {new Date(assignment.endDate).toLocaleDateString()}
                            </Card.Text>
                            <Button variant="primary" onClick={() => onSelectProgram(assignment)}>Select Program</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

Program.propTypes = {
    assignment: PropTypes.shape({
        id: PropTypes.string.isRequired,
        program: PropTypes.shape({
            id: PropTypes.string.isRequired,
            programName: PropTypes.string.isRequired
        }).isRequired,
        startDate: PropTypes.string,
        endDate: PropTypes.string
    }).isRequired,
    onSelectProgram: PropTypes.func.isRequired
};

export default Program;
