import React, { useState, useCallback, useEffect } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import TrainerProfileService from "../../services/trainer.profile.service";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";

const CreateTrainerProfile = () => {
    const [form, setForm] = useState({
        availability: {
            start: "",
            end: "",
            weekends: false
        },
        timezone: "",
        socialMediaLinks: {
            twitter: "",
            facebook: "",
        },
        cellPhone: "",
    });

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setForm(prevForm => ({ ...prevForm, email: user.email }));
        }
    }, [user]);

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name.includes(".")) {
            const [key, subKey] = name.split(".");
            setForm(prevForm => ({ 
                ...prevForm, 
                [key]: { 
                    ...prevForm[key],
                    [subKey]: value 
                } 
            }));
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            datadogLogs.logger.info("Creating trainer profile");
            const token = await getToken();
            if (token) {
                form.id = user.sub; // the id and userId should be the Auth0 user.sub value.
                form.userId = user.sub;
                await TrainerProfileService.addTrainerProfile(token, form);
                datadogLogs.logger.info("Trainer profile created successfully");
                navigate("/"); // navigate to the home page
            } else {
                datadogLogs.logger.warn("Failed to retrieve token");
            }
        } catch (error) {
            datadogLogs.logger.error("Error creating trainer profile:", { error: error.message });
            console.error("Error creating trainer profile:", error);
        }
    };

    const timezones = [
        "Pacific/Honolulu",
        "America/Anchorage",
        "America/Los_Angeles",
        "America/Phoenix",
        "America/Denver",
        "America/Chicago",
        "America/New_York",
        "America/Puerto_Rico"
    ];

    return (
        <Container>
            <Row>
                <h1>Welcome to Enlisted Fitness</h1>
                <h2>Please complete your Coach&apos;s profile</h2>
            </Row>
            <Row className="justify-content-md-center">
                <Col md="8">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Availability Start</Form.Label>
                            <Form.Control type="time" name="availability.start" value={form.availability.start} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Availability End</Form.Label>
                            <Form.Control type="time" name="availability.end" value={form.availability.end} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" label="Available on Weekends" name="availability.weekends" checked={form.availability.weekends} onChange={(event) => setForm(prevForm => ({...prevForm, availability: {...prevForm.availability, weekends: event.target.checked}}))} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Timezone</Form.Label>
                            <Form.Select name="timezone" value={form.timezone} onChange={handleChange}>
                                <option value="">Select Timezone</option>
                                {timezones.map(tz => (
                                    <option key={tz} value={tz}>{tz}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Twitter Link</Form.Label>
                            <Form.Control type="text" name="socialMediaLinks.twitter" value={form.socialMediaLinks.twitter} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Facebook Link</Form.Label>
                            <Form.Control type="text" name="socialMediaLinks.facebook" value={form.socialMediaLinks.facebook} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Cell Phone</Form.Label>
                            <Form.Control type="text" name="cellPhone" value={form.cellPhone} onChange={handleChange} />
                        </Form.Group>

                        <Button variant="primary" type="submit">Submit</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default CreateTrainerProfile;
