import React from "react";

import WorkoutList from "./WorkoutList";


const MyWorkouts = () => {
    return (
        <div className="container">
            <header className="jumbotron">
                <WorkoutList/>
            </header>
        </div>
    );
};

export default MyWorkouts;
