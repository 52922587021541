import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, ListGroup, Form, Container } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import UserProfileService from "../../services/user.profile.service";
import TrainerProfileService from "../../services/trainer.profile.service";
import TeamService from "../../services/team.service";

const TeamManagement = ({ initialTeam, closeModal }) => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [allAthletes, setAllAthletes] = useState([]);
    const [team, setTeam] = useState(initialTeam?.athletes || []);
    const [teamName, setTeamName] = useState(initialTeam?.name || "");
    //console.log(initialTeam);
    //onsole.log(team);

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);


    const handleTeamCreation = async (event) => {
        event.preventDefault();
    
        const token = await getToken();
    
        if (!token) {
            return;
        }
    
        try {
            const athleteIds = team.map(athlete => athlete.id);
            const trainerIds = [user.sub]; // Assuming 'sub' contains the user ID
    
            const teamObject = {
                name: teamName,
                athletes: athleteIds,
                trainers: trainerIds
            };

            if (initialTeam) {
                // Updating existing team
                teamObject.id = initialTeam.id; // Assuming the team has an ID field
                await TeamService.update(token, initialTeam.id, teamObject);
            } else {
                // Creating new team
                await TeamService.create(token, teamObject);
            }
            closeModal();
        } catch (error) {
            console.error("Error creating team:", error);
        }
    };

    const addAthleteToTeam = (athlete) => {
        const newTeam = [...team, athlete];
        setTeam(newTeam);
    
        // Remove the added athlete from the available athletes list
        const updatedAvailableAthletes = allAthletes.filter(a => a.id !== athlete.id);
        setAllAthletes(updatedAvailableAthletes);
    };
    

    const removeAthleteFromTeam = (athlete) => {
        const newTeam = team.filter(t => t.id !== athlete.id);
        setTeam(newTeam);
    
        // Add the removed athlete back to the available athletes list
        const updatedAvailableAthletes = [...allAthletes, athlete];
        setAllAthletes(updatedAvailableAthletes);
    };
    
    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated && user) {
                const token = await getToken();
                const trainerId = user.sub;
    
                if (token) {
                    try {
                        const fetchedTrainerProfile = await TrainerProfileService.getTrainerProfile(token, trainerId);
                        const assignedAthletes = fetchedTrainerProfile.assignedAthletes || [];
                        const validAssignedAthletes = assignedAthletes.filter(athleteId => athleteId !== null && athleteId !== undefined);
                        const athleteProfilesPromises = validAssignedAthletes.map(athleteId =>
                            UserProfileService.getUserProfile(token, athleteId)
                        );
                        const fetchedAthletes = await Promise.all(athleteProfilesPromises);
                        const validFetchedAthletes = fetchedAthletes.filter(athlete => athlete !== null && athlete !== undefined);
    
                        // Map the team's athlete IDs to the full athlete objects
                        const teamAthletes = initialTeam?.athletes?.map(athleteId =>
                            validFetchedAthletes.find(athlete => athlete.id === athleteId)
                        ) || [];
    
                        setTeam(teamAthletes);
    
                        // Filter out the athletes that are already part of the team
                        const availableAthletes = validFetchedAthletes.filter(athlete => !teamAthletes.some(t => t.id === athlete.id));
                        setAllAthletes(availableAthletes);
    
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                }
            }
        };
    
        fetchData();
    }, [getToken, isAuthenticated, user, initialTeam]); // Notice initialTeam in the dependencies
    
    
    

    return (
        <Form onSubmit={handleTeamCreation}>
            <Form.Group>
                <Form.Label>Team Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter team name"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                />
            </Form.Group>
            <Container>
                {
                    allAthletes.filter((athlete) => athlete !== null && athlete !== undefined).length > 0 && (
                        <>
                            <h5>Available Athletes</h5>
                            <ListGroup>
                                {allAthletes
                                    .filter((athlete) => athlete !== null && athlete !== undefined)
                                    .map((athlete) => (
                                        <ListGroup.Item key={athlete.id}>
                                            {athlete.name}
                                            <Button variant="success" onClick={() => addAthleteToTeam(athlete)}>Add</Button>
                                        </ListGroup.Item>
                                    ))}
                            </ListGroup>
                        </>
                    )
                }

            </Container>
            <Container>
                <h5>Team Members</h5>
                <ListGroup>
                    {team
                        .filter((athlete) => athlete !== null && athlete !== undefined)
                        .map((athlete) => (
                            <ListGroup.Item key={athlete.id}>
                                {athlete.name}
                                <Button variant="danger" onClick={() => removeAthleteFromTeam(athlete)}>Remove</Button>
                            </ListGroup.Item>
                        ))}
                </ListGroup>
            </Container>
            <Button type="submit">{initialTeam ? "Update" : "Create"} Team</Button>
        </Form>
    );
};

TeamManagement.propTypes = {
    initialTeam: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        athletes: PropTypes.array,
    }),
    closeModal: PropTypes.func.isRequired, // Add this line
};


export default TeamManagement;