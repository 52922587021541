import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Form, Container, Row, Col, Table } from "react-bootstrap";
import ExerciseService from "../../../services/exercise.service";
import { useAuth0 } from "@auth0/auth0-react";
import ExerciseForm from "../exercise/ExerciseForm";
import PropTypes from "prop-types";
import { datadogLogs } from "@datadog/browser-logs";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";
import { AiFillSave, AiFillCaretDown, AiFillCaretUp, AiOutlineSortDescending, AiOutlineSortAscending } from "react-icons/ai";


const WorkoutForm = ({ initialWorkout = null, closeModal }) => {
    const [exercises, setExercises] = useState([]);
    const [editExercise, setEditExercise] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOrder, setSortOrder] = useState(null); // Possible values: 'asc', 'desc', null
    const [showExercises, setShowExercises] = useState(false);
    const [exerciseTemplate, setExerciseTemplate] = useState([]);

    const { user, getAccessTokenSilently } = useAuth0();
    

    const [workout, setWorkout] = useState({
        name: initialWorkout ? initialWorkout.name : "",
        exercises: initialWorkout ? initialWorkout.exercises : [],
        userId: initialWorkout ? initialWorkout.userId : user.sub,
    });


    const fetchExercises = useCallback(async () => {
        datadogLogs.logger.info("Fetching exercises");
        try {
            const token = await getAccessTokenSilently();
            const exercisesData = await ExerciseService.getExercises(token);
    
            // Filter out exercises where userId is "template"
            const filteredExercises = exercisesData.filter(exercise => exercise.userId.toLowerCase() !== "template");
    
            // Find the exercise with userId equal to "template" (if exactly one exists)
            const templateExercise = exercisesData.find(exercise => exercise.userId.toLowerCase() === "template");
    
            setExercises(filteredExercises);
            setExerciseTemplate(templateExercise || null); // Set exerciseTemplate or null if not found
            datadogLogs.logger.info("Exercises fetched successfully", { exercises: filteredExercises });
        } catch (error) {
            datadogLogs.logger.error("Error fetching exercises", { error: error.message });
            console.error("Error fetching exercises:", error);
        }
    }, [getAccessTokenSilently]);
    
    useEffect(() => {
        fetchExercises();
        if (initialWorkout) {
            setWorkout(initialWorkout);
        }
    }, [initialWorkout, fetchExercises]);
    

    const addExercise = (exercise) => {
        datadogLogs.logger.info("Adding exercise", { exercise });
        const newExercise = {
            ...exercise,
            id: (Math.floor(Math.random() * 1000000) + 1),
            position: workout.exercises.length + 1,
        };
        setWorkout((prevWorkout) => ({
            ...prevWorkout,
            exercises: [...prevWorkout.exercises, newExercise]
        }));
    };

    const removeExercise = (exercise) => {
        datadogLogs.logger.info("Removing exercise", { exercise });
        setWorkout((prevWorkout) => ({
            ...prevWorkout,
            exercises: prevWorkout.exercises.filter((ex) => ex.id !== exercise.id)
        }));
    };

    const moveExerciseUp = (exercise) => {
        const index = workout.exercises.findIndex((ex) => ex.id === exercise.id);
        if (index > 0) {
            const exercises = [...workout.exercises];
            const tmp = exercises[index - 1];
            exercises[index - 1] = exercise;
            exercises[index] = tmp;
            setWorkout((prevWorkout) => ({
                ...prevWorkout,
                exercises
            }));
        }
    };

    const moveExerciseDown = (exercise) => {
        const index = workout.exercises.findIndex((ex) => ex.id === exercise.id);
        if (index < workout.exercises.length - 1) {
            const exercises = [...workout.exercises];
            const tmp = exercises[index + 1];
            exercises[index + 1] = exercise;
            exercises[index] = tmp;
            setWorkout((prevWorkout) => ({
                ...prevWorkout,
                exercises
            }));
        }
    };


    const handleSubmit = (event) => {
        datadogLogs.logger.info("Submitting workout form", { workout });
        event.preventDefault();
        closeModal && closeModal(workout);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setWorkout({ ...workout, [name]: value });
    };

    const handleUpdateExercise = (updatedExercise) => {
        datadogLogs.logger.info("Updating exercise", { updatedExercise });
        const index = workout.exercises.findIndex((ex) => ex.id === updatedExercise.id);
        if (index !== -1) {
            setWorkout((prevWorkout) => ({
                ...prevWorkout,
                exercises: [
                    ...prevWorkout.exercises.slice(0, index),
                    updatedExercise,
                    ...prevWorkout.exercises.slice(index + 1),
                ],
            }));
        }
        setEditExercise(null);
        setShowModal(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    
      
    const handleSort = () => {
        setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    };

    const handleHideExercises = () => {
        setShowExercises(showExercises === true ? false : true);
    };
    
    const filteredExercises = exercises.filter((exercise) => 
        exercise.exerciseName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const sortedExercises = sortOrder
        ? filteredExercises.sort((a, b) => 
            sortOrder === "asc"
                ? a.exerciseName.localeCompare(b.exerciseName)
                : b.exerciseName.localeCompare(a.exerciseName)
        )
        : filteredExercises;
    

    if (!user) {
        return <div>Loading...</div>;
    }

    //console.log(exerciseTemplate);

    return (
        <div>
            <Container fluid>
                <Row>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="name"><h3>Workout Name</h3></Form.Label>
                                <Form.Control
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Enter New Workout Name"
                                    value={workout.name}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="justify-content-between mb-3">
                            <Col md="3" className="text-right">
                                <Button variant="primary" onClick={() => addExercise(exerciseTemplate)}>Add New Exercise</Button>
                            </Col>
                            <Col md="3" className="text-right" style={{ marginTop: "10px" }}>
                                <Button variant="primary" onClick={() => handleHideExercises()}>{showExercises === true ? "Hide Exercise Templates" : "Show Exercise Templates"}</Button>
                            </Col>
                        </Row>
                        {showExercises && (
                            <Form.Group>
                                <Row className="justify-content-between mb-3">
                                    <Form.Group >
                                        <Form.Control
                                            type="text"
                                            placeholder="Search exercises"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="justify-content-end w-100">
                                
                                    <Table bordered striped className="table-striped">
                                        <thead>
                                            <tr>
                                                <th className="col text-left align-middle" style={{ flexGrow: 1, fontSize: "1.25rem" }} onClick={() => handleSort()}>
                                                    <h3>Sort By Exercise Template Name
                                                        {sortOrder === "asc" && <AiOutlineSortAscending />}
                                                        {sortOrder === "desc" && <AiOutlineSortDescending />}
                                                    </h3>
                                                </th>
                                                <th colSpan={2}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedExercises && sortedExercises.map((exercise) => (
                                                <tr key={exercise.id}>
                                                    <td className="col text-left align-middle" style={{ flexGrow: 1, fontSize: "1.25rem" }}>{exercise.exerciseName}</td>
                                                    <td className="col-auto text-end">
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            onClick={() => addExercise(exercise)}
                                                            className="ml-auto"
                                                        >
                                                            Add to Workout
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                
                                </Row>
                            </Form.Group>
                        )}
                        <hr style={{ borderTop: "1px solid black" }}/>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="selectedExercises">
                                    <h3>Selected Exercises</h3>
                                </Form.Label>
                                <Table bordered striped className="table-striped">
                                    <tbody>
                                        {workout.exercises.map((exercise, index) => (
                                            <tr key={exercise.id}>
                                                <td className="col-12 col-md-6 text-md-left align-middle" style={{ fontSize: "1.25rem" }}>
                                                    <div style={{ marginBottom: "10px" }}>{exercise.exerciseName}</div>
                                                    <div className="d-flex align-items-center mt-2 mt-md-0">
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            style={{ marginRight: "20px" }}  
                                                            onClick={() => {
                                                                setEditExercise(exercise);
                                                                setShowModal(true);
                                                            }}
                                                        >
                                                            <BsFillPencilFill />
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            type="button"
                                                            onClick={() => removeExercise(exercise)}
                                                        >
                                                            <BsFillTrash3Fill />
                                                        </Button>
                                                    </div>

                                                </td>
                                                <td className="col-12 col-md-2 text-center align-middle">
                                                    <div className="d-flex flex-column align-items-md-end">
                                                        <Button
                                                            variant="secondary"
                                                            type="button"
                                                            className="mb-2"
                                                            disabled={index === 0}
                                                            onClick={() => moveExerciseUp(exercise)}
                                                        >
                                                            <AiFillCaretUp />
                                                        </Button>
                                                        <Button
                                                            variant="secondary"
                                                            type="button"
                                                            disabled={index === workout.exercises.length - 1}
                                                            onClick={() => moveExerciseDown(exercise)}
                                                        >
                                                            <AiFillCaretDown />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Form.Group>
                        </Row>
                        <hr style={{ borderTop: "1px solid black" }}/>
                        <Button variant="primary" type="submit">
                            <AiFillSave />
                        </Button>
                    </Form>
                </Row>
            </Container>
            <Modal show={showModal} onHide={() => setShowModal(false)} className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editExercise ? "Edit Exercise" : "Add Exercise"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ExerciseForm
                        exercise={editExercise}
                        closeModal={handleUpdateExercise}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
    



};

WorkoutForm.propTypes = {
    initialWorkout: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
};

export default WorkoutForm;