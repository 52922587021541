import { useState, useEffect } from "react";
import TeamService from "../../../../services/team.service";
import { datadogLogs } from "@datadog/browser-logs";

const useFetchTeams = (token, userId, isAuthenticated) => {
    const [teams, setTeams] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTeams = async () => {
            if (isAuthenticated) {
                try {
                    if (token && userId) {
                        const response = await TeamService.getAllByUser(token, userId);
                        setTeams(response || []);
                    }
                } catch (err) {
                    setError(err);
                    datadogLogs.logger.error("Error fetching teams:", { error: err });
                }
            }
        };

        fetchTeams();
    }, [token, userId, isAuthenticated]);

    return [teams, error];
};

export default useFetchTeams;
