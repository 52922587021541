import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import CoachLandingPage from "./coach/CoachLandingPage";
import AthleteLandingPage from "./AthleteLandingPage";

const LandingPage = ({ userType }) => {
    return (
        <Container fluid className="py-4">
            <Row>
                <Col>
                    {userType === "coach" ? <CoachLandingPage /> : <AthleteLandingPage />}
                </Col>
            </Row>
        </Container>
    );
};

LandingPage.propTypes = {
    userType: PropTypes.string.isRequired,
};

export default LandingPage;
