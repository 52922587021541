import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

datadogLogs.init({
    // eslint-disable-next-line no-undef
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    // eslint-disable-next-line no-undef
    site: process.env.REACT_APP_DATADOG_SITE,
    service: "enlistedfitness",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
});

datadogRum.init({
    // eslint-disable-next-line no-undef
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    // eslint-disable-next-line no-undef
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    // eslint-disable-next-line no-undef
    site: process.env.REACT_APP_DATADOG_SITE,
    // eslint-disable-next-line no-undef
    service: process.env.REACT_APP_DATADOG_SERVICE,
    // eslint-disable-next-line no-undef
    env: process.env.REACT_APP_DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0', 
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow"
});

    
datadogRum.startSessionReplayRecording();


const root = createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Auth0Provider
            domain="auth.enlistedfitness.com"
            clientId="HAtU4nx5vkIHarhDGqgDAv0qaLpx6UUq"
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: "https://enlistedfitness.com"
            }}
        >
        
            <App />
        
        </Auth0Provider>
    </BrowserRouter>,
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
