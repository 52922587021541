import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const HowToInstallPWA = () => {
    return (
        <Container fluid>
            <Row className="mt-4">
                <Col>
                    <h2>How to Install a PWA on Android and iOS</h2>
                    <p>
                        Progressive Web Apps (PWAs) are web apps that can be installed on your device and used like native apps. They are fast, reliable, and offline-capable.
                    </p>
                    <h3>To install a PWA on Android using Chrome:</h3>
                    <ol>
                        <li>Open the PWA website in Chrome. (If you are reading this you have completed this step)</li>
                        <li>Tap the three dots menu in the top right corner.</li>
                        <li>Select &ldquo;Add to Home screen.&rdquo;</li>
                        <li>Enter a name for the PWA and tap &ldquo;Add.&rdquo;</li>
                    </ol>
                    <h3>To install a PWA on iOS using Chrome:</h3>
                    <ol>
                        <li>Open the PWA website in Chrome. (If you are reading this you have completed this step)</li>
                        <li>Tap the share icon in the bottom right corner.</li>
                        <li>Select &ldquo;Add to Home Screen.&rdquo;</li>
                        <li>Enter a name for the PWA and tap &ldquo;Add.&rdquo;</li>
                    </ol>
                    <h3>To install a PWA on iOS using Safari:</h3>
                    <ol>
                        <li>Open the PWA website in Safari. (If you are reading this you have completed this step)</li>
                        <li>Tap the share icon in the bottom left corner.</li>
                        <li>Select &ldquo;Add to Home Screen.&rdquo;</li>
                        <li>Enter a name for the PWA and tap &ldquo;Add.&rdquo;</li>
                    </ol>
                </Col>
            </Row>
        </Container>
    );
};

export default HowToInstallPWA;
