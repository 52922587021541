import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import AthleteMeasurementsService from "../../services/athlete.measurements.service";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

const MeasurementsForm = ({ existingMeasurements, handleClose, priorMeasurements, onMeasurementUpdated }) => {

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [measurements, setMeasurements] = useState({
        id: existingMeasurements?.id || "",
        userId: existingMeasurements?.userId || user.sub,
        date: existingMeasurements?.date || new Date().toISOString().split("T")[0],
        neck: existingMeasurements?.neck,
        shoulder: existingMeasurements?.shoulder,
        bust: existingMeasurements?.bust,
        underBust: existingMeasurements?.underBust,
        lowerWaist: existingMeasurements?.lowerWaist,
        rightWrist: existingMeasurements?.rightWrist,
        leftWrist: existingMeasurements?.leftWrist,
        rightArm: existingMeasurements?.rightArm,
        leftArm: existingMeasurements?.leftArm,
        rightForearm: existingMeasurements?.rightForearm,
        leftForearm: existingMeasurements?.leftForearm,
        rightPalm: existingMeasurements?.rightPalm,
        leftPalm: existingMeasurements?.leftPalm,
        waist: existingMeasurements?.waist,
        rightThigh: existingMeasurements?.rightThigh,
        leftThigh: existingMeasurements?.leftThigh,
        rightCalf: existingMeasurements?.rightCalf,
        leftCalf: existingMeasurements?.leftCalf,
        rightAnkle: existingMeasurements?.rightAnkle,
        leftAnkle: existingMeasurements?.leftAnkle,
        weight: existingMeasurements?.weight,
        leftOverKnee: existingMeasurements?.leftOverKnee,
        rightOverKnee: existingMeasurements?.rightOverKnee,
        rightFoot: existingMeasurements?.rightFoot,
        leftFoot: existingMeasurements?.leftFoot,
        hips: existingMeasurements?.hips,
        height: existingMeasurements?.height,
    });
    const [isEditMode] = useState(!!existingMeasurements);

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        if (existingMeasurements) {
            setMeasurements(existingMeasurements);
        }
    }, [existingMeasurements]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setMeasurements((prevMeasurements) => ({
            ...prevMeasurements,
            [name]: value,
        }));
    };

    const resetForm = () => {
        setMeasurements({
            id: "",
            userId: user.sub,
            date: new Date().toISOString().split("T")[0],
            neck: 0,
            shoulder: 0,
            bust: 0,
            underBust: 0,
            lowerWaist: 0,
            rightWrist: 0,
            leftWrist: 0,
            rightArm: 0,
            // ... Rest of your measurement fields initialized to default values
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const finalMeasurements = {
            id: measurements.id,
            userId: measurements.userId,
            date: measurements.date,
            neck: measurements?.neck || priorMeasurements?.neck || 0,
            shoulder: measurements?.shoulder || priorMeasurements?.shoulder || 0,
            bust: measurements?.bust || priorMeasurements?.bust || 0,
            underBust: measurements?.underBust || priorMeasurements?.underBust || 0,
            lowerWaist: measurements?.lowerWaist || priorMeasurements?.lowerWaist || 0,
            rightWrist: measurements?.rightWrist || priorMeasurements?.rightWrist || 0,
            leftWrist: measurements?.leftWrist || priorMeasurements?.leftWrist || 0,
            rightArm: measurements?.rightArm || priorMeasurements?.rightArm || 0,
            leftArm: measurements?.leftArm || priorMeasurements?.leftArm || 0,
            rightForearm: measurements?.rightForearm || priorMeasurements?.rightForearm || 0,
            leftForearm: measurements?.leftForearm || priorMeasurements?.leftForearm || 0,
            rightPalm: measurements?.rightPalm || priorMeasurements?.rightPalm || 0,
            leftPalm: measurements?.leftPalm || priorMeasurements?.leftPalm || 0,
            waist: measurements?.waist || priorMeasurements?.waist || 0,
            rightThigh: measurements?.rightThigh || priorMeasurements?.rightThigh || 0,
            leftThigh: measurements?.leftThigh || priorMeasurements?.leftThigh || 0,
            rightCalf: measurements?.rightCalf || priorMeasurements?.rightCalf || 0,
            leftCalf: measurements?.leftCalf || priorMeasurements?.leftCalf || 0,
            rightAnkle: measurements?.rightAnkle || priorMeasurements?.rightAnkle || 0,
            leftAnkle: measurements?.leftAnkle || priorMeasurements?.leftAnkle || 0,
            weight: measurements?.weight || priorMeasurements?.weight || 0,
            leftOverKnee: measurements?.leftOverKnee || priorMeasurements?.leftOverKnee || 0,
            rightOverKnee: measurements?.rightOverKnee || priorMeasurements?.rightOverKnee || 0,
            rightFoot: measurements?.rightFoot || priorMeasurements?.rightFoot || 0,
            leftFoot: measurements?.leftFoot || priorMeasurements?.leftFoot || 0,
            hips: measurements?.hips || priorMeasurements?.hips || 0,
            height: measurements?.height || priorMeasurements?.height || 0,
        };

        try {
            const token = await getToken(); // Using the getToken method

            if (isEditMode) {
                await AthleteMeasurementsService.update(
                    token,
                    finalMeasurements.id,
                    finalMeasurements
                );
            } else {
                await AthleteMeasurementsService.create(
                    token,
                    finalMeasurements
                );
            }
            // Call onMeasurementUpdated if it's provided
            if (onMeasurementUpdated) {
                onMeasurementUpdated();
            }
            // Call handleClose if it's provided
            if (handleClose) {
                handleClose();
            }
            // Reset the form
            resetForm();
        } catch (error) {
            console.error("An error occurred while submitting the form:", error);
            // You can set an error message to display to the user here
        }
    };


    return (
        <Container fluid>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="date">
                            <Form.Label>Date</Form.Label>
                            <Form.Control type="date" name="date" value={measurements.date} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="weight">
                            <Form.Label>Weight</Form.Label>
                            <Form.Control type="number" name="weight" step="any" placeholder={priorMeasurements?.weight ? `${priorMeasurements.weight}` : ""} value={measurements.weight} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="height">
                            <Form.Label>Height</Form.Label>
                            <Form.Control type="number" name="height" step="any" placeholder={priorMeasurements?.height ? `${priorMeasurements.height}` : ""} value={measurements.height} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="neck">
                            <Form.Label>Neck</Form.Label>
                            <Form.Control type="number" name="neck" step="any" placeholder={priorMeasurements?.neck ? `${priorMeasurements.neck}` : ""} value={measurements.neck} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="shoulder">
                            <Form.Label>Shoulder</Form.Label>
                            <Form.Control type="number" name="shoulder" step="any" placeholder={priorMeasurements?.shoulder ? `${priorMeasurements.shoulder}` : ""} value={measurements.shoulder} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="bust">
                            <Form.Label>Bust</Form.Label>
                            <Form.Control type="number" name="bust" step="any" placeholder={priorMeasurements?.bust ? `${priorMeasurements.bust}` : ""} value={measurements.bust} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="underBust">
                            <Form.Label>Under Bust</Form.Label>
                            <Form.Control type="number" name="underBust" step="any" placeholder={priorMeasurements?.underBust ? `${priorMeasurements.underBust}` : ""} value={measurements.underBust} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="waist">
                            <Form.Label>Waist</Form.Label>
                            <Form.Control type="number" name="waist" step="any" placeholder={priorMeasurements?.waist ? `${priorMeasurements.waist}` : ""} value={measurements.waist} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="lowerWaist">
                            <Form.Label>Lower Waist</Form.Label>
                            <Form.Control type="number" name="lowerWaist" step="any" placeholder={priorMeasurements?.lowerWaist ? `${priorMeasurements.lowerWaist}` : ""} value={measurements.lowerWaist} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="hips">
                            <Form.Label>Hips</Form.Label>
                            <Form.Control type="number" name="hips" step="any" placeholder={priorMeasurements?.hips ? `${priorMeasurements.hips}` : ""} value={measurements.hips} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="rightWrist">
                            <Form.Label>Right Wrist</Form.Label>
                            <Form.Control type="number" name="rightWrist" step="any" placeholder={priorMeasurements?.rightWrist ? `${priorMeasurements.rightWrist}` : ""} value={measurements.rightWrist} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="leftWrist">
                            <Form.Label>Left Wrist</Form.Label>
                            <Form.Control type="number" name="leftWrist" step="any" placeholder={priorMeasurements?.leftWrist ? `${priorMeasurements.leftWrist}` : ""} value={measurements.leftWrist} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="leftArm">
                            <Form.Label>Left Arm</Form.Label>
                            <Form.Control type="number" name="leftArm" step="any" placeholder={priorMeasurements?.leftArm ? `${priorMeasurements.leftArm}` : ""} value={measurements.leftArm} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="rightArm">
                            <Form.Label>Right Arm</Form.Label>
                            <Form.Control type="number" name="rightArm" step="any" placeholder={priorMeasurements?.rightArm ? `${priorMeasurements.rightArm}` : ""} value={measurements.rightArm} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="leftForearm">
                            <Form.Label>Left Forearm</Form.Label>
                            <Form.Control type="number" name="leftForearm" step="any" placeholder={priorMeasurements?.leftForearm ? `${priorMeasurements.leftForearm}` : ""} value={measurements.leftForearm} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="rightForearm">
                            <Form.Label>Right Forearm</Form.Label>
                            <Form.Control type="number" name="rightForearm" step="any" placeholder={priorMeasurements?.rightForearm ? `${priorMeasurements.rightForearm}` : ""} value={measurements.rightForearm} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="rightPalm">
                            <Form.Label>Right Palm</Form.Label>
                            <Form.Control type="number" name="rightPalm" step="any" placeholder={priorMeasurements?.rightPalm ? `${priorMeasurements.rightPalm}` : ""} value={measurements.rightPalm} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="leftPalm">
                            <Form.Label>Left Palm</Form.Label>
                            <Form.Control type="number" name="leftPalm" step="any" placeholder={priorMeasurements?.leftPalm ? `${priorMeasurements.leftPalm}` : ""} value={measurements.leftPalm} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="leftThigh">
                            <Form.Label>Left Thigh</Form.Label>
                            <Form.Control type="number" name="leftThigh" step="any" placeholder={priorMeasurements?.leftThigh ? `${priorMeasurements.leftThigh}` : ""} value={measurements.leftThigh} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="rightThigh">
                            <Form.Label>Right Thigh</Form.Label>
                            <Form.Control type="number" name="rightThigh" step="any" placeholder={priorMeasurements?.rightThigh ? `${priorMeasurements.rightThigh}` : ""} value={measurements.rightThigh} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="leftOverKnee">
                            <Form.Label>Left Over Knee</Form.Label>
                            <Form.Control type="number" name="leftOverKnee" step="any" placeholder={priorMeasurements?.leftOverKnee ? `${priorMeasurements.leftOverKnee}` : ""} value={measurements.leftOverKnee} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>

                        <Form.Group controlId="rightOverKnee">
                            <Form.Label>Right Over Knee</Form.Label>
                            <Form.Control type="number" name="rightOverKnee" step="any" placeholder={priorMeasurements?.rightOverKnee ? `${priorMeasurements.rightOverKnee}` : ""} value={measurements.rightOverKnee} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="leftCalf">
                            <Form.Label>Left Calf</Form.Label>
                            <Form.Control type="number" name="leftCalf" step="any" placeholder={priorMeasurements?.leftCalf ? `${priorMeasurements.leftCalf}` : ""} value={measurements.leftCalf} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} xs={6}>

                        <Form.Group controlId="rightCalf">
                            <Form.Label>Right Calf</Form.Label>
                            <Form.Control type="number" name="rightCalf" step="any" placeholder={priorMeasurements?.rightCalf ? `${priorMeasurements.rightCalf}` : ""} value={measurements.rightCalf} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="leftAnkle">
                            <Form.Label>Left Ankle</Form.Label>
                            <Form.Control type="number" name="leftAnkle" step="any" placeholder={priorMeasurements?.leftAnkle ? `${priorMeasurements.leftAnkle}` : ""} value={measurements.leftAnkle} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="rightAnkle">
                            <Form.Label>Right Ankle</Form.Label>
                            <Form.Control type="number" name="rightAnkle" step="any" placeholder={priorMeasurements?.rightAnkle ? `${priorMeasurements.rightAnkle}` : ""} value={measurements.rightAnkle} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                    <Col md={3} xs={6}>
                        <Form.Group controlId="leftFoot">
                            <Form.Label>Left Foot</Form.Label>
                            <Form.Control type="number" name="leftFoot" step="any" placeholder={priorMeasurements?.leftFoot ? `${priorMeasurements.leftFoot}` : ""} value={measurements.leftFoot} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xl={3} md={3} xs={6}>
                        <Form.Group controlId="rightFoot">
                            <Form.Label>Right Foot</Form.Label>
                            <Form.Control type="number" name="rightFoot" step="any" placeholder={priorMeasurements?.rightFoot ? `${priorMeasurements.rightFoot}` : ""} value={measurements.rightFoot} onChange={handleInputChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit">{isEditMode ? "Edit" : "Save"} Measurements</Button>
            </Form>
        </Container>
    );

};

MeasurementsForm.propTypes = {
    existingMeasurements: PropTypes.shape({
        id: PropTypes.string,
        userId: PropTypes.string,
        date: PropTypes.string,
        neck: PropTypes.number,
        shoulder: PropTypes.number,
        bust: PropTypes.number,
        underBust: PropTypes.number,
        lowerWaist: PropTypes.number,
        rightWrist: PropTypes.number,
        leftWrist: PropTypes.number,
        rightArm: PropTypes.number,
        leftArm: PropTypes.number,
        rightForearm: PropTypes.number,
        leftForearm: PropTypes.number,
        rightPalm: PropTypes.number,
        leftPalm: PropTypes.number,
        waist: PropTypes.number,
        rightThigh: PropTypes.number,
        leftThigh: PropTypes.number,
        rightCalf: PropTypes.number,
        leftCalf: PropTypes.number,
        rightAnkle: PropTypes.number,
        leftAnkle: PropTypes.number,
        weight: PropTypes.number,
        leftOverKnee: PropTypes.number,
        rightOverKnee: PropTypes.number,
        rightFoot: PropTypes.number,
        leftFoot: PropTypes.number,
        hips: PropTypes.number,
        height: PropTypes.number,
    }),
    handleClose: PropTypes.func,
    priorMeasurements: PropTypes.shape({
        id: PropTypes.string,
        userId: PropTypes.string,
        date: PropTypes.string,
        neck: PropTypes.number,
        shoulder: PropTypes.number,
        bust: PropTypes.number,
        underBust: PropTypes.number,
        lowerWaist: PropTypes.number,
        rightWrist: PropTypes.number,
        leftWrist: PropTypes.number,
        rightArm: PropTypes.number,
        leftArm: PropTypes.number,
        rightForearm: PropTypes.number,
        leftForearm: PropTypes.number,
        rightPalm: PropTypes.number,
        leftPalm: PropTypes.number,
        waist: PropTypes.number,
        rightThigh: PropTypes.number,
        leftThigh: PropTypes.number,
        rightCalf: PropTypes.number,
        leftCalf: PropTypes.number,
        rightAnkle: PropTypes.number,
        leftAnkle: PropTypes.number,
        weight: PropTypes.number,
        leftOverKnee: PropTypes.number,
        rightOverKnee: PropTypes.number,
        rightFoot: PropTypes.number,
        leftFoot: PropTypes.number,
        hips: PropTypes.number,
        height: PropTypes.number,
    }),
    onMeasurementUpdated: PropTypes.func
};

export default MeasurementsForm;
