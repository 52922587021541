import axios from "axios";

const createApiInstance = (token, contentType = "application/json") => {
    const instance = axios.create({
        // eslint-disable-next-line no-undef
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-Type": contentType,
            Authorization: `Bearer ${token}`,
        },
    });

    instance.interceptors.response.use(
        (res) => {
            return res;
        },
        (err) => {
            //const { response } = err;
            // if (response && response.status === 401) {
            //     EventBus.dispatch("logout");
            // }
            return Promise.reject(err);
        }
    );

    return instance;
};

export default createApiInstance;
