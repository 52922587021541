import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Button onClick={() => loginWithRedirect()} className="mb-2 me-2">
            Log In
        </Button>
    );
};

export default LoginButton;
