import React from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { datadogLogs } from "@datadog/browser-logs";

function ExerciseAssignmentForm({ exerciseAssignment, onChange, mode }) {
    const handleChange = (field, value) => {
        datadogLogs.logger.info("Changing exercise assignment field", { field, value });
        onChange(field, value);
    };

    datadogLogs.logger.info("Exercise assignment details", { exerciseAssignment });
    //console.log(exerciseAssignment);

    // check if extraData and result are present, then create sets data
    const sets = exerciseAssignment.extraData?.result ? exerciseAssignment.extraData.result.completedReps.map((rep, index) => {
        let formattedSet = `Set ${index + 1} repetitions: ${rep}`;
        
        if (exerciseAssignment.exercise.repetitionMeasure === "weight") {
            formattedSet += ` with ${exerciseAssignment.extraData.result.measure[index] ?? ""} lbs`;
        } else if (exerciseAssignment.exercise.repetitionMeasure === "time") {
            formattedSet += ` with time of ${exerciseAssignment.extraData.result.measure[index] ?? ""} seconds`;
        } else if (exerciseAssignment.exercise.repetitionMeasure === "distance") {
            formattedSet += ` with time of ${exerciseAssignment.extraData.result.measure[index] ?? ""} feet`;
        }
        
        datadogLogs.logger.info("Set details", { formattedSet });
        return formattedSet;
    }) : [];
    
    datadogLogs.logger.info("Exercise assignment details after creating sets", { exerciseAssignment });
    //console.log(exerciseAssignment);

    // Display the formatted sets details
    const setsDetails = sets.length > 0 ? (
        <Row>
            <Col xs={12}>
                <Form.Group controlId="setsDetails">
                    <Form.Label>Sets Details</Form.Label>
                    <ul>
                        {sets.map((set, index) => (
                            <li key={index}>{set}</li>
                        ))}
                    </ul>
                </Form.Group>
            </Col>
        </Row>
    ) : null;
    
    // Display notes if present
    const notesDetails = exerciseAssignment.extraData?.notes ? (
        <Row>
            <Col xs={12}>
                <Form.Group controlId="notes">
                    <Form.Label>Notes</Form.Label>
                    {mode === "view" ?
                        <h6>{exerciseAssignment.extraData.notes}</h6>
                        :
                        <Form.Control type="text" value={exerciseAssignment.extraData.notes} onChange={e => handleChange("notes", e.target.value)} />
                    }
                </Form.Group>
            </Col>
        </Row>
    ) : null;

    function convertToLocalTime(timeString) {
        if (!timeString) return "N/A";
    
        const currentUTCDate = new Date(); // Get the current UTC date
        const timeUTC = timeString.split(":");
        const time = new Date(Date.UTC(
            currentUTCDate.getUTCFullYear(),
            currentUTCDate.getUTCMonth(),
            currentUTCDate.getUTCDate(),
            parseInt(timeUTC[0], 10), // Hours
            parseInt(timeUTC[1], 10), // Minutes
            parseInt(timeUTC[2], 10)  // Seconds
        ));
        return time.toLocaleTimeString();
    }
    
    
    return (
        <Container fluid>
            {exerciseAssignment.exercise.description && (
                <Row>
                    <Form.Group controlId="description">
                        <Form.Label style={{fontSize: "1.15rem"}}>Description</Form.Label>
                        {mode === "view" ?
                            <h6>{exerciseAssignment.exercise.description}</h6>
                            :
                            <Form.Control type="text" value={exerciseAssignment.exercise.description} onChange={e => handleChange("description", e.target.value)} />
                        }
                    </Form.Group>
                </Row>
            )}
            <Row>
                {exerciseAssignment.exercise.sets && (
                    <Col xs={6} md={6}>
                        <Form.Group controlId="Sets">
                            <Form.Label>Sets</Form.Label>
                            {mode === "view" ?
                                <h4>{exerciseAssignment.exercise.sets}</h4>
                                :
                                <Form.Control type="number" value={exerciseAssignment.exercise.sets} onChange={e => handleChange("sets", e.target.value)} />
                            }
                        </Form.Group>
                    </Col>
                )}  
                {exerciseAssignment.exercise.repetitions && (
                    <Col xs={6} md={6}>
                        <Form.Group controlId="repetitions">
                            <Form.Label>Repetitions</Form.Label>
                            {mode === "view" ?
                                <h4>{exerciseAssignment.exercise.repetitions}</h4>
                                :
                                <Form.Control type="number" value={exerciseAssignment.exercise.repetitions} onChange={e => handleChange("repetitions", e.target.value)} />
                            }
                        </Form.Group>
                    </Col>
                )}
            </Row>
            <Row>
                {exerciseAssignment.extraData.endTime && (
                    <Col xs={12} md={4}>
                        <Form.Group controlId="endTime">
                            <Form.Label>Completed Time</Form.Label>
                            {mode === "view" ?
                                <h4>{convertToLocalTime(exerciseAssignment.extraData.endTime)}</h4>
                                :
                                <Form.Control type="text" value={exerciseAssignment.extraData.endTime} onChange={e => handleChange("endTime", e.target.value)} />
                            }
                        </Form.Group>
                    </Col>
                )}
            </Row>
            {exerciseAssignment.exercise.exerciseSteps && exerciseAssignment.exercise.exerciseSteps.length > 0 && mode === "view" && (
                <Row>
                    <Col xs={12}>
                        <hr style={{ borderTop: "1px solid black" }}/>
                        <h6>Exercise Steps</h6>
                        <ul>
                            {exerciseAssignment.exercise.exerciseSteps.map((step, index) => (
                                <li key={index}>{step.description}</li>
                            ))}
                        </ul>
                    </Col>
                </Row>
            )}
            {setsDetails}
            {notesDetails}
            <Row>
                <Col xs={12}>
                    <Button variant="primary" onClick={() => console.log("Save Changes")} hidden={mode === "view"}>Save Changes</Button>
                </Col>
            </Row>
        </Container>
    );
      
}

ExerciseAssignmentForm.propTypes = {
    exerciseAssignment: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]).isRequired
};

export default ExerciseAssignmentForm;
