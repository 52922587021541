import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import AthleteDetailSection from "./AthleteDetailsSection";
import CreateAthleteProgram from "../../athletemanager/CreateAthleteProgram";

const AthleteModal = ({
    showModal,
    onClose,
    selectedAthlete,
    athletePrograms,
    handleProgramAssignmentChange,
    measurements,
    activeAccordionKey,
    onAccordionChange,
    modalMode,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    enabled,
    setEnabled,
    handleAssignProgram,
    programs,
    athletes,
}) => (
    <Modal show={showModal} onHide={onClose} className="custom-modal">
        <Modal.Header closeButton/>
        {modalMode === "view" && (
            <AthleteDetailSection
                selectedAthlete={selectedAthlete}
                athletePrograms={athletePrograms}
                handleProgramAssignmentChange={handleProgramAssignmentChange}
                measurements={measurements}
                activeAccordionKey={activeAccordionKey}
                onAccordionChange={onAccordionChange}
            />
        )}
        {modalMode === "create" && (
            <Modal.Body className="d-flex justify-content-center">
                <CreateAthleteProgram
                    show={true}
                    selectedAthlete={selectedAthlete}
                    athletePrograms={athletePrograms}
                    handleProgramAssignmentChange={handleProgramAssignmentChange}
                    handleAssignProgram={handleAssignProgram}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    enabled={enabled}
                    setEnabled={setEnabled}
                    programs={programs}
                    onClose={onClose}
                    athletes={athletes}
                />
            </Modal.Body>
        )}
    </Modal>
);

AthleteModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedAthlete: PropTypes.object,
    athletePrograms: PropTypes.array.isRequired,
    handleProgramAssignmentChange: PropTypes.func.isRequired,
    measurements: PropTypes.array,
    activeAccordionKey: PropTypes.string,
    onAccordionChange: PropTypes.func.isRequired,
    modalMode: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    setStartDate: PropTypes.func.isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    setEndDate: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired,
    setEnabled: PropTypes.func.isRequired,
    handleAssignProgram: PropTypes.func.isRequired,
    programs: PropTypes.array.isRequired,
    athletes: PropTypes.array.isRequired
};

export default AthleteModal;
