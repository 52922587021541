import React from "react";
import { Image } from "react-bootstrap";
import PropTypes from "prop-types";

const MediaRenderer = ({ url, mediaType }) => {
    if (mediaType === "image") {
        return <Image src={url} rounded />;
    } else if (mediaType === "video") {
        return <video controls src={url} />;
    }
    return null;
};

MediaRenderer.propTypes = {
    url: PropTypes.string.isRequired,
    mediaType: PropTypes.oneOf(["image", "video"]).isRequired,
};

export default MediaRenderer;
