import React, { useState } from "react";
import { Container, Row, Col, Collapse, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import MeasurementHistory from "../athlete/MeasurementHistory";
import MeasurementsChart from "../athlete/MeasurementsChart";

const AthleteProfile = ({ user, measurements }) => {

    const [openMeasurements, setOpenMeasurements] = useState(false);
    const [openRecords, setOpenRecords] = useState(false);

    if (!user) {
        return null;
    }

    const { name, email, dateOfBirth, gender, height, weight, primarySport, personalRecords, preferredUnit } = user;
    //console.log(user);
    const renderObject = (obj) => {
        if (!obj) {
            return "N/A";
        }

        return Object.entries(obj).map(([key, value], i) => (
            <div key={i}><strong>{key}:</strong> {value}</div>
        ));
    };


    return (
        <Container fluid>
            <Row>
                <Col xs={12} md={6}>
                    <strong>Name:</strong> {name || "N/A"}
                </Col>

                <Col xs={12} md={6}>
                    <strong>Primary Sport:</strong> {primarySport || "N/A"}
                </Col>

                <Col xs={12} md={6}>
                    <strong>Email:</strong> {email || "N/A"}
                </Col>

                <Col xs={12} md={6}>
                    <strong>Date of Birth:</strong> {dateOfBirth || "N/A"}
                </Col>

                <Col xs={12} md={6}>
                    <strong>Gender:</strong> {gender || "N/A"}
                </Col>

                <Col xs={12} md={6}>
                    <strong>Height:</strong> {height || "N/A"}
                </Col>

                <Col xs={12} md={6}>
                    <strong>Weight:</strong> {weight || "N/A"}
                </Col>
            </Row>
            <Row>

                <hr />

                <Col >
                    <Button
                        onClick={() => setOpenMeasurements(!openMeasurements)}
                        aria-controls="body-measurements"
                        aria-expanded={openMeasurements}
                    >
        Body Measurements
                    </Button>
                    <Collapse in={openMeasurements}>
                        <div id="body-measurements">
                            <Row>
                                <Col xs={12}>
                                    <div className="chart-container">
                                        <MeasurementsChart data={measurements} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <MeasurementHistory measurements={measurements} /> 
                            </Row>
                        </div>
                    </Collapse>
                </Col>


                <Row>
                    <Col xs={12} md={6}>
                        <Button
                            onClick={() => setOpenRecords(!openRecords)}
                            aria-controls="personal-records"
                            aria-expanded={openRecords}
                            style={{ marginTop: "10px" }}
                        >
    Personal Records
                        </Button>
                        <Collapse in={openRecords}>
                            <div id="personal-records">
                                {renderObject(personalRecords)}
                            </div>
                        </Collapse>
                    </Col>
                </Row>
                <hr />
            </Row>
        </Container>
    );
};

AthleteProfile.propTypes = {
    user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        dateOfBirth: PropTypes.string,
        gender: PropTypes.string,
        height: PropTypes.number,
        weight: PropTypes.number,
        bodyMeasurements: PropTypes.object,
        primarySport: PropTypes.string,
        personalRecords: PropTypes.object,
        preferredUnit: PropTypes.string,
    }),
    measurements: PropTypes.array.isRequired,
};

export default AthleteProfile;
