import React from "react";
import { Table, Container, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import PropTypes from "prop-types";

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Raw and percentage change since the current measurement.
    </Tooltip>
);

const getInitialMeasurements = (sortedMeasurements, keys) => {
    const initialMeasurements = {};
    const initialDates = {};
    const reversedMeasurements = [...sortedMeasurements].reverse(); // Reverse the array

    keys.forEach((key) => {
        // Iterate through reversedMeasurements to find the first non-zero value for each key
        reversedMeasurements.some((measurement) => {
            if (measurement[key] !== 0) {
                initialMeasurements[key] = measurement[key];
                initialDates[key] = measurement.date;
                return true; // Break the loop when a non-zero value is found
            }
            return false; // Continue looping
        });

        // Set to 0 if no non-zero value was found
        if (initialMeasurements[key] === undefined) {
            initialMeasurements[key] = 0;
            initialDates[key] = "";
        }
    });

    return { initialMeasurements, initialDates };
};

const getPriorMeasurements = (sortedMeasurements, keys) => {
    const priorMeasurements = {};
    const priorDates = {};

    keys.forEach((key) => {
        let nonZeroCount = 0;

        // Iterate through sortedMeasurements to find the second non-zero value for each key
        sortedMeasurements.some((measurement) => {
            if (measurement[key] !== 0) {
                nonZeroCount += 1;

                // Record the second non-zero value found
                if (nonZeroCount === 2) {
                    priorMeasurements[key] = measurement[key];
                    priorDates[key] = measurement.date;
                    return true; // Break the loop when the second non-zero value is found
                }
            }
            return false; // Continue looping
        });

        // If there was only one non-zero value, then use the first one
        if (nonZeroCount === 1) {
            sortedMeasurements.some((measurement) => {
                if (measurement[key] !== 0) {
                    priorMeasurements[key] = measurement[key];
                    priorDates[key] = measurement.date;
                    return true;
                }
                return false;
            });
        }

        // Set to 0 if no non-zero value was found
        if (priorMeasurements[key] === undefined) {
            priorMeasurements[key] = 0;
            priorDates[key] = "";
        }
    });

    return { priorMeasurements, priorDates };
};



const MeasurementHistory = ({ measurements }) => {
    if (measurements.length === 0) {
        return <div>No measurements available</div>;
    }
  
    // Sort the measurements by date
    const sortedMeasurements = [...measurements].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
    );
  

    // Define the keys and labels to display
    const keysAndLabels = [
        { key: "date", label: "Date" },
        { key: "height", label: "Height" },
        { key: "weight", label: "Weight" },
        { key: "neck", label: "Neck" },
        { key: "shoulder", label: "Shoulder" },
        { key: "bust", label: "Bust" },
        { key: "underBust", label: "Under Bust" },
        { key: "lowerWaist", label: "Lower Waist" },
        { key: "rightWrist", label: "Right Wrist" },
        { key: "leftWrist", label: "Left Wrist" },
        { key: "rightArm", label: "Right Arm" },
        { key: "leftArm", label: "Left Arm" },
        { key: "rightForearm", label: "Right Forearm" },
        { key: "leftForearm", label: "Left Forearm" },
        { key: "rightPalm", label: "Right Palm" },
        { key: "leftPalm", label: "Left Palm" },
        { key: "waist", label: "Waist" },
        { key: "rightThigh", label: "Right Thigh" },
        { key: "leftThigh", label: "Left Thigh" },
        { key: "rightCalf", label: "Right Calf" },
        { key: "leftCalf", label: "Left Calf" },
        { key: "rightAnkle", label: "Right Ankle" },
        { key: "leftAnkle", label: "Left Ankle" },
        { key: "leftOverKnee", label: "Left Over Knee" },
        { key: "rightOverKnee", label: "Right Over Knee" },
        { key: "rightFoot", label: "Right Foot" },
        { key: "leftFoot", label: "Left Foot" },
        { key: "hips", label: "Hips" },
    ];


    // Extract the most recent, prior, and oldest entries
    const mostRecent = sortedMeasurements[0] || {};
    const { initialMeasurements, initialDates } = getInitialMeasurements(sortedMeasurements, keysAndLabels.map(({ key }) => key));
    const { priorMeasurements, priorDates } = getPriorMeasurements(sortedMeasurements, keysAndLabels.map(({ key }) => key));
  
    

    return (
        <Container>
            <Row>
                <h3>Measurement Summary</h3>
            </Row>
            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Current</th>
                            <th>
                                Prior
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <span style={{ cursor: "pointer" }}> (?)</span>
                                </OverlayTrigger>
                            </th>
                            <th>
                                Initial
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <span style={{ cursor: "pointer" }}> (?)</span>
                                </OverlayTrigger>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {keysAndLabels.map(({ key, label }) => {
                            const currentValue = mostRecent[key];
                            const priorValue = key === "date" ? "" : priorMeasurements[key];
                            const initialValue = key === "date" ? "" : initialMeasurements[key];

                            // Skip the row if all values are zero for this key (excluding "date")
                            if (key !== "date" && currentValue === 0 && priorValue === 0 && initialValue === 0) {
                                return null;
                            }

                            let rawChangePrior = (currentValue - priorValue).toFixed(2);
                            let percentChangePrior = priorValue !== 0 ? ((currentValue - priorValue) / priorValue * 100).toFixed(2) : "N/A";

                            let rawChangeInitial = (currentValue - initialValue).toFixed(2);
                            let percentChangeInitial = initialValue !== 0 ? ((currentValue - initialValue) / initialValue * 100).toFixed(2) : "N/A";

                            // Format raw changes
                            if (key !== "date") {
                                rawChangePrior = parseFloat(rawChangePrior) === 0 ? "0.00" : (rawChangePrior >= 0 ? "+" : "") + rawChangePrior;
                                rawChangeInitial = parseFloat(rawChangeInitial) === 0 ? "0.00" : (rawChangeInitial >= 0 ? "+" : "") + rawChangeInitial;
                            }

                            const priorStyle = key !== "date" && parseFloat(rawChangePrior) !== 0 ? { color: parseFloat(rawChangePrior) > 0 ? "green" : "red" } : {};
                            const initialStyle = key !== "date" && parseFloat(rawChangeInitial) !== 0 ? { color: parseFloat(rawChangeInitial) > 0 ? "green" : "red" } : {};

                            return (
                                <tr key={key}>
                                    <td>{label}</td>
                                    <td>{currentValue}</td>
                                    <td>
                                        {priorValue}
                                        {key !== "date" ? (
                                            <>
                                                <span style={priorStyle}>
                                                    {` (${rawChangePrior}/${percentChangePrior}%)`}
                                                </span>
                                                <br /> {/* New line */}
                                                {priorDates[key] ? ` ${priorDates[key]}` : ""}
                                            </>
                                        ) : ""}
                                    </td>
                                    <td>
                                        {initialValue}
                                        {key !== "date" ? (
                                            <>
                                                <span style={initialStyle}>
                                                    {` (${rawChangeInitial}/${percentChangeInitial}%)`}
                                                </span>
                                                <br /> {/* New line */}
                                                {initialDates[key] ? ` ${initialDates[key]}` : ""}
                                            </>
                                        ) : ""}
                                    </td>


                                </tr>
                            );
                        })}
                    </tbody>


                </Table>
            </Row>
        </Container>
    );
};

MeasurementHistory.propTypes = {
    measurements: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                userId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
                neck: PropTypes.number.isRequired,
                shoulder: PropTypes.number.isRequired,
                bust: PropTypes.number.isRequired,
                underBust: PropTypes.number.isRequired,
                lowerWaist: PropTypes.number.isRequired,
                rightWrist: PropTypes.number.isRequired,
                leftWrist: PropTypes.number.isRequired,
                rightArm: PropTypes.number.isRequired,
                leftArm: PropTypes.number.isRequired,
                rightForearm: PropTypes.number.isRequired,
                leftForearm: PropTypes.number.isRequired,
                rightPalm: PropTypes.number.isRequired,
                leftPalm: PropTypes.number.isRequired,
                waist: PropTypes.number.isRequired,
                rightThigh: PropTypes.number.isRequired,
                leftThigh: PropTypes.number.isRequired,
                rightCalf: PropTypes.number.isRequired,
                leftCalf: PropTypes.number.isRequired,
                rightAnkle: PropTypes.number.isRequired,
                leftAnkle: PropTypes.number.isRequired,
                weight: PropTypes.number.isRequired,
                leftOverKnee: PropTypes.number.isRequired,
                rightOverKnee: PropTypes.number.isRequired,
                rightFoot: PropTypes.number.isRequired,
                leftFoot: PropTypes.number.isRequired,
                hips: PropTypes.number.isRequired,
            })
        ),
        PropTypes.string,
    ]).isRequired,
};

export default MeasurementHistory;
