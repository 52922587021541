import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const SignupButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Button onClick={() => loginWithRedirect({authorizationParams: {screen_hint: "signup"}})}  className="mb-2 ml-2">
            Sign Up
        </Button>
    );
};

export default SignupButton;
