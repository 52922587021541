import { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const useGetToken = () => {
    const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);

    return {
        getToken,
        user,
        isLoading,
        isAuthenticated,
    };
};
