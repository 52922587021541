import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import UserProfileService from "../../services/user.profile.service";
import TeamService from "../../services/team.service";
import { datadogLogs } from "@datadog/browser-logs";
import { useAuth0 } from "@auth0/auth0-react";

const MyTeams = () => {
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [athletes, setAthletes] = useState([]);
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const [trainers, setTrainers] = useState([]);


    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);

    const fetchTeams = async () => {
        if (isAuthenticated) {
            try {
                const token = await getToken();
                const response = await TeamService.getAllByUser(token, user.sub);
                setTeams(response || []);
            } catch (error) {
                console.error("Error fetching teams:", error);
                datadogLogs.logger.error("Error fetching teams:", { error });
            }
        }
    };

    useEffect(() => {
        fetchTeams();
    }, [isAuthenticated, user.sub]);

    const handleView = async (team) => {
        setShowModal(true);
        setSelectedTeam(team);
        const token = await getToken();
    
        const athleteProfiles = await Promise.all(
            team.athletes.map(async (athleteId) => ({
                id: athleteId,
                name: await UserProfileService.getUserName(token, athleteId)
            }))
        );
        setAthletes(athleteProfiles);
    
        const trainerProfiles = await Promise.all(
            team.trainers.map(async (trainerId) => ({
                id: trainerId,
                name: await UserProfileService.getUserName(token, trainerId)
            }))
        );
        setTrainers(trainerProfiles);
    };
    

    const handleClose = () => {
        setShowModal(false);
        setSelectedTeam(null);
        setAthletes([]);
    };

    return (
        <Container fluid>
            <h2>My Teams</h2>
            {teams.map((team) => (
                <Row key={team.id} className="mb-2 align-items-center">
                    <Col className="team-name">{team.name}</Col>
                    <Col xs="auto">
                        <Button variant="primary" onClick={() => handleView(team)}>
                View
                        </Button>
                    </Col>
                </Row>
            ))}

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedTeam?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {trainers.length > 0 && (
                        <>
                            <h5>Trainers</h5>
                            <ul>
                                {trainers.map((trainer) => (
                                    <li key={trainer.id}>{trainer.name}</li>
                                ))}
                            </ul>
                        </>
                    )}

                    {athletes.length > 0 && (
                        <>
                            <h5>Athletes</h5>
                            <ul>
                                {athletes.map((athlete) => (
                                    <li key={athlete.id}>{athlete.name}</li>
                                ))}
                            </ul>
                        </>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
            Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
};

export default MyTeams;