import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Button, Form, Container, Row, Col, Modal } from "react-bootstrap";
import ExerciseAssignmentForm from "../trainer/programform/ExerciseAssignmentForm";
import { BsFillPencilFill } from "react-icons/bs";
import { AiFillSave, AiFillMessage, AiFillCloseCircle } from "react-icons/ai";

const ExerciseAssignment = ({ exerciseAssignment, onCompleteExerciseAssignment }) => {
    const { exercise } = exerciseAssignment;
    console.log(exerciseAssignment);

    const [sets, setSets] = useState(exercise.sets || 0);
    const [completedReps, setCompletedReps] = useState(Array(sets).fill(""));
    const [measure, setMeasure] = useState(Array(sets).fill(""));    
    const [isEditing, setIsEditing] = useState(exerciseAssignment.extraData.endTime === null || exerciseAssignment.extraData.endTime === undefined); // Set initial editing mode based on endTime
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [notes, setNotes] = useState("");
    const [validationError, setValidationError] = useState(null);
    const [athleteAddedSet, setAthleteAddedSet] = useState(false);
    const originalSets = exercise.sets;



    const handleInputChange = (index, setter) => (event) => {
        setter(prevValues => {
            const newValues = [...prevValues];
            newValues[index] = event.target.value;
            return newValues;
        });
    };

    const handleExerciseAssignmentChange = () => {

    };

    const handleEditClick = () => {
        console.log("edit click");
        setIsEditing(true);
    };

    const handleCompleteExercise = () => {
        // Check if all completedReps are whole numbers or blank
        if (completedReps.some(rep => rep.trim() !== "" && !Number.isInteger(parseFloat(rep)))) {
            setValidationError("Completed reps must be whole numbers or blank.");
            return; // Stop the function if validation fails
        }
    
        // Reset the validation error if any
        setValidationError(null);
    
        const exerciseData = {
            completedReps,
            measure,
            notes
        };
        onCompleteExerciseAssignment(exerciseAssignment, exerciseData);
        setIsEditing(false); // Set editing state back to false
    };

    const handleAddSet = () => {
        setSets(prevSets => prevSets + 1);
        setCompletedReps(prevReps => [...prevReps, ""]);
        setMeasure(prevMeasure => [...prevMeasure, ""]);
        setAthleteAddedSet(true); // Set to true when an athlete adds a set
    };

    const handleDeleteLastSet = () => {
        if (sets > originalSets) { // Only delete if there are additional sets beyond the original ones
            setSets(prevSets => prevSets - 1);
            setCompletedReps(prevReps => prevReps.slice(0, -1));
            setMeasure(prevMeasure => prevMeasure.slice(0, -1));
            // If sets are equal to originalSets after deletion, update athleteAddedSet to false
            if (sets - 1 === originalSets) {
                setAthleteAddedSet(false);
            }
        }
    };
    
    

    const setsInputs = [...Array(sets).keys()].map((setNumber) => (
        <div key={setNumber}>
            <Form.Label>Set {setNumber + 1}</Form.Label>
            <Form.Control type="number" placeholder="Enter completed reps" value={completedReps[setNumber]} onChange={handleInputChange(setNumber, setCompletedReps)} disabled={!isEditing} />
            {(exercise.repetitionMeasure !== "none" || measure[setNumber]) && (
                <Form.Control 
                    type="text" 
                    placeholder={`Enter ${measure[setNumber] || exercise.repetitionMeasure}`} 
                    value={measure[setNumber]} 
                    onChange={handleInputChange(setNumber, setMeasure)} 
                    style={{marginTop: "10px"}}
                    disabled={!isEditing} 
                />
            )}
        </div>
    ));

    const currentUTCDate = new Date(); // Get the current UTC date

    let endTimeString = "N/A"; // Default value
    
    if (exerciseAssignment.extraData && exerciseAssignment.extraData.endTime) {
        const endTimeUTC = exerciseAssignment.extraData.endTime.split(":");
        const endTime = new Date(Date.UTC(
            currentUTCDate.getUTCFullYear(),
            currentUTCDate.getUTCMonth(),
            currentUTCDate.getUTCDate(),
            parseInt(endTimeUTC[0], 10), // Hours
            parseInt(endTimeUTC[1], 10), // Minutes
            parseInt(endTimeUTC[2], 10)  // Seconds
        ));
        endTimeString = endTime.toLocaleTimeString();
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Card className="text-center">
                        <Card.Body>
                            {validationError && <div className="text-danger">{validationError}</div>}
                            <Card.Title>{exercise.exerciseName}</Card.Title>
                            <ExerciseAssignmentForm mode="view" onChange={handleExerciseAssignmentChange} exerciseAssignment={{exercise: exercise, extraData:{endTime: null}}} />
                            {!isEditing && (
                                <Card.Text>
                                    End Time: {endTimeString}
                                </Card.Text>
                            )}
                            { (sets > 0 || athleteAddedSet) && isEditing && (
                                <Form>
                                    {setsInputs}
                                </Form>
                            )}
                            {isEditing && (
                                <div className="mt-2 d-flex justify-content-between">
                                    <Button variant="info" size="sm" onClick={handleAddSet}>Add Set</Button>
                                    <Button variant="danger" size="sm" onClick={handleDeleteLastSet} disabled={sets <= exercise.sets}>Delete Last Set</Button>
                                </div>
                            )}

                            {isEditing && (
                                <div className="mt-3 d-flex justify-content-between">
                                    <Button variant="primary" size="lg" onClick={handleCompleteExercise}><AiFillSave /></Button>
                                    <Button variant="secondary" size="lg" onClick={() => setShowNotesModal(true)}><AiFillMessage /></Button> {/* Notes button */}
                                </div>
                            )}
                            {!isEditing && (
                                <Button variant="secondary" className="mt-3" onClick={handleEditClick}><BsFillPencilFill /></Button>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* Notes Modal */}
            <Modal show={showNotesModal} onHide={() => setShowNotesModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control as="textarea" rows={3} value={notes} onChange={(e) => setNotes(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setShowNotesModal(false)}><AiFillCloseCircle /></Button>
                    <Button variant="primary" size="lg" onClick={() => setShowNotesModal(false)}><AiFillSave /></Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

ExerciseAssignment.propTypes = {
    exerciseAssignment: PropTypes.shape({
        id: PropTypes.string.isRequired,
        exercise: PropTypes.shape({
            id: PropTypes.string.isRequired,
            exerciseName: PropTypes.string.isRequired,
            sets: PropTypes.number.isRequired,
            repetitions: PropTypes.number.isRequired,
            repetitionType: PropTypes.string.isRequired,
            repetitionMeasure: PropTypes.string.isRequired,
            description: PropTypes.string,
            vid_link: PropTypes.string
        }).isRequired,
        completedSets: PropTypes.number,
        totalWeight: PropTypes.number,
        videoLink: PropTypes.string,
        status: PropTypes.string.isRequired,
        endTime: PropTypes.string,
        extraData: PropTypes.object
    }).isRequired,
    onCompleteExerciseAssignment: PropTypes.func.isRequired
};

export default ExerciseAssignment;
