import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Button } from "react-bootstrap";

const EventModal = ({ event, show, handleClose, handleSave, handleDeleteEvent, teams }) => {
    const [formState, setFormState] = useState(event || {});
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    //console.log(teams);

    // Sync state when `event` prop changes
    useEffect(() => {
        setFormState(event || {});
    }, [event]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const finalValue = type === "checkbox" ? checked : value;
        setFormState({
            ...formState,
            [name]: finalValue,
        });
    };

    const handleSubmit = () => {
        // If event type is "team", add teamId to participants
        if (formState.eventType === "team") {
            formState.participants = [...(formState.participants || []), selectedTeamId];
        }
        
        handleSave(formState);
        handleClose();
    };

    const handleDeleteClick = () => {
        handleDeleteEvent(event.id);
        handleClose();
    };

    const formatDate = (date) => {

        //Some times the date is a string depnding on locale.
        if (!(date instanceof Date)) {
            date = new Date(date);
        }

        //just in case some how it still is not a date log it and eat the error.
        if (!(date instanceof Date)) {
            console.error("Passed argument is not a Date object");
            return "";
        }
        const localDate = new Date(date);
        localDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return localDate.toJSON().slice(0, 16);
    };

    const handleTeamChange = (e) => {
        console.log(e);
        setSelectedTeamId(e.target.value);
    };


    
      
    //console.log(formState);

    return (
        <Modal show={show} className="custom-modal">
            <Modal.Header>
                <Modal.Title>{event?.id ? "Edit Event" : "Create Event"}</Modal.Title>
                <Button variant="close" onClick={handleClose}>X</Button>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            value={formState.title || ""}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Event Type</Form.Label>
                        <Form.Control as="select" name="eventType" value={formState.eventType || ""} onChange={handleChange}>
                            <option value="user">User</option>
                            <option value="team">Team</option>
                        </Form.Control>
                    </Form.Group>
                    {formState.eventType === "team" && (
                        <Form.Group>
                            <Form.Label>Team</Form.Label>
                            <Form.Control as="select" value={selectedTeamId || ""} onChange={handleTeamChange}>
                                <option value="" disabled>Select a team</option>
                                {teams.map((team) => (
                                    <option key={team.id} value={team.id}>{team.name}</option>
                                ))}
                            </Form.Control>

                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Check 
                            type="checkbox"
                            label="Private"
                            name="privateEvent"
                            checked={formState.privateEvent || false}
                            onChange={handleChange}
                        />
                    </Form.Group>
      
                    <Form.Group>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                            type="text"
                            name="location"
                            value={formState.location || ""}
                            onChange={handleChange}
                        />
                    </Form.Group>
      
                    <Form.Group>
                        <Form.Label>Start Time</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            name="start"
                            value={formState.start ? formatDate(formState.start) : ""}
                            onChange={handleChange}
                        />
                    </Form.Group>
      
                    <Form.Group>
                        <Form.Label>End Time</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            name="end"
                            value={formState.end ? formatDate(formState.end) : ""}
                            onChange={handleChange}
                        />
                    </Form.Group>
      
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            value={formState.description || ""}
                            onChange={handleChange}
                        />
                    </Form.Group>
      
                    <Form.Group>
                        <Form.Check 
                            type="checkbox"
                            label="All Day Event"
                            name="allDay"
                            checked={formState.allDay || false}
                            onChange={handleChange}
                        />
                    </Form.Group>
      
                    <Form.Group>
                        <Form.Label>Repeatable</Form.Label>
                        <Form.Control as="select" name="repeatable" value={formState.repeatable || ""} onChange={handleChange}>
                            <option value="none">None</option>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                        </Form.Control>
                    </Form.Group>
      
                    {/* Additional form fields can be added here similarly */}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDeleteClick}>
                    Delete
                </Button>
                <Button variant="secondary" onClick={handleClose}>
              Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
              Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EventModal.propTypes = {
    event: PropTypes.shape({
        userId: PropTypes.string,
        createdByRole: PropTypes.string,
        teamId: PropTypes.string,
        trainerId: PropTypes.string,
        eventType: PropTypes.string,
        eventCategory: PropTypes.string,
        privateEvent: PropTypes.bool,
        visibility: PropTypes.string,
        title: PropTypes.string,
        location: PropTypes.string,
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
        allDay: PropTypes.bool,
        id: PropTypes.string,
        description: PropTypes.string,
        repeatable: PropTypes.string,
        resourcesNeeded: PropTypes.arrayOf(PropTypes.string),
        status: PropTypes.string,
        participants: PropTypes.arrayOf(PropTypes.string),
        notes: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        draggable: PropTypes.bool,
        resizable: PropTypes.bool,
    }),
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleDeleteEvent: PropTypes.func,
    teams: PropTypes.array
};

export default EventModal;
