import React, { useState, useEffect, useCallback } from "react";
import { Button, ListGroup } from "react-bootstrap";
import TrainerProfileService from "../../services/trainer.profile.service";
import UserProfileService from "../../services/user.profile.service";
import { useAuth0 } from "@auth0/auth0-react";
import { datadogLogs } from "@datadog/browser-logs";

const AthleteAssignment = () => {
    const [trainers, setTrainers] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const { getAccessTokenSilently } = useAuth0();


 
    const fetchTrainersAndUsers = useCallback(async () => {
        const token = await getAccessTokenSilently();
        //console.log("Fetching trainers and users...");
    
        try {
            const trainersData = await TrainerProfileService.getTrainerProfilesWithUserData(token);
            //console.log("Trainers data:", trainersData);
    
            const usersData = await UserProfileService.getUserProfiles(token);
            //console.log("Users data:", usersData);
    
            const trainerUserIds = trainersData.map(trainer => trainer.trainerProfile.userId);
            
            let excludedUserIds = [...trainerUserIds];
            if (selectedTrainer) {
                const selectedTrainerData = trainersData.find(trainer => trainer.trainerProfile.id === selectedTrainer);
                const assignedUserIds = selectedTrainerData ? selectedTrainerData.trainerProfile.assignedAthletes : [];
                excludedUserIds = [...excludedUserIds, ...assignedUserIds];
            }
    
            setUsers(usersData.filter(user => !excludedUserIds.includes(user.id)));
            setTrainers(trainersData);
        } catch (error) {
            datadogLogs.logger.error("Error fetching trainers and users:", error);
        }
    }, [getAccessTokenSilently, selectedTrainer]);
    
    // Re-fetch users whenever the selected trainer changes
    useEffect(() => {
        fetchTrainersAndUsers();
    }, [fetchTrainersAndUsers, selectedTrainer]);
    

    // Updated AthleteAssignment component

    const assignUserToTrainer = async () => {
        if (selectedTrainer && selectedUser) {
            datadogLogs.logger.info("Assigning user to trainer:", selectedUser, "to", selectedTrainer);

            try {
                const token = await getAccessTokenSilently();
                await TrainerProfileService.assignAthleteToTrainer(token, selectedTrainer, selectedUser);
                datadogLogs.logger.info("User assigned to trainer successfully");

                setSelectedTrainer("");
                setSelectedUser("");
                fetchTrainersAndUsers();
            } catch (error) {
                console.log("Error assigning user to trainer:", error);
            }
        }
    };


    // console.log("Trainers:", trainers);
    // console.log("Users:", users);
    // console.log("Selected trainer:", selectedTrainer);
    // console.log("Selected user:", selectedUser);

    return (
        <div>
            <h2>Trainers</h2>
            <ListGroup>
                {trainers.map((trainer, index) => (
                    <ListGroup.Item
                        key={index}
                        action
                        active={selectedTrainer === trainer.trainerProfile.id}
                        onClick={() => setSelectedTrainer(trainer.trainerProfile.id)}
                    >
                        {trainer.userProfile.name} - {trainer.trainerProfile.id}
                    </ListGroup.Item>
                ))}
            </ListGroup>

            <h2>Users</h2>
            <ListGroup>
                {users.map((user, index) => (
                    <ListGroup.Item
                        key={index}
                        action
                        active={selectedUser === user.id}
                        onClick={() => setSelectedUser(user.id)}
                    >
                        {user.name} - {user.id}
                    </ListGroup.Item>
                ))}
            </ListGroup>

            <Button onClick={assignUserToTrainer} disabled={!selectedTrainer || !selectedUser}>
                Assign User to Trainer
            </Button>
        </div>
    );
};

export default AthleteAssignment;
