import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ScheduleController from "../../schedule/ScheduleController";
import EventService from "../../../services/event.service";
import EventModal from "../../schedule/EventModal";
import NavigationButtons from "./NavigationButtons";
import ScheduleManagement from "./ScheduleManagement";
import { useGetToken } from "../../../services/authUtils";
import useFetchEvents from "./customHooks/useFetchEvents";
import useFetchTeams from "./customHooks/useFetchTeams";
import useAthletes from "./customHooks/useAthletes";





const CoachLandingPage = () => {
    const [modalShow, setModalShow] = useState(false);
    const currentDate = new Date();
    // getToken
    const { user, isAuthenticated, getToken } = useGetToken();
    const [token, setToken] = useState(null);



    // custom hooks
    const [localEvents, fetchEventsError, fetchEvents] = useFetchEvents(user.sub, getToken);
    
    
    const start = new Date(currentDate);
    start.setHours(12, 0, 0);
    const end = new Date(currentDate);
    end.setHours(13, 0, 0);
    const initialEvent = { start, end };
    const [selectedEvent] = useState(initialEvent);

    useEffect(() => {
        (async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        })();
    }, [getToken]);

    const [teams, fetchTeamsError] = useFetchTeams(token, user.sub, isAuthenticated);
    const [athletes, fetchAthletesError] = useAthletes(token, user.sub, isAuthenticated);

    const handleEventChange = async (event) => {
        console.log(event);
        if (token) {
            try {
                // Checking if the event has an id or not
                if (event.id) {
                    // If it has an id, update it
                    const updatedEvent = await EventService.update(token, event.id, event);
                    console.log("Event updated:", updatedEvent);
                } else {
                    // If it doesn't have an id, create a new event
                    event.userId = user.sub;
                    event.draggable = true;
                    event.resizable = true;
                    const newEvent = await EventService.create(token, event);
                    console.log("Event created:", newEvent);
                }
                // Fetch the latest events using the custom hook function
                await fetchEvents();
            } catch (error) {
                console.error("Error handling event:", error);
            }
        }
    };

    const handleDeleteEvent = async (eventId) => {
        if (token) {
            try {
                await EventService.delete(token, eventId);
                // Wait for the delete to complete and then re-fetch events
                await fetchEvents();  
            } catch (error) {
                console.error(`Error deleting event with id ${eventId}:`, error);
            }
        }
    };

    const handleOnNewEventClick = () => {
        setModalShow(true);
    };

    //console.log(selectedEvent);
    return (
        <Container fluid className="mt-4">
            {fetchTeamsError && <p>Error fetching teams: {fetchTeamsError}</p>}
            {fetchEventsError && <p>Error fetching events: {fetchEventsError}</p>}
            {fetchAthletesError && <p>Error fetching athletes: {fetchAthletesError}</p>}
            <Helmet>
                <link rel="canonical" href="https://www.enlistedfitness.com/home" />
            </Helmet>
            <Row>
                <Col md={5} className="custom-cell-border" style={{ paddingBottom: "8px" }}>
                    <Row className="mt-4">
                        <h2>Quick Links</h2>
                        <NavigationButtons />
                    </Row>
                    <Row className="mt-4">
                        <ScheduleManagement onNewEventClick={handleOnNewEventClick} />
                    </Row>
                </Col>
                <Col className="custom-cell-border">
                    <h2>My Schedule</h2>
                    <ScheduleController
                        initialEvents={localEvents}
                        onEventChange={handleEventChange}
                        handleDeleteEvent={handleDeleteEvent}
                        teams={teams}
                    />
                </Col>
            </Row>
            <EventModal
                show={modalShow}
                handleClose={() => setModalShow(false)}
                handleSave={handleEventChange}
                event={selectedEvent}
                handleDeleteEvent={handleDeleteEvent}
                teams={teams}
            />
        </Container>
    );
};



export default CoachLandingPage;
