import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import MeasurementsForm from "../athlete/MeasurementsForm";
import { useAuth0 } from "@auth0/auth0-react";
import AthleteMeasurementsService from "../../services/athlete.measurements.service";
import MeasurementHistory from "./MeasurementHistory";
import MeasurementList from "./MeasurementList";
import MeasurementsChart from "./MeasurementsChart";

const MyMeasurements = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [showModal, setShowModal] = useState(false);
    const [measurements, setMeasurements] = useState([]);
    
    const sortedMeasurements = [...measurements].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
    );

    const mostRecent = sortedMeasurements[0] || {};



    const handleShow = () => setShowModal(true);

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);

    const fetchMeasurements = async () => {
        const token = await getToken();
        if (token) {
            try {
                const localMeasurements = await AthleteMeasurementsService.getAllByUserId(token, user.sub);
                //console.log("User's measurements:", localMeasurements);
                
                // Ensure that localMeasurements is an array before setting the state
                if (Array.isArray(localMeasurements)) {
                    return localMeasurements;
                } else {
                    return [];
                }
            } catch (error) {
                console.error("Error retrieving measurements:", error);
            }
        }
        return [];
    };    

    const handleClose = useCallback(() => {
        fetchMeasurements().then((localMeasurements) => setMeasurements(localMeasurements));
        setShowModal(false);
    }, [fetchMeasurements]);

    const onMeasurementUpdated = useCallback(() => {
        fetchMeasurements().then((localMeasurements) => setMeasurements(localMeasurements));
    }, [fetchMeasurements]);

    useEffect(() => {
        fetchMeasurements().then((localMeasurements) => setMeasurements(localMeasurements));
    }, [getToken, user.sub]);

    return (
        <Container fluid>
            <Row>
                <h2 style={{ marginTop: "1rem" }}>My Measurements</h2>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary" onClick={handleShow}>
                        Add New Measurement
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginTop: "1rem" }}>
                <MeasurementsChart data={measurements} />
            </Row>
            <Row style={{ marginTop: "1rem" }}>
                <MeasurementHistory measurements={measurements} />
            </Row>
            <Row>
                <MeasurementList measurements={sortedMeasurements} onMeasurementUpdated={onMeasurementUpdated} />
            </Row>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Measurement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MeasurementsForm handleClose={handleClose} priorMeasurements={mostRecent} />
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default MyMeasurements;
