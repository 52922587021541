import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import AthleteProfile from "../../trainer/AthleteProfile";
import ProgramAssignmentAccordion from "../../trainer/programform/ProgramAssignmentAccordion";

const AthleteDetailSection = ({
    selectedAthlete,
    onAssignProgramClick,
    athletePrograms,
    handleProgramAssignmentChange,
    measurements,
    activeAccordionKey,
    onAccordionChange,
}) => (
    <div>
        <h2>Athlete Profile</h2>
        <AthleteProfile user={selectedAthlete} displayMode="view" measurements={measurements} />
        
        {onAssignProgramClick && (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h2>Assigned Programs</h2>
                <Button onClick={onAssignProgramClick}>Assign a Program</Button>
            </div>
        )}
        
        <ProgramAssignmentAccordion
            athletePrograms={athletePrograms}
            handleProgramAssignmentChange={handleProgramAssignmentChange}
            mode="view"
            activeKey={activeAccordionKey}
            onAccordionChange={onAccordionChange}
        />
    </div>
);

AthleteDetailSection.propTypes = {
    selectedAthlete: PropTypes.object.isRequired,
    onAssignProgramClick: PropTypes.func,
    athletePrograms: PropTypes.array.isRequired,
    handleProgramAssignmentChange: PropTypes.func.isRequired,
    measurements: PropTypes.array.isRequired,
    activeAccordionKey: PropTypes.string,
    onAccordionChange: PropTypes.func.isRequired,
};

export default AthleteDetailSection;
