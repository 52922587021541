import React from "react";
import ProgramList from "./ProgramList";

const MyPrograms = () => {
    return (
        <div className="container">
            <header className="jumbotron">
                <ProgramList />
            </header>
        </div>
    );
};

export default MyPrograms;
