import createApiInstance from "./api";

const API_URL = "/programs";

const ProgramService = {
    getAll: async (token) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(API_URL);
            return response.data;
        } catch (error) {
            console.error("Error fetching programs:", error);
            throw error;
        }
    },

    get: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.get(`${API_URL}/${encodedId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching program with id ${id}:`, error);
            throw error;
        }
    },

    create: async (token, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post(API_URL, data);
            return response.data;
        } catch (error) {
            console.error("Error creating program:", error);
            throw error;
        }
    },

    update: async (token, id, data) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.put(`${API_URL}/${encodedId}`, data);
            return response.data;
        } catch (error) {
            console.error(`Error updating program with id ${id}:`, error);
            throw error;
        }
    },

    delete: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            await api.delete(`${API_URL}/${encodedId}`);
        } catch (error) {
            console.error(`Error deleting program with id ${id}:`, error);
            throw error;
        }
    },

    deleteAll: async (token) => {
        try {
            const api = createApiInstance(token);
            await api.delete(API_URL);
        } catch (error) {
            console.error("Error deleting all programs:", error);
            throw error;
        }
    },

    findByUserId: async (token, userId) => {
        try {
            const api = createApiInstance(token);
            const encodedUserId = encodeURIComponent(userId);
            const response = await api.get(`${API_URL}/?userId=${encodedUserId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching programs for user id ${userId}:`, error);
            throw error;
        }
    },

    addWorkout: async (token, programId, workoutId) => {
        try {
            const api = createApiInstance(token);
            const encodedProgramId = encodeURIComponent(programId);
            const encodedWorkoutId = encodeURIComponent(workoutId);
            const response = await api.post(`${API_URL}/${encodedProgramId}/workouts/${encodedWorkoutId}`);
            return response.data;
        } catch (error) {
            console.error(`Error adding workout with id ${workoutId} to program with id ${programId}:`, error);
            throw error;
        }
    },

    removeWorkout: async (token, programId, workoutId) => {
        try {
            const api = createApiInstance(token);
            const encodedProgramId = encodeURIComponent(programId);
            const encodedWorkoutId = encodeURIComponent(workoutId);
            await api.delete(`${API_URL}/${encodedProgramId}/workouts/${encodedWorkoutId}`);
        } catch (error) {
            console.error(`Error removing workout with id ${workoutId} from program with id ${programId}:`, error);
            throw error;
        }
    },

    getProgramsByTrainer: async (token, trainerId) => {
        try {
            const api = createApiInstance(token);
            const encodedTrainerId = encodeURIComponent(trainerId);
            const response = await api.get(`${API_URL}/trainer/${encodedTrainerId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching programs for trainer id ${trainerId}:`, error);
            throw error;
        }
    },

};

export default ProgramService;
