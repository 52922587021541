import React from "react";
import { Form, Accordion, Button, Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import WorkoutAssignmentForm from "./WorkoutAssignmentForm";

function ProgramAssignmentForm({ programAssignment, onChange, mode }) {
    const handleChange = (field, value) => {
        // onChange(field, value);
    };

    const handleWorkoutChange = (index, field, value) => {
        // onChange(`workoutAssignments.${index}.${field}`, value);
    };
    // console.log(mode);

    return (
        <Container fluid>
            <Form>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="startDate">
                            <Form.Label>Start Date</Form.Label>
                            {mode === "view" ?
                                <h4>{programAssignment.startDate}</h4>
                                :
                                <Form.Control type="date" readOnly={mode === "view"} value={programAssignment.startDate} onChange={e => handleChange("startDate", e.target.value)} />
                            }
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="endDate">
                            <Form.Label>End Date</Form.Label>
                            {mode === "view" ?
                                <h4>{programAssignment.endDate}</h4>
                                :
                                <Form.Control type="date" readOnly={mode === "view"} value={programAssignment.endDate} onChange={e => handleChange("endDate", e.target.value)} />
                            }
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group controlId="enabled">
                    <Form.Label>Enabled</Form.Label>
                    {mode === "view" ?
                        <h4>{programAssignment.enabled ? "Yes" : "No"}</h4>
                        :
                        <Form.Check type="checkbox" disabled={mode === "view"} checked={programAssignment.enabled} onChange={e => handleChange("enabled", e.target.checked)} />
                    }
                </Form.Group>
                <h2>Workouts</h2>
                <Accordion>
                    {programAssignment.workoutAssignments?.map((assignment, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header style={{fontSize: "20px", fontWeight: "bold"}}>
                                {assignment.workout.name}
                            </Accordion.Header>
                            <Accordion.Body>
                                <WorkoutAssignmentForm mode={mode} workoutAssignment={assignment} onChange={(field, value) => handleWorkoutChange(index, field, value)} />
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
                <Button variant="primary" onClick={() => console.log("Save Changes")} hidden={mode === "view"}>Save Changes</Button>
            </Form>
        </Container>
    );
    
}

ProgramAssignmentForm.propTypes = {
    programAssignment: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]).isRequired
};

export default ProgramAssignmentForm;
