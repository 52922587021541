import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { Container, Form, Dropdown, Button, Row, Col } from "react-bootstrap";

const MeasurementsChart = ({ data = [] }) => { // Default data to an empty array

    const excludedKeys = ["id", "userId", "date"];
    
    // Filter keys with non-zero values
    const keysWithNonZeroValues = Object.keys(data[0] || {}).filter(key => !excludedKeys.includes(key) && data.some(item => item[key] > 0));
    
    // Initialize selectedKeys with keys that have non-zero values
    const [selectedKeys, setSelectedKeys] = useState(keysWithNonZeroValues);



    // Handler function for checkbox changes
    const handleCheckboxChange = (e) => {
        const value = e.target.name;
        if (e.target.checked) {
            setSelectedKeys(prevKeys => [...prevKeys, value]);
        } else {
            setSelectedKeys(prevKeys => prevKeys.filter(key => key !== value));
        }
    };

    // State to store the width and height
    const [chartSize, setChartSize] = useState({
        width: window.innerWidth * 0.8, // 90% of the window width
        height: window.innerHeight * 0.6 // 60% of the window height
    });
    
    // Event listener to update the size when the window is resized
    const handleResize = () => {
        setChartSize({
            width: window.innerWidth * 0.8,
            height: window.innerHeight * 0.6
        });
    };

    // Use effect to add the event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (data.length === 0) {
        return <div>No Data Available</div>; // Return a message if there's no data
    }

    return (
        <Container fluid>
            <Row>
                <Col style={{ width: chartSize.width, height: chartSize.height }}>
                    <LineChart
                        width={chartSize.width}
                        height={chartSize.height}
                        data={data}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />

                        {keysWithNonZeroValues.map((key, index) => {
                            if (selectedKeys.includes(key)) {
                                return (
                                    <Line 
                                        key={index} 
                                        type="monotone" 
                                        dataKey={key} 
                                        stroke={`#${Math.floor(Math.random()*16777215).toString(16)}`} 
                                    />
                                );
                            }

                            return null;
                        })}
                    </LineChart>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Select Measurements
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Row>
                                <Col>
                                    <Button onClick={() => setSelectedKeys(keysWithNonZeroValues)}>Select All</Button>
                                </Col>
                                <Col>
                                    <Button onClick={() => setSelectedKeys([])}>Deselect All</Button>
                                </Col>
                            </Row>
                            <Form>
                                <Row>
                                    {keysWithNonZeroValues.map((key, index) => (
                                        <Col key={index} xs={6} sm={4} md={3} lg={2}> {/* Adjust column sizes as needed */}
                                            <Form.Check 
                                                type="checkbox" 
                                                label={key} 
                                                name={key} 
                                                checked={selectedKeys.includes(key)}
                                                onChange={handleCheckboxChange}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Form>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        </Container>
    );
};


MeasurementsChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            userId: PropTypes.string,
            date: PropTypes.string,
            neck: PropTypes.number,
            shoulder: PropTypes.number,
            bust: PropTypes.number,
            underBust: PropTypes.number,
            lowerWaist: PropTypes.number,
            rightWrist: PropTypes.number,
            leftWrist: PropTypes.number,
            rightArm: PropTypes.number,
            leftArm: PropTypes.number,
            rightForearm: PropTypes.number,
            leftForearm: PropTypes.number,
            rightPalm: PropTypes.number,
            leftPalm: PropTypes.number,
            waist: PropTypes.number,
            rightThigh: PropTypes.number,
            leftThigh: PropTypes.number,
            rightCalf: PropTypes.number,
            leftCalf: PropTypes.number,
            rightAnkle: PropTypes.number,
            leftAnkle: PropTypes.number,
            weight: PropTypes.number,
            leftOverKnee: PropTypes.number,
            rightOverKnee: PropTypes.number,
            rightFoot: PropTypes.number,
            leftFoot: PropTypes.number,
            hips: PropTypes.number,
            height: PropTypes.number,
        }),)
};

export default MeasurementsChart;
