import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Container, Row, Table } from "react-bootstrap";
import TeamManagement from "./TeamManagement";
import TeamService from "../../services/team.service";
import { useAuth0 } from "@auth0/auth0-react";
import { datadogLogs } from "@datadog/browser-logs";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";

const TeamList = () => {
    const [teams, setTeams] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [teamToEdit, setTeamToEdit] = useState(null);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);

    const closeModal = () => {
        setShowModal(false);
        // Re-fetch teams to reflect changes
        fetchTeams();
    };

    const fetchTeams = async () => {
        if (isAuthenticated) {
            try {
                const token = await getToken();
                const response = await TeamService.getAllByUser(token, user.sub);
                setTeams(response || []);
            } catch (error) {
                console.error("Error fetching teams:", error);
                datadogLogs.logger.error("Error fetching teams:", { error });
            }
        }
    };

    useEffect(() => {
        const fetchTeams = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getToken();
                    const response = await TeamService.getAllByUser(token, user.sub);
                    setTeams(response || []);
                } catch (error) {
                    console.error("Error fetching teams:", error);
                    datadogLogs.logger.error("Error fetching teams:", { error });
                }
            }
        };
        fetchTeams();
    }, [isAuthenticated, getToken]);

    const handleAddTeam = () => {
        setShowModal(true);
        setTeamToEdit(null);
        datadogLogs.logger.info("Adding a new Team");
    };

    const handleEditTeam = (team) => {
        setShowModal(true);
        setTeamToEdit(team);
        datadogLogs.logger.info("Editing Team", { team });
    };

    const handleDeleteTeam = async (team) => {
        if (isAuthenticated) {
            try {
                const token = await getToken();
                const response = await TeamService.delete(token,team.id);
                setTeams(response || []);
            } catch (error) {
                console.error("Error deleting team:", error);
                datadogLogs.logger.error("Error deleting team:", { error });
            }
        }
        fetchTeams();
    };

    return (
        <div>
            <Container fluid>
                <Row className="mt-4">
                    <h1>My Teams</h1>
                </Row>
                <Row className="justify-content-end w-100">
                    <Button onClick={handleAddTeam}>Add Team</Button>
                </Row>

                <Row className="mt-4">
                    <Table bordered striped>
                        <thead>
                            <tr>
                                <th>Team Name</th>
                                <th colSpan={2}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {teams.map((team) => (
                                <tr key={team.id}>
                                    <td>{team.name}</td>
                                    <td className="text-center">
                                        <Button
                                            variant="danger"
                                            onClick={() => handleDeleteTeam(team)}
                                        >
                                            <BsFillTrash3Fill />
                                        </Button>
                                    </td>
                                    <td className="text-center">
                                        <Button
                                            variant="primary"
                                            onClick={() => handleEditTeam(team)}
                                        >
                                            <BsFillPencilFill />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
                <Modal
                    show={showModal}
                    onHide={closeModal}
                    className="custom-modal"
                    aria-labelledby={teamToEdit ? "Edit Team" : "Add Team"}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {teamToEdit ? "Edit Team" : "Add Team"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TeamManagement
                            initialTeam={teamToEdit}
                            closeModal={closeModal}
                        />
                    </Modal.Body>
                </Modal>
            </Container>
        </div>
    );
};

export default TeamList;
