import { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { AiFillSave, AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";


function ExerciseForm(props) {
    const { exercise, closeModal } = props;
    const { user, isAuthenticated } = useAuth0();
    //console.log("Current User: " + user.sub);

    let initialFormData = {
        "id": "0",
        "userId": "",
        "exerciseName": "",
        "sets": "", 
        "repetitions": "", 
        "repetitionType": "",
        "repetitionMeasure": "", // new field
        "description": "",
        "vidLink": "",
        "exerciseSteps": [] 
    };

    if (exercise) {
        initialFormData = { ...exercise };
    }

    const [formData, setFormData] = useState(initialFormData);


    useEffect(() => {
        if (exercise) {
            setFormData({
                ...exercise,
                sets: exercise.sets ? parseInt(exercise.sets) : "", 
                repetitions: exercise.repetitions ? parseInt(exercise.repetitions) : "", 
                repetitionMeasure: exercise.repetitionMeasure || "", // new field
                // more fields...
            });
        } else {
            setFormData({
                id: 0,
                userId: "",
                exerciseName: "",
                sets: "", 
                repetitions: "", 
                repetitionType: "",
                repetitionMeasure: "", // new field
                description: "",
                vidLink: "",
                exerciseSteps: [],
            });
        }
    }, [exercise]);
      
    




    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const updatedValue = name === "sets" || name === "repetitions" ? parseInt(value) : value;
        setFormData({
            ...formData,
            [name]: updatedValue,
        });
    };

    const handleStepInputChange = (index, name, value) => {
        // const { name, value } = event.target;
        const newSteps = [...formData.exerciseSteps];
        const updatedValue = name === "sets" || name === "repetitions" || name === "position" ? parseInt(value) : value;
        newSteps[index] = {
            ...newSteps[index],
            [name]: updatedValue,
        };
        setFormData({
            ...formData,
            exerciseSteps: newSteps,
        });
    };

    const handleAddStep = () => {
        if (!formData.exerciseSteps || formData.exerciseSteps.length === 0) {
            setFormData({
                ...formData,
                exerciseSteps: [
                    {
                        detail: "",
                        sets: "", // changed here
                        repetitions: "", // changed here
                        repetitionType: "",
                        description: "",
                        position: 1,
                    },
                ],
            });
        } else {
            setFormData({
                ...formData,
                exerciseSteps: [
                    ...formData.exerciseSteps,
                    {
                        detail: "",
                        sets: "", // changed here
                        repetitions: "", // changed here
                        repetitionType: "",
                        description: "",
                        position: formData.exerciseSteps.length + 1,
                    },
                ],
            });
        }
    };

    const handleRemoveStep = (index) => {
        const newSteps = [...formData.exerciseSteps];
        newSteps.splice(index, 1);
        setFormData({
            ...formData,
            exerciseSteps: newSteps.map((step, i) => ({ ...step, position: i + 1 })),
        });
    };

    const handleStepUp = (index) => {
        const newSteps = [...formData.exerciseSteps];
        const currentStep = newSteps[index];
        const prevStep = newSteps[index - 1];
        newSteps[index] = prevStep;
        newSteps[index - 1] = currentStep;
        setFormData({
            ...formData,
            exerciseSteps: newSteps.map((step, i) => ({ ...step, position: i + 1 })),
        });
    };

    const handleStepDown = (index) => {
        const newSteps = [...formData.exerciseSteps];
        const currentStep = newSteps[index];
        const nextStep = newSteps[index + 1];
        newSteps[index] = nextStep;
        newSteps[index + 1] = currentStep;
        setFormData({
            ...formData,
            exerciseSteps: newSteps.map((step, i) => ({ ...step, position: i + 1 })),
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (FormDataEvent.userId === "all") {
            FormDataEvent.id = 0;
        }
        formData.userId = user.sub;
        console.log(JSON.stringify(formData));
        closeModal && closeModal(formData);
    };

    if (!isAuthenticated) {
        return <div>You need to log in to access this form.</div>;
    }

    return (
        <Container fluid>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Form.Group controlId="formExerciseName">
                        <Form.Label>Exercise Name *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter exercise name"
                            name="exerciseName"
                            value={formData.exerciseName}
                            maxLength={64}
                            required
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                    <Col xs={3}>
                        <Form.Group controlId="formSets">
                            <Form.Label>Sets</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter sets"
                                name="sets"
                                value={formData.sets || ""}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={4}>
                        <Form.Group controlId="formRepetitions">
                            <Form.Label>Repetitions</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter repetitions"
                                name="repetitions"
                                value={formData.repetitions || ""}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                    <Col xs={6}>
                        <Form.Group controlId="formRepetitionType">
                            <Form.Label>Repetition Type</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter repetition type"
                                name="repetitionType"
                                value={formData.repetitionType}
                                maxLength={12}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={4}>
                        <Form.Group controlId="formRepetitionMeasure">
                            <Form.Label>Measure</Form.Label>
                            <Form.Control
                                as="select"
                                name="repetitionMeasure"
                                value={formData.repetitionMeasure}
                                onChange={handleInputChange}
                            >
                                <option value="">Select...</option>
                                <option value="weight">Weight</option>
                                <option value="time">Time</option>
                                <option value="distance">Distance</option>
                                <option value="none">None</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                    <Form.Group controlId="formDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter description"
                            name="description"
                            value={formData.description}
                            maxLength={256}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Row>
                <Form.Group>
                    <hr style={{ borderTop: "1px solid black" }}/>
                    <Row><Form.Label>Exercise Steps</Form.Label></Row>
                    <Row><Button variant="secondary" size="sm" onClick={handleAddStep}>
                        Add Step
                    </Button></Row>
                    {(formData.exerciseSteps && formData.exerciseSteps.length > 0) &&
                        formData.exerciseSteps.map((exercise, index) => (
                            <div key={index}>
                                <hr />
                                <Form.Group>
                                    <Row className="align-items-center">
                                        <Col xs={3} className="d-flex justify-content-start">
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() => handleStepUp(index)}
                                                disabled={index === 0}
                                            ><AiFillCaretUp /></Button>
                                        </Col>
                                        <Col xs={6} className="d-flex justify-content-start ml-auto">
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() => handleStepDown(index)}
                                                disabled={index === formData.exerciseSteps.length - 1}
                                            ><AiFillCaretDown /></Button>
                                        </Col>
                                        <Col xs={3} className="d-flex justify-content-end">
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleRemoveStep(index)}
                                            ><BsFillTrash3Fill /></Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Form.Group controlId={`exerciseSteps[${index}].description`}>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter description"
                                                value={exercise.description || ""}
                                                onChange={(e) =>
                                                    handleStepInputChange(index, "description", e.target.value)
                                                }
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Col xs={3}>
                                            <Form.Group controlId={`exerciseSteps[${index}].sets`}>
                                                <Form.Label>Sets</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter sets"
                                                    value={exercise.sets || ""}
                                                    onChange={(e) =>
                                                        handleStepInputChange(index, "sets", e.target.value)
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={3}>
                                            <Form.Group controlId={`exerciseSteps[${index}].repetitions`}>
                                                <Form.Label>Repetitions</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter repetitions"
                                                    value={exercise.repetitions || ""}
                                                    onChange={(e) =>
                                                        handleStepInputChange(
                                                            index,
                                                            "repetitions",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Group controlId={`exerciseSteps[${index}].repetitionType`}>
                                                <Form.Label>Repetition Type</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter repetition type"
                                                    value={exercise.repetitionType || ""}
                                                    onChange={(e) =>
                                                        handleStepInputChange(
                                                            index,
                                                            "repetitionType",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </div>
                        ))}
                    <hr />
                </Form.Group>
                <Form.Group>
                    <div>
                        <p>
                            <Button
                                variant="primary"
                                size="sm"
                                type="submit"
                            ><AiFillSave />
                            </Button>
                        </p>
                    </div>
                </Form.Group>
            </Form>
        </Container>
    );
}

export default ExerciseForm;

ExerciseForm.propTypes = {
    closeModal: PropTypes.func,
    exercise: PropTypes.shape({
        sets: PropTypes.number,
        repetitions: PropTypes.number,
        description: PropTypes.string,
        repetitionType: PropTypes.string,
        repetitionMeasure: PropTypes.string
    }),
};
