import createApiInstance from "./api";

const API_URL = "/programAssignments";

const ProgramAssignmentService = {
    getAll: async (token) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(API_URL);
            return response.data;
        } catch (error) {
            console.error("Error fetching program assignments:", error);
            throw error;
        }
    },

    get: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.get(`${API_URL}/${encodedId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching program assignment with id ${id}:`, error);
            throw error;
        }
    },

    create: async (token, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post(API_URL, data);
            return response.data;
        } catch (error) {
            console.error("Error creating program assignment:", error);
            throw error;
        }
    },

    disable: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.put(`${API_URL}/${encodedId}/disable`);
            return response.data;
        } catch (error) {
            console.error(`Error disabling program assignment with id ${id}:`, error);
            throw error;
        }
    },

    enable: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.put(`${API_URL}/${encodedId}/enable`);
            return response.data;
        } catch (error) {
            console.error(`Error enabling program assignment with id ${id}:`, error);
            throw error;
        }
    },
    

    update: async (token, id, data) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.put(`${API_URL}/${encodedId}`, data);
            return response.data;
        } catch (error) {
            console.error(`Error updating program assignment with id ${id}:`, error);
            throw error;
        }
    },

    delete: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            await api.delete(`${API_URL}/${encodedId}`);
        } catch (error) {
            console.error(`Error deleting program assignment with id ${id}:`, error);
            throw error;
        }
    },

    findByTrainerId: async (token, trainerId) => {
        try {
            const api = createApiInstance(token);
            const encodedTrainerId = encodeURIComponent(trainerId);
            const response = await api.get(`${API_URL}/trainer/${encodedTrainerId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching program assignments for trainer id ${trainerId}:`, error);
            throw error;
        }
    },

    findByAthleteId: async (token, athleteId) => {
        try {
            const api = createApiInstance(token);
            const encodedAthleteId = encodeURIComponent(athleteId);
            const response = await api.get(`${API_URL}/athleteProgram/${encodedAthleteId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching program assignments for athlete id ${athleteId}:`, error);
            throw error;
        }
    },

    updateAthleteProgram: async (token, id, data) => {
        try {
            const api = createApiInstance(token);
            const encodedId = encodeURIComponent(id);
            const response = await api.put(`${API_URL}/athleteProgram/${encodedId}`, data);
            return response.data;
        } catch (error) {
            console.error(`Error updating athlete program with id ${id}:`, error);
            throw error;
        }
    },

    getAthleteProgram: async (token, athleteId) => {
        try {
            const api = createApiInstance(token);
            const encodedAthleteId = encodeURIComponent(athleteId);
            const response = await api.get(`${API_URL}/athleteProgram/${encodedAthleteId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching athlete program for athlete id ${athleteId}:`, error);
            throw error;
        }
    },
};

export default ProgramAssignmentService;
