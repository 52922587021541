import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import UserPrograms from "../athlete/UserPrograms";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useGetToken } from "../../services/authUtils";
import { datadogLogs } from "@datadog/browser-logs";
import TeamService from "../../services/team.service";


import MyTeams from "../athlete/MyTeams";
import ScheduleController from "../schedule/ScheduleController";

import EventService from "../../services/event.service";
import EventModal from "../schedule/EventModal";
import useFetchEvents from "./coach/customHooks/useFetchEvents";


const AthleteLandingPage = () => {
    const [teams, setTeams] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const currentDate = new Date();
    // getToken
    const { user, isAuthenticated, getToken } = useGetToken();
    const [token, setToken] = useState(null);

    const start = new Date(currentDate);
    start.setHours(12, 0, 0);
    const end = new Date(currentDate);
    end.setHours(13, 0, 0);
    const initialEvent = { start, end };
    const [selectedEvent] = useState(initialEvent);
    // custom hooks
    

    

    useEffect(() => {
        (async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        })();
    }, [getToken]);

    const [localEvents, fetchEventsError, fetchEvents] = useFetchEvents(user.sub, getToken);

    const NavigationButtons = () => {
        return (
            <Row>
                <Col className="mt-4">
                    <Link to="/mymeasurements">
                        <Button variant="primary">My Measurements</Button>
                    </Link>
                    <Button variant="primary" onClick={() => { setModalShow(true); }}>
                    Create New Event
                    </Button>
                </Col>
            </Row>
        );
    };

    const fetchTeams = async () => {
        if (isAuthenticated) {
            try {
                const token = await getToken();
                if (token) {
                    const response = await TeamService.getAllByUser(token, user.sub);
                    setTeams(response || []);
                }
            } catch (error) {
                console.error("Error fetching teams:", error);
                datadogLogs.logger.error("Error fetching teams:", { error });
            }
        }
    };

    const handleEventChange = async (event) => {
        console.log(event);
        const token = await getToken();
        if (token) {
            try {
                // Checking if the event has an id or not
                if (event.id) {
                    // If it has an id, update it
                    const updatedEvent = await EventService.update(token, event.id, event);
                    console.log("Event updated:", updatedEvent);
                } else {
                    // If it doesn't have an id, create a new event
                    const newEvent = await EventService.create(token, event);
                    console.log("Event created:", newEvent);
                }
            } catch (error) {
                console.error("Error handling event:", error);
            }
        }
    };

    const handleDeleteEvent = async (eventId) => {
        if (token) {
            try {
                await EventService.delete(token, eventId);
            
                // Re-fetch events from the server
                fetchEvents();
            } catch (error) {
                console.error(`Error deleting event with id ${eventId}:`, error);
            }
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            fetchEvents();
    
            // Assuming fetchTeams is async
            await fetchTeams(); 
        };
    
        if (isAuthenticated && user) {
            fetchData();
        }
    }, [fetchEvents, isAuthenticated, user]);


    // useEffect(() => {
    //     console.log("Events have changed:", events);
    // }, [events]);

    return (
        <Container fluid className="justify-content-md-center">
            {fetchEventsError && <p>Error fetching events: {fetchEventsError}</p>}
            <Helmet>
                <link rel="canonical" href="https://www.enlistedfitness.com/home" />
            </Helmet>
            <Row className="mt-4">
                <Col md={12} className="custom-cell-border" style={{ paddingBottom: "8px" }}>
                    <h2>Quick Links</h2>
                    <NavigationButtons />
                </Col>
            </Row>
            <Row>
                <Col className="custom-cell-border" md={6} style={{ paddingBottom: "16px" }}>
                    <UserPrograms />
                </Col>
                <Col className="custom-cell-border" md={6} style={{ paddingBottom: "16px" }}>
                    <h2>My Schedule</h2>
                    <ScheduleController
                        initialEvents = {localEvents}
                        onEventChange = {handleEventChange}
                        handleDeleteEvent = {handleDeleteEvent}
                        teams = {teams}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="custom-cell-border" md={6} style={{ paddingBottom: "16px" }}>
                    <MyTeams />
                </Col>
            </Row>
            {/*<Row><MeasurementsForm /></Row>
             <Row className="mt-4">
                <Col>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                Details Section
                            </Accordion.Header>
                            <Accordion.Body>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row> */}
            <EventModal 
                show={modalShow} 
                handleClose={() => setModalShow(false)} 
                handleSave={handleEventChange} 
                event={selectedEvent}
                handleDeleteEvent={handleDeleteEvent}
            />
        </Container>
    );
};

export default AthleteLandingPage;
