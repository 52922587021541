import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Container, Row, Col, Table, FormControl } from "react-bootstrap";
import WorkoutForm from "./WorkoutForm";
import WorkoutService from "../../../services/workout.service";
import { useAuth0 } from "@auth0/auth0-react";
import { datadogLogs } from "@datadog/browser-logs";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";
import { AiOutlineSortDescending, AiOutlineSortAscending, AiFillCopy } from "react-icons/ai";

const WorkoutList = () => {
    const [workouts, setWorkouts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [workoutToEdit, setWorkoutToEdit] = useState(null);
    const [searchQuery, setSearchQuery] = useState(""); // State for the search query
    const [sortField, setSortField] = useState("name"); // Field to sort by
    const [sortOrder, setSortOrder] = useState("asc");  // Sort order: 'asc' or 'desc'
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();
                return token;
            } catch (error) {
                console.error("Error retrieving access token:", error);
            }
        }
        return null;
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        const fetchWorkouts = async () => {
            datadogLogs.logger.info("Fetching workouts");
            if (isAuthenticated) {
                try {
                    const token = await getToken();
                    if (token) {
                        const response = await WorkoutService.getAll(token);
                        if (!response || Object.keys(response).length === 0) {
                            setWorkouts([]);
                        } else {
                            // Filter workouts where workout.userId is "template"
                            const filteredWorkouts = response.filter(workout => workout.userId.toLowerCase() !== "template");
                            setWorkouts(filteredWorkouts);
                            datadogLogs.logger.info("Workouts fetched successfully", { workouts: filteredWorkouts });
                        }
                    }
                } catch (error) {
                    datadogLogs.logger.error("Error fetching workouts", { error: error.message });
                    console.error("Error fetching workouts:", error);
                }
            }
        };
        fetchWorkouts();
    }, [isAuthenticated, getToken]);
    

    const handleAddWorkout = () => {
        setShowModal(true);
        setWorkoutToEdit(null);
    };

    const handleEditWorkout = (workout) => {
        setShowModal(true);
        setWorkoutToEdit(workout);
    };

    const handleCopyWorkout = (workout) => {
        const copiedWorkout = { ...workout, id: 0 };
        setShowModal(true);
        setWorkoutToEdit(copiedWorkout);
    };

    const handleDeleteWorkout = async (workout) => {
        datadogLogs.logger.info("Deleting workout", { workoutId: workout.id });
        try {
            const token = await getToken();
            if (token) {
                await WorkoutService.delete(token, workout.id);
                setWorkouts((prevWorkouts) =>
                    prevWorkouts.filter((w) => w.id !== workout.id)
                );
                datadogLogs.logger.info("Workout deleted successfully", { workoutId: workout.id });
            }
        } catch (error) {
            datadogLogs.logger.error("Error deleting workout", { error: error.message, workoutId: workout.id });
            console.error(`Error deleting workout with id ${workout.id}:`, error);
        }
    };

    const handleSaveWorkout = async (workout) => {
        datadogLogs.logger.info(workout.id && workout.id !== 0 ? "Updating workout" : "Creating new workout", { workout });
        try {
            const token = await getToken();
            if (token) {
                if (workout.id && workout.id !== 0) {
                    await WorkoutService.update(token, workout.id, workout);
                    setWorkouts((prevWorkouts) =>
                        prevWorkouts.map((w) => (w.id === workout.id ? workout : w))
                    );
                    datadogLogs.logger.info("Workout updated successfully", { workout });
                } else {
                    const response = await WorkoutService.create(token, workout);
                    setWorkouts((prevWorkouts) =>
                        prevWorkouts.concat(response)
                    );
                    datadogLogs.logger.info("Workout created successfully", { workout: response });
                }
                setShowModal(false);
            }
        } catch (error) {
            datadogLogs.logger.error("Error saving workout", { error: error.message, workout });
            console.error("Error saving workout:", error);
        }
    };  

    const filteredWorkouts = workouts.filter(workout =>
        workout.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };

    const sortedWorkouts = [...filteredWorkouts].sort((a, b) => {
        if (sortOrder === "asc") {
            return a[sortField].localeCompare(b[sortField]);
        }
        return b[sortField].localeCompare(a[sortField]);
    });

    return (
        <div>
            <Container fluid>
                <Row className="mt-4">
                    <h1>My Workout Templates</h1>
                </Row>
                <Row className="justify-content-start mb-4">
                    <Col>
                        <FormControl
                            type="text"
                            placeholder="Search by workout name"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-end w-100">
                    <Button onClick={handleAddWorkout}>Add Workout</Button>
                </Row>
                <hr style={{ borderTop: "1px solid black" }}/>
                <Row className="mt-4">
                    {sortedWorkouts.length ? (
                        <Table bordered striped className="table-striped">
                            <thead>
                                <tr>
                                    <th className="col-12 col-md-6 text-md-left align-middle" style={{ fontSize: "1.25rem" }} onClick={() => handleSort("name")}>
                              Sort By Workout Name
                                        {sortField === "name" && sortOrder === "asc" && <AiOutlineSortAscending />}
                                        {sortField === "name" && sortOrder === "desc" && <AiOutlineSortDescending />}
                                    </th>
                                    <th colSpan={3} className="col-12 col-md-2 text-center align-middle"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedWorkouts && sortedWorkouts.map((workout) => workout && (
                                    <tr key={workout.id}>
                                        <td className="col-12 col-md-12 text-md-left align-middle" style={{ fontSize: "1.25rem" }}>
                                            <div style={{ textAlign: "center" }}>{workout.name}</div>
                                            <div className="d-flex align-items-center mt-2 mt-md-0 justify-content-between">
                                                <Button
                                                    variant="info"
                                                    onClick={() => handleCopyWorkout(workout)}
                                                >
                                                    <AiFillCopy />
                                                </Button>
                                                <Button
                                                    variant="danger"
                                                    onClick={() => handleDeleteWorkout(workout)}
                                                >
                                                    <BsFillTrash3Fill />
                                                </Button>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => handleEditWorkout(workout)}
                                                >
                                                    <BsFillPencilFill />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                      
                    ) : (
                        <div>No results found</div>
                    )}
                </Row>
                <Modal show={showModal} onHide={() => setShowModal(false)}  className="custom-modal"
                    aria-labelledby= {workoutToEdit ? "Edit Workout" : "Add Workout"}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {workoutToEdit ? "Edit Workout" : "Add Workout"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <WorkoutForm
                            initialWorkout={workoutToEdit}
                            closeModal={handleSaveWorkout}
                        />
                    </Modal.Body>
                </Modal>
            </Container>
        </div>
    );
};

export default WorkoutList;
