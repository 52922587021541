import { useState, useEffect } from "react";
import ProgramAssignmentService from "../../../../services/program.assignment.service";

const useFetchProgramAssignments = (token, athleteId) => {
    const [programAssignments, setProgramAssignments] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProgramAssignments = async () => {
            if (token && athleteId) {
                try {
                    const fetchedProgramAssignments = await ProgramAssignmentService.findByAthleteId(token, athleteId);
                    setProgramAssignments(Array.isArray(fetchedProgramAssignments) ? fetchedProgramAssignments : []);
                } catch (error) {
                    setError(error.message || "An error occurred while fetching program assignments.");
                }
            }
        };
        fetchProgramAssignments();
    }, [token, athleteId]);

    const refreshProgramAssignments = async () => {
        try {
            const fetchedProgramAssignments = await ProgramAssignmentService.findByAthleteId(token, athleteId);
            setProgramAssignments(Array.isArray(fetchedProgramAssignments) ? fetchedProgramAssignments : []);
        } catch (error) {
            setError(error.message || "An error occurred while refreshing program assignments.");
        }
    };

    return [programAssignments, error, refreshProgramAssignments];
};

export default useFetchProgramAssignments;
