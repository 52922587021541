import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const AthleteCard = ({ athlete, onView, onCreateProgram, onSendMessage }) => {
    return (
        <Container>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col className="text-center">
                                    <Card.Title>{athlete.name}</Card.Title>
                                </Col>
                            </Row>
                            <Card.Text>
                                {/* Some quick summary */}
                            </Card.Text>
                            <Row>
                                <Col className="d-flex justify-content-center" xs={12} sm={6} md={4}> 
                                    <Button variant="primary" onClick={() => onView(athlete.id)}>View Details</Button>
                                </Col>
                                <Col className="d-flex justify-content-center mt-4" xs={12} sm={6} md={4}> 
                                    <Button variant="secondary" onClick={() => onCreateProgram(athlete.id)}>Create Program</Button>
                                </Col>
                                <Col className="d-flex justify-content-center mt-4" xs={12} sm={6} md={4}> 
                                    <Button variant="success" onClick={() => onSendMessage(athlete.id)}>Send Message</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

AthleteCard.propTypes = {
    athlete: PropTypes.object.isRequired,
    onView: PropTypes.func.isRequired,
    onCreateProgram: PropTypes.func.isRequired,
    onSendMessage: PropTypes.func.isRequired,
};

export default AthleteCard;
