import React, { useState, useEffect, useCallback, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import "./bootstrap.min.morph.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardAdmin from "./components/BoardAdmin";
import MyExercises from "./components/trainer/exercise/MyExercises";
import MyWorkouts from "./components/trainer/workout/MyWorkouts";
import NavBar from "./components/NavBar";
import MyPrograms from "./components/trainer/program/MyPrograms";
import UserProfileService from "./services/user.profile.service";
import CreateUserProfile from "./components/util/CreateUserProfile";
import TrainerProfileService from "./services/trainer.profile.service";
import CreateTrainerProfile from "./components/util/CreateTrainerProfile";
import UserPrograms from "./components/athlete/UserPrograms";
import EventBus from "./common/EventBus";
import { datadogLogs } from "@datadog/browser-logs";
import LandingPage from "./components/landing/LandingPage";
import HowToInstallPWA from "./components/HowToInstallPWA";
import UserContext from "./context/UserContext";
import TeamList from "./components/trainer/TeamList";
import MyMeasurements from "./components/athlete/MyMeasurements";
import My404Page from "./components/error/my404page";
import { Helmet } from "react-helmet";
import MyAthletes from "./components/trainer/MyAthletes";


const App = () => {
    const [showTrainerBoard, setShowTrainerBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const navigate = useNavigate();
    const redirect = useCallback(() => navigate("/"), [navigate]);
    const divRef = useRef(null);
    //used in render
    // eslint-disable-next-line no-unused-vars
    const [paddingTop, setPaddingTop] = useState("calc(env(safe-area-inset-top))");



    const { user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

    datadogLogs.logger.debug("Auth0 User:", { user });

    const getToken = useCallback(async () => {
        if (user) {
            const token = await getAccessTokenSilently({});
            datadogLogs.logger.debug("Token:", { token });
            return token;
        }
        return null;
    }, [getAccessTokenSilently, user]);

    const logOut = useCallback(() => {
        datadogLogs.logger.info("User logging out");
        setShowTrainerBoard(false);
        setShowAdminBoard(false);
        redirect();
    }, [redirect]);

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (user && isAuthenticated) {
                const token = await getToken();
                if (token) {
                    UserProfileService.hasUserProfile(token, user.sub)
                        .then(hasProfile => {
                            if (!hasProfile) {
                                datadogLogs.logger.info("User profile not found, navigating to create-profile");
                                navigate("/create-profile");
                            } else {
                                const userRoles = user["https://enlistedfitness.com/roles"] || [];
                                const isTrainer = userRoles.includes("ROLE_TRAINER");
                                setShowTrainerBoard(isTrainer);
                                setShowAdminBoard(userRoles.includes("ROLE_ADMIN"));

                                if (isTrainer) {
                                    TrainerProfileService.hasTrainerProfile(token, user.sub)
                                        .then(hasTrainerProfile => {
                                            if (!hasTrainerProfile) {
                                                datadogLogs.logger.info("Trainer profile not found, navigating to create-trainer-profile");
                                                navigate("/create-trainer-profile");
                                            }
                                        })
                                        .catch(err => {
                                            datadogLogs.logger.error("Error checking trainer profile:", { error: err.message });
                                        });
                                }
                            }
                        })
                        .catch(err => {
                            datadogLogs.logger.error("Error checking user profile:", { error: err.message });
                        });
                }
            } else {
                datadogLogs.logger.warn("User not authenticated");
            }
        };

        if (user) {
            datadogLogs.setUser({ id: user.sub, name: user.name, email: user.email });
            fetchUserProfile();
        }

        EventBus.on("logout", () => {
            datadogLogs.clearUser();
            logOut();
        });

        return () => {
            EventBus.remove("logout");
            datadogLogs.logger.info("EventBus listener for 'logout' removed");
        };
    }, [user, isAuthenticated, logOut, navigate, getToken]);

    const location = useLocation();

    useEffect(() => {
        datadogLogs.logger.info("Current Location:", { location: location.pathname });
    }, [location]);

    useEffect(() => {
        const updatePadding = () => {
            if (divRef.current) {
                const computedPadding = getComputedStyle(divRef.current).paddingTop;
                if (computedPadding === "0px") {
                    setPaddingTop("85px");
                }
            }
        };
    
        // Call the updatePadding function on initial render
        updatePadding();
    
        // Add an event listener to call updatePadding on page refresh or resize
        window.addEventListener("load", updatePadding);
        window.addEventListener("resize", updatePadding);
    
        // Return a cleanup function to remove the event listeners
        return () => {
            window.removeEventListener("load", updatePadding);
            window.removeEventListener("resize", updatePadding);
        };
    }, []);

    if (isLoading) {
        datadogLogs.logger.info("App is loading");
        return <div>Loading...</div>;
    }

    datadogLogs.logger.debug("App Current User:", { user });

    return (
        <UserContext.Provider value={user}>
            <Helmet>
                <link rel="canonical" href="https://www.enlistedfitness.com/" />
            </Helmet>
            <NavBar
                showTrainerBoard={showTrainerBoard}
                showAdminBoard={showAdminBoard}
                currentUser={user}
            />
            <div ref={divRef} className="main-container" style={{ paddingTop: 70 }}>
                <Routes>
                    <Route
                        path="/"
                        element={
                            isAuthenticated ? (
                                <LandingPage userType={showTrainerBoard ? "coach" : "athlete"} />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route
                        path="/home"
                        element={
                            isAuthenticated ? (
                                <LandingPage userType={showTrainerBoard ? "coach" : "athlete"} />
                            ) : (
                                <Home />
                            )
                        }
                    />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/create-profile" element={<CreateUserProfile />} />
                    <Route path="/create-trainer-profile" element={<CreateTrainerProfile />} />
                    <Route path="/install" element={<HowToInstallPWA />} />

                    {/* athlete */}
                    <Route path="/user" element={<UserPrograms />} />
                    <Route path="/mymeasurements" element={<MyMeasurements />} />

                    {/* trainer */}
                    <Route path="/myexercises" element={<MyExercises />} />
                    <Route path="/myworkouts" element={<MyWorkouts />} />
                    <Route path="/myprograms" element={<MyPrograms />} />
                    <Route path="/myteams" element={<TeamList />} />
                    <Route path="/myathletes" element={<MyAthletes />} />

                    {/* admin */}
                    <Route path="/admin" element={<BoardAdmin />} />

                    {/* defauly */}
                    <Route path="*" element={<My404Page />} />
                </Routes>
            </div>
        </UserContext.Provider>
    );
};

export default App;
