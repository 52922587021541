import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, ListGroup, Container, Row, Col, Form } from "react-bootstrap";
import ProgramForm from "../trainer/program/ProgramForm";
import { useGetToken } from "../../services/authUtils";
import useFetchProgramAssignments from "../landing/coach/customHooks/useFetchProgramAssignments";

const CreateAthleteProgram = ({ show,
    selectedAthlete,
    athletePrograms,
    handleProgramAssignmentChange,
    handleAssignProgram,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    enabled,
    setEnabled,
    programs,
    onClose,
    athletes }) => {
    const [showProgramList, setShowProgramList] = useState(false);
    const [showProgramTemplateList, setShowProgramTemplateList] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [showOtherAthletePrograms, setShowOtherAthletePrograms] = useState(false);
    const [selectedOtherAthlete, setSelectedOtherAthlete] = useState(null);
    // getToken
    const { getToken } = useGetToken();
    const [token, setToken] = useState(null);

    useEffect(() => {
        (async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        })();
    }, [getToken]);


    const [programAssignments, fetchProgramAssignmentsError] = useFetchProgramAssignments(
        token,
        selectedOtherAthlete?.id || null // Check if selectedOtherAthlete.id is null or undefined
    );
    

    const createNewProgram = () => {
        setSelectedOtherAthlete(null);
        setShowOtherAthletePrograms(false);
        setShowProgramList(false);
        setShowProgramTemplateList(false);
        // Find the program with userId "template"
        const programTemplate = programs.find(program => program.userId.toLowerCase() === "template");
    
        if (programTemplate) {
            // Copy the program and reset its id
            const copiedProgram = { ...programTemplate, id: 0 };
    
            // Set the copied program as the selected program
            setSelectedProgram(copiedProgram);
        } else {
            console.log("No program template found with userId 'template'");
        }
    };    

    const copyAndEditPriorProgram = () => {
        setSelectedProgram(null);
        setSelectedOtherAthlete(null);
        setShowOtherAthletePrograms(false);
        setShowProgramTemplateList(false);
        setShowProgramList(true);
    };

    const handleProgramSelect = (program) => {
        setShowOtherAthletePrograms(false);
        setShowProgramTemplateList(false);
        setShowProgramList(false);
        const copiedProgram = { ...program, id: 0, athleteId: selectedAthlete.id };
        setSelectedProgram(copiedProgram.program);
        
    };

    const createProgramFromTemplate = () => {
        setSelectedOtherAthlete(null);
        setSelectedProgram(null);
        setShowOtherAthletePrograms(false);
        setShowProgramList(false);
        setShowProgramTemplateList(true);
    };

    const handleProgramTemplateSelect = (program) => {
        console.log(program);
        setSelectedProgram(program);
        setShowProgramTemplateList(false);
    };


    const handleProgramAssignment = (program) => {
        handleAssignProgram(program);
        handleProgramAssignmentChange();
        setSelectedProgram(null);
        onClose();
    };

    const copyAndEditPriorProgramOtherAthlete = () => {
        setSelectedProgram(null);
        setShowProgramList(false);
        setShowProgramTemplateList(false);
        if (athletes && athletes.length > 0) {
            setShowOtherAthletePrograms(true);
        }
    };

    const handleOtherAthleteSelect = (e) => {
        const selectedAthleteId = e.target.value;
        const selectedAthlete = athletes.find((athlete) => athlete.id === selectedAthleteId);
        setSelectedOtherAthlete(selectedAthlete);
    };

    return (
        <Container>
            {fetchProgramAssignmentsError && <p>Error fetching athletes: {fetchProgramAssignmentsError}</p>}
            <Row>
                <Col>
                    <div>
                        <h2>Create a Program for {selectedAthlete.firstName}</h2>
                        <ButtonGroup vertical>
                            <Button variant="primary" onClick={createNewProgram}>
                                Create New Program
                            </Button>
                            <Button variant="secondary" onClick={copyAndEditPriorProgram}>
                                Copy and Edit {selectedAthlete.firstName}&apos;s Prior Program
                            </Button>
                            <Button variant="secondary" onClick={copyAndEditPriorProgramOtherAthlete}>
                                Copy and Edit another Athlete&apos;s Prior Program
                            </Button>
                            <Button variant="success" onClick={createProgramFromTemplate}>
                                Create Program from Template
                            </Button>
                        </ButtonGroup>
                        {showProgramTemplateList && (
                            <ListGroup>
                                {programs
                                    .filter((program) => {
                                        return program.userId.toLowerCase() !== "template";
                                    })
                                    .map((program) => (
                                        <ListGroup.Item
                                            key={program.id}
                                            action
                                            onClick={() => handleProgramTemplateSelect(program)}
                                            active={selectedProgram?.id === program.id}
                                        >
                                            {program.programName}
                                        </ListGroup.Item>
                                    ))}
                            </ListGroup>
                        )}
                        {showProgramList && (
                            <ListGroup>
                                {athletePrograms
                                    .sort((a, b) => new Date(b.endDate) - new Date(a.endDate)) // Sort by end date descending
                                    .map((program) => (
                                        <ListGroup.Item key={program.id} action onClick={() => handleProgramSelect(program)}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ flex: "1" }}>
                                                    <strong>{program.program.programName}</strong>
                                                </div>
                                                <div>
                                                    End Date: {new Date(program.endDate).toLocaleDateString()} {/* Format end date */}
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                            </ListGroup>
                        )}
                        {showOtherAthletePrograms && (
                            <div>
                                <Form.Group>
                                    <Form.Label>Select Athlete</Form.Label>
                                    <Form.Select onChange={handleOtherAthleteSelect}>
                                        <option value="">Select an Athlete</option>
                                        {athletes
                                            .filter((athlete) => athlete.id !== selectedAthlete.id) // Exclude the selected athlete
                                            .map((athlete) => (
                                                <option key={athlete.id} value={athlete.id}>
                                                    {athlete.name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    {selectedOtherAthlete && (
                                        <ListGroup>
                                            {programAssignments
                                                .sort((a, b) => new Date(b.endDate) - new Date(a.endDate)) // Sort by end date descending
                                                .map((program) => (
                                                    <ListGroup.Item key={program.id} action onClick={() => handleProgramSelect(program)}>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div style={{ flex: "1" }}>
                                                                <strong>{program.program.programName}</strong>
                                                            </div>
                                                            <div>
                                                            End Date: {new Date(program.endDate).toLocaleDateString()} {/* Format end date */}
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                ))}
                                        </ListGroup>
                                    )}
                                </Form.Group>
                                {/* Other content related to the selected athlete */}
                            </div>
                        )}
                        {selectedProgram && (
                            <div style={{ marginTop: "10px" }}><Form.Group>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startDate.toISOString().substr(0, 10)}
                                    onChange={e => setStartDate(new Date(e.target.value))} />
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={endDate.toISOString().substr(0, 10)}
                                    onChange={e => setEndDate(new Date(e.target.value))} />
                                <Form.Check
                                    type="checkbox"
                                    label="Enabled"
                                    checked={enabled}
                                    onChange={e => setEnabled(e.target.checked)} style={{ marginTop: "10px" }}/>
                            </Form.Group><ProgramForm initialProgram={selectedProgram} closeModal={handleProgramAssignment} /></div>
                        )}
                    </div>

                </Col>
            </Row>
        </Container>
    );
};

CreateAthleteProgram.propTypes = {
    show: PropTypes.bool.isRequired,
    selectedAthlete: PropTypes.object.isRequired,
    athletePrograms: PropTypes.array.isRequired,
    handleProgramAssignmentChange: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    setStartDate: PropTypes.func.isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    setEndDate: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired,
    setEnabled: PropTypes.func.isRequired,
    handleAssignProgram: PropTypes.func.isRequired,
    programs: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    athletes: PropTypes.array.isRequired
};

export default CreateAthleteProgram;