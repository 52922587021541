import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AthleteListView from "../athletemanager/AthleteListView";

const MyAthletes = () => {

    return (
        <Container fluid>
            <Row className="mt-4">
                <Col>
                    <h1>My Athletes</h1>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <AthleteListView />
                </Col>
            </Row>
        </Container>
    );
};

export default MyAthletes;
