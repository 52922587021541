import React, { useState } from "react";
import { Table, Button, Container, Row, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import MeasurementsForm from "./MeasurementsForm";

const MeasurementList = ({ measurements, onMeasurementUpdated }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedMeasurement, setSelectedMeasurement] = useState(null); // New state variable

    const handleClose = () => {
        setShowModal(false);
        setSelectedMeasurement(null); // Clear the selected measurement when the modal is closed
    };

    const handleEdit = (measurement) => { // New handler function
        setSelectedMeasurement(measurement);
        setShowModal(true);
    };

    return (
        <Container>
            <Row>
                <h3>Measurement History</h3>
            </Row>
            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {measurements.map((measurement, index) => (
                            <tr key={index}>
                                <td>{measurement.date}</td>
                                <td>
                                    <Button variant="secondary" onClick={() => handleEdit(measurement)}>Edit</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Measurement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MeasurementsForm handleClose={handleClose} existingMeasurements={selectedMeasurement} onMeasurementUpdated={onMeasurementUpdated} />
                </Modal.Body>
            </Modal>
        </Container>
    );
};

MeasurementList.propTypes = {
    measurements: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.string.isRequired
        })
    ).isRequired,
    onMeasurementUpdated: PropTypes.func
};

export default MeasurementList;
