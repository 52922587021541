import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import AthleteCard from "./AthleteCard";
import { useGetToken } from "../../services/authUtils";
import useAthletes from "../landing/coach/customHooks/useAthletes";
import useFetchMeasurements from "../landing/coach/customHooks/useFetchMeasurements";
import useFetchProgramAssignments from "../landing/coach/customHooks/useFetchProgramAssignments";
import usePrograms from "../landing/coach/customHooks/usePrograms";
import ProgramAssignmentService from "../../services/program.assignment.service";
import AthleteModal from "../landing/coach/AthleteModal";
import { datadogLogs } from "@datadog/browser-logs";
import { v4 as uuidv4 } from "uuid";

const AthleteListView = () => {
    // getToken
    const { getToken, user, isAuthenticated } = useGetToken();
    const [token, setToken] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedAthlete, setSelectedAthlete] = useState(null);
    const [modalMode, setModalMode] = useState("none");
    const athleteId = selectedAthlete ? selectedAthlete.id : null;
    const [programAssignments, fetchProgramAssignmentsError, refreshProgramAssignments] = useFetchProgramAssignments(token, athleteId);
    const [localMeasurements, fetchMeasurementsError] = useFetchMeasurements(getToken, athleteId);
    const [activeAccordionKey, setActiveAccordionKey] = useState(null);
    const [startDate, setStartDate] = useState(new Date()); // Initialize to today
    const [endDate, setEndDate] = useState(new Date()); // Initialize to today
    const [enabled, setEnabled] = useState(true); // Initialize to true
    const [searchInput, setSearchInput] = useState("");



    useEffect(() => {
        (async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        })();
    }, [getToken]);

    const [athletes, fetchAthletesError] = useAthletes(token, user.sub, isAuthenticated);
    const [programs, fetchProgramsError] = usePrograms(token, isAuthenticated);

    const getWorkoutAssignments = (program) => {
        if (!program.workouts) {
            console.log("No existing workouts");
            return [];
        } 
    
        console.log("Existing workouts");
        return program.workouts.map(workout => {
            const exerciseAssignments = workout.exercises.map(exercise => {
                return {
                    id: uuidv4(),
                    exercise: exercise,
                    completedSets: null,
                    totalWeight: null,
                    videoLink: null,
                    status: "ready",
                    extraData: {}
                };
            });
            return {
                id: uuidv4(),
                workout: workout,
                date: null,
                startTime: null,
                endTime: null,
                exerciseAssignments,
                extraData: {}
            };
        });
    };

    const handleProgramAssignmentChange = async (programId, action, updatedAthleteProgram) => {
        try {
            switch (action) {
            case "disable":
                await ProgramAssignmentService.disable(token, programId);
                break;
            case "enable":
                await ProgramAssignmentService.enable(token, programId);
                break;
            case "update":
                await ProgramAssignmentService.update(token, programId, updatedAthleteProgram);
                break;
            default:
                console.error(`Invalid action: ${action}`);
                return;
            }

            // Refresh the list of program assignments for the currently selected athlete
            if (selectedAthlete) {
                refreshProgramAssignments();
            }

            // Collapse the accordion
            setActiveAccordionKey(null);

        } catch (error) {
            console.error(`Error disabling program assignment with id ${programId}:`, error);
        }
    };

    const handleViewDetails = (athlete) => {
        if (athlete === null) {
            setSelectedAthlete(null);
        } else {
            setSelectedAthlete(athlete);
            setModalMode("view");
            setShowModal(true);
        }
        //console.log(athlete);
    };

    const handleCreateProgram = (athlete) => {
        if (athlete === null) {
            setSelectedAthlete(null);
        } else {
            setSelectedAthlete(athlete);
            setModalMode("create");
            setShowModal(true);
        }
        //console.log(athlete);
    };

    const handleSendMessage = (athlete) => {
        console.log(athlete);
    };

    const handleAssignProgram = useCallback(async (program) => {
        datadogLogs.logger.info("Assigning program to athlete", { athleteId });
        const token = await getToken();
        if (token && program) {
            const workoutAssignments = getWorkoutAssignments(program);
            const assignmentData = {
                userId: user.sub,
                athleteId: athleteId,
                trainerId: user.sub,
                startDate: startDate,
                endDate: endDate,
                enabled: enabled,
                program: program,
                workoutAssignments
            };
            try {
                await ProgramAssignmentService.create(token, assignmentData);
                refreshProgramAssignments();
                datadogLogs.logger.info("Program assigned successfully", { athleteId });
                //onAssign && onAssign(assignmentData);
                //close();
            } catch (error) {
                datadogLogs.logger.error("Error assigning program", { error: error.message });
            }
        } else {
            datadogLogs.logger.warn("Failed to retrieve token or no program selected");
        }
    }, [getToken, athleteId, close, user, startDate, endDate, enabled]);


    return (

        <Container fluid className="mt-4">
            <Row>
                <Col>
                    {fetchMeasurementsError && <p>Error fetching measurements: {fetchMeasurementsError}</p>}
                    {fetchAthletesError && <p>Error fetching athletes: {fetchAthletesError}</p>}
                    {fetchProgramAssignmentsError && <p>Error fetching program assignments: {fetchProgramAssignmentsError}</p>}
                    {fetchProgramsError && <p>Error fetching programs: {fetchProgramsError}</p>}
                    <div>
                        <Form.Control
                            type="text"
                            placeholder="Search Athlete"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <div className="d-flex flex-wrap">
                            {athletes
                                .filter((athlete) =>
                                    athlete.name.toLowerCase().includes(searchInput.toLowerCase())
                                )
                                .map((athlete, index) => (
                                    <AthleteCard
                                        key={index}
                                        athlete={athlete}
                                        onView={() => handleViewDetails(athlete)}
                                        onCreateProgram={() => handleCreateProgram(athlete)}
                                        onSendMessage={() => handleSendMessage(athlete)}
                                    />
                                ))}
                        </div>

                        <AthleteModal
                            showModal={showModal}
                            onClose={() => setShowModal(false)}
                            selectedAthlete={selectedAthlete}
                            athletePrograms={programAssignments}
                            handleProgramAssignmentChange={handleProgramAssignmentChange}
                            measurements={localMeasurements}
                            activeAccordionKey={activeAccordionKey}
                            onAccordionChange={setActiveAccordionKey}
                            modalMode={modalMode}
                            handleAssignProgram={handleAssignProgram}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            enabled={enabled}
                            setEnabled={setEnabled}
                            programs={programs}
                            athletes={athletes}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AthleteListView;
