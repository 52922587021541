import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../App.css";

const Home = () => {
    // Define the logo path using the public URL
    // eslint-disable-next-line no-undef
    const logo = process.env.PUBLIC_URL + "/safari-pinned-tab.svg";

    return (
        <>
            <div className="home-content">
                <h1>Enlisted Fitness</h1>
                <img src={logo} className="App-logo" alt="logo" />
                <h2>Coming Soon</h2>
            </div>
            <footer>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <a
                                target="_blank"
                                href="https://uptime.betterstack.com"
                                rel="noreferrer"
                            >
                                <img
                                    style={{ width: "130px", height: "52px" }}
                                    alt="Better Stack Website Monitoring"
                                    src="https://uptime.betterstack.com/assets/static_assets/badges/dark.png" />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Home;
