import createApiInstance from "./api";

const API_URL = "/workouts";

const WorkoutService = {
    getAll: async (token) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(API_URL);
            return response.data;
        } catch (error) {
            console.error("Error fetching workouts:", error);
            throw error;
        }
    },

    get: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(`${API_URL}/${encodeURIComponent(id)}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching workout with id ${id}:`, error);
            throw error;
        }
    },

    create: async (token, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post(API_URL, data);
            return response.data;
        } catch (error) {
            console.error("Error creating workout:", error);
            throw error;
        }
    },

    update: async (token, id, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.put(`${API_URL}/${encodeURIComponent(id)}`, data);
            return response.data;
        } catch (error) {
            console.error(`Error updating workout with id ${id}:`, error);
            throw error;
        }
    },

    delete: async (token, id) => {
        try {
            const api = createApiInstance(token);
            await api.delete(`${API_URL}/${encodeURIComponent(id)}`);
        } catch (error) {
            console.error(`Error deleting workout with id ${id}:`, error);
            throw error;
        }
    },

    deleteAll: async (token) => {
        try {
            const api = createApiInstance(token);
            await api.delete(API_URL);
        } catch (error) {
            console.error("Error deleting all workouts:", error);
            throw error;
        }
    },

    findByUserId: async (token, userId) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(`${API_URL}/?userId=${encodeURIComponent(userId)}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching workouts for user id ${userId}:`, error);
            throw error;
        }
    },

    addExercise: async (token, workoutId, exerciseId) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post(`${API_URL}/${encodeURIComponent(workoutId)}/exercises/${encodeURIComponent(exerciseId)}`);
            return response.data;
        } catch (error) {
            console.error(`Error adding exercise with id ${exerciseId} to workout with id ${workoutId}:`, error);
            throw error;
        }
    },

    removeExercise: async (token, workoutId, exerciseId) => {
        try {
            const api = createApiInstance(token);
            await api.delete(`${API_URL}/${encodeURIComponent(workoutId)}/exercises/${encodeURIComponent(exerciseId)}`);
        } catch (error) {
            console.error(`Error removing exercise with id ${exerciseId} from workout with id ${workoutId}:`, error);
            throw error;
        }
    },
};

export default WorkoutService;
