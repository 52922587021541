import { useState, useEffect } from "react";
import AthleteMeasurementsService from "../../../../services/athlete.measurements.service";

const useFetchMeasurements = (getToken, athleteId) => {
    const [measurements, setMeasurements] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMeasurements = async () => {
            const token = await getToken();
            if (!athleteId) {
                return; // Exit if no athleteId is provided
            }
            try {
                const localMeasurements = await AthleteMeasurementsService.getAllByUserId(token, athleteId);
                setMeasurements(localMeasurements);
            } catch (err) {
                setError(err);
            }
        };
    
        fetchMeasurements();
    }, [getToken, athleteId]); // Dependency array now includes athleteId

    return [measurements, error];
};

export default useFetchMeasurements;