import createApiInstance from "./api";

const API_URL = "/sports";  // Update this to the correct API URL for sports

const SportsService = {
    // Retrieve sport by ID
    get: async (token, id) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(`${API_URL}/${encodeURIComponent(id)}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching sport with id ${id}:`, error);
            throw error;
        }
    },

    // Retrieve all sports
    getAllSports: async (token) => {
        try {
            const api = createApiInstance(token);
            const response = await api.get(API_URL);
            return response.data;
        } catch (error) {
            console.error("Error fetching all sports:", error);
            throw error;
        }
    },

    // Create a new sport
    create: async (token, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.post(API_URL, data);
            return response.data;
        } catch (error) {
            console.error("Error creating sport:", error);
            throw error;
        }
    },

    // Update an existing sport by ID
    update: async (token, id, data) => {
        try {
            const api = createApiInstance(token);
            const response = await api.put(`${API_URL}/${encodeURIComponent(id)}`, data);
            return response.data;
        } catch (error) {
            console.error(`Error updating sport with id ${id}:`, error);
            throw error;
        }
    },

    // Delete a sport by ID
    delete: async (token, id) => {
        try {
            const api = createApiInstance(token);
            await api.delete(`${API_URL}/${encodeURIComponent(id)}`);
        } catch (error) {
            console.error(`Error deleting sport with id ${id}:`, error);
            throw error;
        }
    },
};

export default SportsService;
