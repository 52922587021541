import { useState, useEffect } from "react";
import TrainerProfileService from "../../../../services/trainer.profile.service";
import UserProfileService from "../../../../services/user.profile.service";

const useAthletes = (token, trainerId, isAuthenticated) => {
    const [athletes, setAthletes] = useState([]);
    const [fetchAthletesError, setFetchAthletesError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated && trainerId && token) {
                try {
                    const fetchedTrainerProfile = await TrainerProfileService.getTrainerProfile(token, trainerId);
                    if (fetchedTrainerProfile) {
                        const athleteProfilesPromises = fetchedTrainerProfile.assignedAthletes
                            ? fetchedTrainerProfile.assignedAthletes.map(athleteId => UserProfileService.getUserProfile(token, athleteId))
                            : [];
                        const fetchedAthletes = await Promise.all(athleteProfilesPromises);
                        setAthletes(fetchedAthletes);
                    }
                } catch (error) {
                    setFetchAthletesError(error.message || "An error occurred while fetching athletes.");
                }
            }
        };
        fetchData();
    }, [token, isAuthenticated, trainerId]);

    return [athletes, fetchAthletesError];
};

export default useAthletes;
