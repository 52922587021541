import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import UserProfileService from "../../services/user.profile.service";
import SportsService from "../../services/sports.service";
import { useNavigate } from "react-router-dom";
import { useGetToken } from "../../services/authUtils";

const CreateUserProfile = () => {
    const [primarySport, setPrimarySport] = useState("");
    const [sportsList, setSportsList] = useState([]);
    const [filteredSports, setFilteredSports] = useState([]);
    // getToken
    const { user, getToken } = useGetToken();
    const [token, setToken] = useState(null);

    useEffect(() => {
        (async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        })();
    }, [getToken]);

    const fetchSports = async () => {
        try {
            if (token) {
                const sports = await SportsService.getAllSports(token);
                return sports.length > 0 ? sports : [];
            }
        } catch (error) {
            console.error("Failed to fetch sports:", error);
            return [];  // Return empty list on failure
        }
    };

    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        dateOfBirth: "",
        gender: "",
        heightFeet: "",
        heightInches: "",
        height: "",
        weight: "",
        primarySport: "",
        bodyMeasurements: {
            chest: "",
            waist: "",
            hips: "",
            thigh: "",
            bicep: "",
            neck: ""
        }
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setForm(prevForm => ({ ...prevForm, email: user.email }));
        }
    }, [user]);

    useEffect(() => {
        const fetchAndSetSportsList = async () => {
            const sports = await fetchSports();
            // Sort sports array by name
            const sortedSports = sports.sort((a, b) => a.name.localeCompare(b.name));
            setSportsList(sortedSports);
            setFilteredSports(sortedSports);
        };
    
        if (token) {
            fetchAndSetSportsList();
        }
    }, [token]);
    
    useEffect(() => {
        //console.log("Debug: ", { sportsList, primarySport });
        setFilteredSports(
            sportsList.filter((sport) =>
                sport.name.toLowerCase().includes(primarySport.toLowerCase())
            )
        );
    }, [primarySport, sportsList]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name in form) {
            setForm({ ...form, [name]: value });
        } else {
            setForm({ ...form, bodyMeasurements: { ...form.bodyMeasurements, [name]: value } });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (token) {
                const { heightFeet, heightInches, ...restOfForm } = form;
                const heightInInches = (parseInt(heightFeet, 10) * 12) + parseInt(heightInches, 10);
                const userProfileData = {
                    ...restOfForm,
                    id: user.sub,
                    userId: user.sub,
                    name: `${restOfForm.firstName} ${restOfForm.lastName}`,
                    height: heightInInches,
                    preferredUnit: "Imperial"
                };
                await UserProfileService.addUserProfile(token, userProfileData);
                navigate("/"); // navigate to the home page
            }
        } catch (error) {
            console.error("Error creating user profile:", error);
        }
    };

    const handlePrimarySportChange = (e) => {
        setPrimarySport(e.target.value);
    };
    

    
    

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1>Welcome to Enlisted Fitness</h1>
                    <h2>Please Create Your User Profile</h2>
                </Col>
            </Row>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={form.firstName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name:</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={form.lastName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={form.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group controlId="formDOB">
                            <Form.Label>Date of Birth:</Form.Label>
                            <Form.Control
                                type="date"
                                name="dateOfBirth"
                                value={form.dateOfBirth}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formGender">
                            <Form.Label>Gender:</Form.Label>
                            <Form.Control
                                as="select"
                                name="gender"
                                value={form.gender}
                                onChange={handleChange}
                            >
                                <option value="">Select...</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                                <option value="preferNotToSay">Prefer not to say</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group controlId="formHeightFeet">
                            <Form.Label>Height (ft):</Form.Label>
                            <Form.Control
                                type="number"
                                name="heightFeet"
                                value={form.heightFeet}
                                onChange={handleChange}
                                min="1"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formHeightInches">
                            <Form.Label>Height (in):</Form.Label>
                            <Form.Control
                                type="number"
                                name="heightInches"
                                value={form.heightInches}
                                onChange={handleChange}
                                min="0"
                                max="11"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formWeight">
                            <Form.Label>Weight (lb):</Form.Label>
                            <Form.Control
                                type="number"
                                name="weight"
                                value={form.weight}
                                onChange={handleChange}
                                min="1"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={8}>
                        <Form.Group controlId="formPrimarySport">
                            <Form.Label>Primary Sport:</Form.Label>
                            <Form.Control
                                type="text"
                                value={primarySport}
                                onChange={handlePrimarySportChange}
                                autoComplete="off"
                                list="sportsOptions"
                            />
                            <datalist id="sportsOptions">
                                {filteredSports.map((sport, index) => (
                                    <option key={index} value={sport.name} />
                                ))}
                            </datalist>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button variant="primary" type="submit" style={{ marginTop: "20px" }}>
                            Submit
                        </Button>
                    </Col>
                </Row>

            </Form>
        </Container>
    );
};

CreateUserProfile.propTypes = {
    user: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    getAccessTokenSilently: PropTypes.func,
    navigate: PropTypes.func
};


export default CreateUserProfile;

