import React, { useState } from "react";
import { Form, Accordion, Button, Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import ExerciseAssignmentForm from "./ExerciseAssignmentForm";

function WorkoutAssignmentForm({ workoutAssignment: initialWorkoutAssignment, onChange, mode }) {
    const [workoutAssignment, setWorkoutAssignment] = useState(initialWorkoutAssignment);

    const handleExerciseChange = (index, field, value) => {
        setWorkoutAssignment(prevWorkoutAssignment => {
            const updatedWorkoutAssignment = { ...prevWorkoutAssignment };
            updatedWorkoutAssignment.exerciseAssignments[index][field] = value;

            // Notify parent component about the change.
            onChange(updatedWorkoutAssignment);

            return updatedWorkoutAssignment;
        });
    };

    function convertToLocalTime(timeString) {
        if (!timeString) return "N/A";
    
        const currentUTCDate = new Date(); // Get the current UTC date
        const timeUTC = timeString.split(":");
        const time = new Date(Date.UTC(
            currentUTCDate.getUTCFullYear(),
            currentUTCDate.getUTCMonth(),
            currentUTCDate.getUTCDate(),
            parseInt(timeUTC[0], 10), // Hours
            parseInt(timeUTC[1], 10), // Minutes
            parseInt(timeUTC[2], 10)  // Seconds
        ));
        return time.toLocaleTimeString();
    }

    return (
        <Container fluid>
            <Row>
                {workoutAssignment.date && (
                    <Col xs={12} md={4}>
                        <Form.Group controlId="workout.date">
                            <Form.Label>Date</Form.Label>
                            {mode === "view" ?
                                <h4>{workoutAssignment.date}</h4>
                                :
                                <Form.Control type="text" value={workoutAssignment.date} readOnly={mode === "view"} onChange={e => handleExerciseChange("date", e.target.value)} />
                            }
                        </Form.Group>
                    </Col>
                )}
                {workoutAssignment.startTime && (
                    <Col xs={12} md={4}>
                        <Form.Group controlId="workout.startTime">
                            <Form.Label>Start Time</Form.Label>
                            {mode === "view" ?
                                <h4>{convertToLocalTime(workoutAssignment.startTime)}</h4>
                                :
                                <Form.Control type="text" value={workoutAssignment.startTime} readOnly={mode === "view"} onChange={e => handleExerciseChange("startTime", e.target.value)} />
                            }
                        </Form.Group>
                    </Col>
                )}
                {workoutAssignment.endTime && (
                    <Col xs={12} md={4}>
                        <Form.Group controlId="workout.endTime">
                            <Form.Label>End Time</Form.Label>
                            {mode === "view" ?
                                <h4>{convertToLocalTime(workoutAssignment.endTime)}</h4>
                                :
                                <Form.Control type="text" value={workoutAssignment.endTime} readOnly={mode === "view"} onChange={e => handleExerciseChange("endTime", e.target.value)} />
                            }
                        </Form.Group>
                    </Col>
                )}
            </Row>
            <Row>
                <h2>Exercises</h2>
            </Row>
            <Accordion>
                {workoutAssignment.exerciseAssignments
                    .sort((a, b) => {
                        const endTimeA = a.extraData?.endTime || "";
                        const endTimeB = b.extraData?.endTime || "";
                        return endTimeA.localeCompare(endTimeB);
                    })
                    .map((assignment, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index} style={{ marginTop: "5px" }}>
                            <Accordion.Header>{assignment.exercise.exerciseName}</Accordion.Header>
                            <Accordion.Body>
                                <ExerciseAssignmentForm mode={mode} exerciseAssignment={assignment} onChange={(field, value) => handleExerciseChange(index, field, value)} />
                            </Accordion.Body>
                        </Accordion.Item>
                    ))
                }
            </Accordion>
    
            <Row>
                <Col xs={12}>
                    <Button variant="primary" onClick={() => console.log("Save Changes")} hidden={mode === "view"}>Save Changes</Button>
                </Col>
            </Row>
        </Container>
    );
    
}

WorkoutAssignmentForm.propTypes = {
    workoutAssignment: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]).isRequired
};


export default WorkoutAssignmentForm;
