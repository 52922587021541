import React from "react";
import PropTypes from "prop-types";
import { Row, Button } from "react-bootstrap";

const ScheduleManagement = ({ onNewEventClick }) => (
    <Row className="mt-4">
        <h2>Schedule Management</h2>
        <Button variant="primary" onClick={onNewEventClick}>
            Create New Event
        </Button>
    </Row>
);

ScheduleManagement.propTypes = {
    onNewEventClick: PropTypes.func.isRequired,
};

export default ScheduleManagement;
